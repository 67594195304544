<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="公司名称" name="companyname" required>
          <a-input placeholder="请输入姓名" v-model:value="formState.companyname"></a-input>
        </a-form-item>
        <a-form-item label="公司名称2" name="companyname2">
          <a-input placeholder="请输入姓名" v-model:value="formState.companyname2"></a-input>
        </a-form-item>
        <a-form-item label="姓名" name="name" required>
          <a-input placeholder="请输入姓名" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="电话" name="tel" required>
          <a-input placeholder="请输入电话" v-model:value="formState.tel"></a-input>
        </a-form-item>
        <a-form-item label="电话2" name="tel2">
          <a-input placeholder="请输入电话2" v-model:value="formState.tel2"></a-input>
        </a-form-item>
        <a-form-item label="邮箱" name="mail">
          <a-input placeholder="请输入邮箱" v-model:value="formState.mail"></a-input>
        </a-form-item>
        <a-form-item label="邮箱2" name="mail2">
          <a-input placeholder="请输入邮箱2" v-model:value="formState.mail2"></a-input>
        </a-form-item>
        <a-form-item label="网址" name="website">
          <a-input placeholder="请输入网址" v-model:value="formState.website"></a-input>
        </a-form-item>
        <a-form-item label="地址" name="addr">
          <a-input placeholder="请输入地址" v-model:value="formState.addr"></a-input>
        </a-form-item>

        <a-form-item label="成立时间" name="regtime">
          <a-date-picker v-model:value="formState.regtime" placeholder="请选择成立时间"></a-date-picker>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { Upload } from '@/components'
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  name: [{ required: true, message: '请输入模板名称', trigger: 'change' }],
  template_url: [{ required: true, message: '请上传附件', trigger: 'change' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增模板',
  loading: false
})

let form = ref<any>({
  isshow: 1
})
let formState = form.value

onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑模板'
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/template/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    form.value = res.data
    formState = form.value
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id) {
        let requeryParam = {
          url: '/admin/v1/template/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let data = { ...formState }
        data.regtime = dayjs(data.regtime).format('YYYY-MM-DD HH:mm:ss')
        let requeryParam = {
          url: '/admin/v1/txl',
          method: 'post',
          data: data
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
