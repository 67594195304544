<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="项目名称" name="name" required>
          <a-input placeholder="请输入项目名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="项目说明" name="remark" required>
          <a-textarea placeholder="请输入项目说明" v-model:value="formState.remark"></a-textarea>
        </a-form-item>
        <a-form-item label="复制内容" name="copy_content" required>
          <a-textarea :rows="5" placeholder="请输入复制内容" v-model:value="formState.copy_content"></a-textarea>
          插入 {网址} 进行网址替换,可将放在适合的位置
        </a-form-item>
        <a-form-item label="项目相关附件">
          <Upload
            upload_path="public/uploads/特别行动"
            uploadType="text"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            :limitation="99"
            v-model:value="formState.document"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="项目目的">
          <a-radio-group v-model:value="formState.type" :options="templateFileTypeOptions"> </a-radio-group>
        </a-form-item>
        <a-form-item label="项目收集时间" name="startAndEnd_time" required>
          <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
        </a-form-item>

        <a-form-item label="项目负责人姓名" name="responsible_person_name" required>
          <a-input placeholder="请输入项目负责人姓名" v-model:value="formState.responsible_person_name"></a-input>
        </a-form-item>
        <a-form-item label="项目负责人电话" name="responsible_person_tel" required>
          <a-input placeholder="请输入项目负责人电话" v-model:value="formState.responsible_person_tel"></a-input>
        </a-form-item>
        <a-form-item label="项目接收邮箱">
          <a-input placeholder="请输入项目接收邮箱" v-model:value="formState.mail"></a-input>
        </a-form-item>
        <a-form-item label="参与主体">
          <Upload
            upload_path="public/uploads/特别行动"
            uploadType="text"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            :limitation="1"
            v-model:value="formState.import_url"
            uploadText="上传参与主体表格"
          >
          </Upload>
          <a
            href="https://hncs.cskaiyela.com/storage/public/uploads/%E6%A8%A1%E7%89%88%E5%BA%93/%E4%B8%93%E9%A1%B9%E8%A1%8C%E5%8A%A8%E7%AB%99%E5%A4%96%E5%AE%A2%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
            target="_blank"
            >查看主体模板</a
          >
        </a-form-item>
        <a-form-item label="参与主体">
          <SelectCompany :isEdit="state.isEdit" :selectCheck="formState.customer_infos" @change="SelectCompanyChange"></SelectCompany>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { SelectCompany, Upload } from '@/components'
import { mobileRequiredValidator } from '@/utils/util'
import dayjs from 'dayjs'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const format = 'YYYY-MM-DD HH:mm:ss'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const templateFileTypeOptions = [
  { label: '收集附件', value: 1 },
  { label: '工商年检', value: 2 }
]
const emit = defineEmits(['cancel'])
const ruleForm = ref()
// const rangePickerValueRules = (rule, value: any) => {
//     if (!formState.start_time) {
//         return Promise.reject('请选择限制截止开始时间')
//     }
//     if (!formState.end_time) {
//         return Promise.reject('请选择限制截止结束时间')
//     }
//     return Promise.resolve();
// }
const rules = {
  responsible_person_tel: [{ required: true, validator: mobileRequiredValidator }]
  //   start_time: [{ validator: rangePickerValueRules, required: true }],
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
  isEdit: boolean
}
const state = reactive<state>({
  modalTitle: '新增项目',
  loading: false,
  isEdit: false
})

interface formState {
  name: string
  customer_infos: any[]
  import_url: any[] | undefined
  type: number
  document: any[] | undefined
  remark: string
  responsible_person_name: string
  responsible_person_tel: string
  mail: string
  startAndEnd_time: string | null
  copy_content: string | null
}
let formState = reactive<formState>({
  name: '',
  customer_infos: [],
  import_url: undefined,
  type: 1,
  document: undefined,
  remark: '',
  responsible_person_name: '',
  responsible_person_tel: '',
  mail: '',
  startAndEnd_time: '',
  copy_content: ''
})

const rangTime = computed(() => {
  let array: any = []
  if (formState.startAndEnd_time) {
    const startAndEnd_time = formState.startAndEnd_time.split(',')
    array = startAndEnd_time.map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})

const rangTimeChange = (val, str) => {
  if (val) {
    formState.startAndEnd_time = str.join(',')
  } else {
    formState.startAndEnd_time = null
  }
}
onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑项目'
    state.isEdit = true
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/specificProjec/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    Object.assign(formState, res.data)
  })
}
const SelectCompanyChange = (val: any[]) => {
  formState.customer_infos = [...val]
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/specificProjec/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/specificProjec',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
