<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createMenu',
})
</script>
<template>
    <a-modal :title="state.modalTitle" :width="800" :open="true" :confirmLoading="state.loading" @ok="onFormSubmit" @cancel="() => {
        emit('cancel')
    }
        ">
        <a-spin :spinning="state.loading">
            <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
                <a-form-item label="用户权限名称" name="display_name">
                    <a-input placeholder="请输入用户权限名称" v-model:value="formState.display_name"></a-input>
                </a-form-item>
                <a-form-item label="角色标识" name="name">
                    <a-input placeholder="请输入角色标识" v-model:value="formState.name"></a-input>
                </a-form-item>
                <a-form-item label="状态">
                    <a-radio-group v-model:value="formState.status">
                        <a-radio :value="1">正常</a-radio>
                        <a-radio :value="0">停用</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="菜单权限" name="permissions">
                    <div style="line-height: 32px;">
                        <a-checkbox v-model:checked="state.unfold" @change="unfoldChange">展开/折叠</a-checkbox>
                        <a-checkbox v-model:checked="state.checkAll" @change="checkAllChange">全选/全不选</a-checkbox>
                    </div>
                    <div class="tree">
                        <a-tree v-model:expandedKeys="state.expandedKeys" v-model:checkedKeys="formState.permissions"
                            checkable :tree-data="state.permissionList" :fieldNames="fieldNames">
                            <!-- <template #title="{ display_name, id }">
                                <template>{{ display_name }}</template>
                            </template> -->
                        </a-tree>
                    </div>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup name="createMenu">
import { reactive, ref, onMounted, watch, nextTick } from 'vue';
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import type { TreeProps } from 'ant-design-vue';
const props = defineProps({
    detailData: {
        type: Object,
        default: () => null,
    },
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
    display_name: [{ required: true, message: '请输入用户权限名称', trigger: 'change' }],
    permissions: [{ required: true, message: '请选择菜单权限', trigger: 'change' }],
    name: [{ required: true, message: '请输入角色标识', trigger: 'change' }],
}
const fieldNames = {
    title: 'display_name',
    key: 'id',
    children: '_child',
}
const unfoldChange = (val: any) => {
    if (val.target.checked) {
        state.expandedKeys = state.permissionList.map((item) => item.id)
    } else {
        state.expandedKeys = []
    }
}
const checkAllChange = (val: any) => {
    if (val.target.checked) {
        formState.permissions = state.permissionList.map((item) => item.id)
    } else {
        formState.permissions = []
    }
}
const formLayout = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 15 },
        sm: { span: 15 },
    },
}
interface state {
    modalTitle: string
    loading: boolean
    unfold: boolean
    checkAll: boolean
    permissionList: any
    expandedKeys: (string | number)[]
}
const state = reactive<state>({
    modalTitle: '新增用户权限',
    loading: false,
    unfold: false,
    checkAll: false,
    permissionList: [],
    expandedKeys: []
})
interface formState {
    display_name: string
    name: string
    status: number
    permissions: (string | number)[]

}
let form = ref<formState>({
    display_name: '',
    name: '',
    status: 1,
    permissions: [],

})
let formState = form.value
watch(() => form.value.permissions, (val) => {
    nextTick(() => {
        ruleForm.value.validate('permissions')
    })
})
onMounted(() => {
    queryPermissions()
})

const queryPermissions = () => {
    state.loading = true
    let requeryParam = {
        url: `/admin/role/create`,
        method: 'get',
    }
    baseService(requeryParam).then((res) => {
        state.permissionList = res.data.permissions
        if (props.detailData?.id) {
            state.modalTitle = '编辑用户权限'
            queryDetail(props.detailData.id)
            return
        }
        state.loading = false
    })
}

const queryDetail = (id) => {
    let requeryParam = {
        url: `/admin/role/${id}/edit`,
        method: 'get',
    }
    baseService(requeryParam).then((res) => {
        state.loading = false
        form.value = res.data.role
        form.value.permissions = res.data.permissions_ids
        formState = form.value
    })
}
const onFormSubmit = () => {
    ruleForm.value.validate().then(() => {
        state.loading = true
        if (props.detailData?.id > 0) {
            let requeryParam = {
                url: `/admin/role/${props.detailData.id}/update`,
                method: 'put',
                data: formState,
            }
            baseService(requeryParam).then((res: any) => {
                state.loading = false
                message.success(res.msg)
                emit('cancel')
            }).catch((res) => {
                state.loading = false
                if (res.msg) {
                    message.error(res.msg)
                } else {
                    message.error('操作失败')
                }
            })
        } else {
            let requeryParam = {
                url: '/admin/role/store',
                method: 'post',
                data: formState,
            }
            baseService(requeryParam).then((res: any) => {
                state.loading = false
                message.success(res.msg)
                emit('cancel')
            }).catch((res) => {
                state.loading = false
                if (res.msg) {
                    message.error(res.msg)
                } else {
                    message.error('操作失败')
                }
            })
        }
    }).catch(() => {
    })
}
</script>

<style lang="less" scoped>
.tree {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
}
</style>
