import ls from '@/utils/Storage'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export function clearUserInfo() {
    ls.remove('ACCESS_TOKEN')
    ls.remove('PERMISSION')
    ls.remove('USER_INFO')
    ls.remove('MENU_NAV')
}

export function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}
export const getWeek = (week: number, useZhou) => {
    let txt = ''
    switch (week) {
        case 1:
            txt = '一'
            break
        case 2:
            txt = '二'
            break
        case 3:
            txt = '三'
            break
        case 4:
            txt = '四'
            break
        case 5:
            txt = '五'
            break
        case 6:
            txt = '六'
            break
        case 0:
            txt = '日'
            break
        default:
            return 'getWeekError'
    }
    return useZhou ? '周' : '星期' + txt
}
export const mobileRequiredValidator = (rule, value) => {
    let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (value && value.length > 0) {
        if (!reg_tel.test(value)) {
            return Promise.reject('请输入正确的手机号码');
        } else {
            return Promise.resolve();
        }
    } else {
        return Promise.reject('请输入手机号码');
    }
}
export const mobileValidator = (rule, value) => {
    let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (value && value.length > 0) {
        if (!reg_tel.test(value)) {
            return Promise.reject('请输入正确的手机号码');
        } else {
            return Promise.resolve();
        }
    }
    return Promise.resolve();
}

export const trimAll = (ele) => {
    if (typeof ele === 'string') {
        return ele.split(/[\t\r\f\n\s]*/g).join('');
    } else {
        return ele
    }
}

export const isEmpty = (val) => {
    if (val && val.length > 0) {
        return val
    } else {
        return '无'
    }
}
