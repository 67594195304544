<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    title="发行优惠券"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="优惠券名称" name="name">
<div class="item">
  <div class="box">
    <div class="content">
	    <div class="page-group">
	      <i class="fold-page"></i>
        <span class="page">{{detailData.coupon_type==1?'直减现金券':detailData.coupon_type==2?'满减现金券':'折扣券'}}</span>
      </div>
      <i class="dot-left"></i>
      <i class="dot-right"></i>
    </div>
    <div class="footer">
      <div>{{detailData.name}}</div>
      <div>截止日期：{{detailData.end_time}}</div>
    </div>
    <div class="ribbon">生效中</div>
  </div>
</div>


 </a-form-item>




        <a-form-item label="优惠券类型" name="type"> <a-tag class="ant-tag-green">{{detailData.coupon_type==1?'直减现金券':detailData.coupon_type==2?'满减现金券':'折扣券'}}</a-tag> </a-form-item>

        <div>
          <a-form-item label="优惠方式" name="discounts_type"> <a-tag class="ant-tag-green">{{detailData.name}}</a-tag> </a-form-item>
          <a-form-item label="优惠卷金额" name="coupon_amout"> <a-tag class="ant-tag-green">¥{{detailData.coupon_amout}}</a-tag> </a-form-item>
        </div>

        <a-form-item label="公司主体类型">
             <a-checkbox-group v-model:value="formState.main_types" button-style="solid">
                <a-checkbox :value="1">公司</a-checkbox>
                <a-checkbox :value="2">个体户</a-checkbox>
                <a-checkbox :value="3">个人独资企业</a-checkbox>
            </a-checkbox-group>
        </a-form-item>
        <a-form-item label="业务类型">
            <a-checkbox-group v-model:value="formState.business_types" button-style="solid">
                <a-checkbox :value="1">设立</a-checkbox>
                <a-checkbox :value="2">同区迁入</a-checkbox>
                <a-checkbox :value="3">跨区迁入</a-checkbox>
            </a-checkbox-group>
        </a-form-item>
        <a-form-item name="registration_types" label="注册类型">
            <a-checkbox-group v-model:value="formState.registration_types" button-style="solid">
              <a-checkbox :value="1">包注册</a-checkbox>
              <a-checkbox :value="2">出裸地址</a-checkbox>
            </a-checkbox-group>
          </a-form-item>

        <a-form-item label="可使用平台" name="platform_ids">
          <a-select placeholder="请选择可使用平台" show-search allowClear :filter-option="filterOption" @change="selectChange" v-model:value="formState.platform_ids" mode="multiple">
            <a-select-option v-for="(item, index) in state.platformOptions" :key="index" :value="item.id" :title="item" :label="item.platform_abbreviation">{{
              item.platform_abbreviation
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="发行数量" name="amount">
          <a-input placeholder="请输入发行数量" v-model:value="formState.amount"></a-input>
        </a-form-item>
        <a-form-item label="绑定用户名" name="user_username">
          <a-input placeholder="请输入绑定用户名" v-model:value="formState.user_username"></a-input>
        </a-form-item>
        <a-form-item label="发行原因" name="publish_reason">
          <a-input placeholder="请输入发行原因" v-model:value="formState.publish_reason"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  platform_ids: [{ required: true, message: '请输入使用平台', trigger: 'change' }],
  user_nickname: [{ required: true, message: '请输入绑定用户名', trigger: 'change' }],
  publish_reason: [{ required: true, message: '请输入发行原因', trigger: 'change' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}

interface state {
  modalTitle: string
  loading: boolean
  platformOptions: any[]
}
const state = reactive<state>({
  modalTitle: '新增优惠券',
  loading: false,
  platformOptions: []
})

let form = ref<any>({
    main_types:[1,2,3],
    business_types: [1,2,3],
    registration_types: [1,2],
    type: 1,
    amount:1,
    discounts_type: 1,
    time_type: 1,


})
let formState = form.value

onMounted(() => {
  queryPlatform()
})
const queryPlatform = () => {
  let requeryParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: {
      limit: 'all'
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.platformOptions = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const filterOption = (input: string, option: any) => {
  let bool = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return bool
}
const selectChange = (val, e: any[]) => {}

const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      let requeryParam = {
        url: '/admin/v1/coupon/issueCoupon',
        method: 'post',
        data: { ...formState, coupon_id: props.detailData.id }
      }
      baseService(requeryParam)
        .then((res: any) => {
          state.loading = false
          message.success(res.msg)
          emit('cancel')
        })
        .catch((res) => {
          state.loading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped>
.item {
  width: 205px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-left: 2px;
  position: relative;
}
.box {
  background: #fff;
  border-radius: 5px;
  background: linear-gradient(45deg, orange, red);
}
.item .dot-left,.item .dot-right {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f5f5f5;
  position: absolute;
  z-index: 999;
}
.item .dot-left {
  bottom: -6px;
  left: -6px;
}
.item .dot-right {
  bottom: -6px;
  right: -6px;
}
.item .page-group {
  position: absolute;
  top: 10px;
  left: -2px;
  width: 100%;
  max-width: 120px;
}
.item .page-group .fold-page {
  display: block;
  width: 2px;
  height: 5px;
  background: #db0113;
  transform: skewY(-40deg);
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 0;
}
.item .page-group .page {
  position: absolute;
  z-index: 1;
  display: block;
  padding: 0 10px;
  height: 20px;
  background: linear-gradient(137deg, #ff5b5b 0%, #db0113 100%);
  border-radius: 0 20px 20px 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
}
.item .content {
  width: 100%;
  height: 50px;
  border-bottom: 1px dotted #f5f5f5;
  position: relative;
  z-index: 2;
}
.item .footer {
  color: #fff;
  font-size: 12px;
  padding: 5px 15px 10px 15px;
}
.footer-label {
  line-height: 24px;
}
.item .ribbon {
  width: 80px;
  height: 20px;
  background: #dd0d1f;
  position: absolute;
  right: -20px;
  top: 10px;
  transform: rotateZ(45deg);
  text-align: center;
  color: #fff;
  font-size: 12px;
}

</style>
