<template>
  <a-result status="success" title="恭喜您提交成功，请等待审核！" sub-title="恭喜您提交成功，请等待审核！">
    <template #extra>
      <a-button type="primary" @click="ToBack">返 回</a-button>
      <a-button @click="ok">确 定</a-button>
    </template>
  </a-result>
</template>

<script lang="ts" setup>
const emit = defineEmits(['cancel', 'ok'])
const ToBack = () => {
  emit('cancel')
}
const ok = () => {
  emit('ok')
}
</script>
