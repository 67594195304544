<template>
  <a-modal :title="`退款操作(${props.tableColumnsData.full_name})`" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button type="primary">确定</a-button>
      </a-popconfirm>
    </template>
    <a-form ref="ruleForm" :model="formData" v-bind="formLayout" :rules="{}">
      <a-form-item label="退款原因" name="refund_remark" required>
        <a-input v-model:value="formData.refund_remark" placeholder="请输入原因"> </a-input>
      </a-form-item>
      <a-form-item label="退款金额" name="refund_money" required>
        <a-input v-model:value="formData.refund_money" placeholder="退款金额"> </a-input>
      </a-form-item>
      <a-form-item v-if="state.selectedRecord.order_type === 0" label="退款后状态">
        <a-radio-group v-model:value="formData.refund_type">
          <a-radio :value="1">改为未付款</a-radio>
          <a-radio :value="2">不变</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-else-if="state.selectedRecord.order_type === 1" label="退款后合同起止时间">
        <a-radio-group v-model:value="formData.renew_pact">
          <a-radio :value="1">回滚</a-radio>
          <a-radio :value="2">不回滚</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="订单编号">
        <div class="d-flex">
          <a-input @keyup.enter="getCustomerOrder" v-model:value="state.queryKey" placeholder="请输入订单编号"></a-input>
          <a-button class="ml-3x" @click="getCustomerOrder" type="primary">搜索</a-button>
        </div>
      </a-form-item>
      <a-table
        :row-selection="{ selectedRowKeys: state.selectedRowKeys, type: 'radio', onChange: onSelectChange }"
        :pagination="pagination"
        @change="onChange"
        :columns="CustomerOrderColumns"
        :loading="state.loading"
        rowKey="id"
        :data-source="state.CustomerOrder"
      >
      </a-table>

      <a-form-item label="上传附件">
        <Upload
          :upload_path="formData.upload_path"
          uploadType="text"
          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
          v-model:value="formData.refund_attachment"
          :limitation="99"
          uploadText="上传附件"
        >
        </Upload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { reactive, onMounted, ref } from 'vue'
import PlatformPayment from './PlatformPayment.vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import Upload from '@/components/base/Upload.vue'
const format = 'YYYY-MM-DD HH:mm:ss'
const $store = useStore()
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const ruleForm = ref()
const emit = defineEmits(['cancel', 'ok'])
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const CustomerOrderColumns: any = [
  {
    title: '订单编号',
    dataIndex: 'order_number'
  },
  {
    title: '订单名字',
    dataIndex: 'order_name'
  },
  {
    title: '价格',
    dataIndex: 'actually_paid_money'
  },
  {
    title: '时间',
    dataIndex: 'created_at'
  }
]
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  current: 1,
  total: undefined,
  showTotal: (total) => `总数：${total}`
})

const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  getCustomerOrder()
}
const state = reactive<any>({
  selectedRowKeys: [],
  CustomerOrder: [],
  selectedRecord: {},
  queryKey: '',
  loading: false
})
const formData = reactive<any>({
  refund_remark: null,
  refund_money: null,
  refund_type: 1,
  renew_pact: 1,
  refund_attachment: null,
  type: null
})
onMounted(() => {
  getCustomerOrder()
})

const getCustomerOrder = () => {
  state.loading = true
  let requestParam = {
    url: '/admin/v1/getCustomerOrder',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, { customer_id: props.tableColumnsData.id, order_number: state.queryKey })
  }
  baseService(requestParam)
    .then((res: any) => {
      state.loading = false
      pagination.total = res.data.total_records
      state.CustomerOrder = res.data.list
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

const onSelectChange = (selectedRowKeys: any[], val) => {
  console.log('selectedRowKeys changed: ', selectedRowKeys)
  console.log('selectedRecord: ', val[0])
  state.selectedRowKeys = selectedRowKeys
  state.selectedRecord = val[0]
}

// 提交
const onFormSubmit = () => {
  ruleForm.value.validate().then((valid) => {
    let requestParam = {
      url: '/admin/v1/customer/refund',
      method: 'post',
      data: {
        ...formData,
        customer_id: props.tableColumnsData.id,
        refund_order_ids: state.selectedRowKeys
      }
    }
    baseService(requestParam)
      .then((res: any) => {
        message.success(res.msg)
        emit('ok')
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  })
}

const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-modal-title) {
  margin-bottom: 25px !important;
}
.label-form-item {
  display: flex;
  align-items: center;

  .delete-icon {
    color: red;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.select-color {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .label-color-picker {
    border: 1px solid #dcdfe6;
    padding: 4px;
    border-radius: 4px;
  }
}
</style>
