<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="客户姓名" name="customer_name" required>
          <a-input placeholder="请输入客户姓名" v-model:value="formState.customer_name"></a-input>
        </a-form-item>
        <a-form-item label="客户电话" name="customer_tel">
          <a-input placeholder="请输入客户电话" v-model:value="formState.customer_tel"></a-input>
        </a-form-item>
        <a-form-item label="紧急联系人手机号码" name="exigency_tel">
          <AssociateAuthorization
            type="number"
            v-model:value="formState.exigency_tel"
            @change="validate"
            placeholder="请输入紧急联系人手机号码"
            suffixTitle="回车或失去焦点后可以添加多个紧急联系人手机号码"
            inputType="number"
          >
          </AssociateAuthorization>
        </a-form-item>
        <a-form-item label="邮箱" name="mail_address">
          <a-input placeholder="请输入邮箱" v-model:value="formState.mail_address"></a-input>
        </a-form-item>
        <a-form-item label="微信号" name="wechat_id">
          <a-input placeholder="请输入微信号" v-model:value="formState.wechat_id"></a-input>
        </a-form-item>
        <a-form-item label="微信昵称" name="wechat_name">
          <a-input placeholder="请输入微信昵称" v-model:value="formState.wechat_name"></a-input>
        </a-form-item>
        <a-form-item label="微信头像">
          <Upload
            upload_path="public/uploads/渠道备案"
            :accept="['png', 'jpg', 'jpeg', 'pdf']"
            uploadType="picture-card"
            :limitation="1"
            v-model:value="formState.wechat_picture"
            uploadText="上传微信头像"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="身份证人像面照片" name="identity_card_front">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept=".jpeg,.png,.jpg"
            :before-upload="beforeUpload"
            @change="
              (info) => {
                handleChange(info, 'identity_card_front')
              }
            "
          >
            <img class="upload-img" type="" v-if="formState.identity_card_front" :src="$store.state.user.image_domain + formState.identity_card_front" alt="avatar" />
            <div v-else class="upload-img">
              <PlusOutlined />
              <div class="ant-upload-text">上传人像面照片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="身份证国徽面照片" name="identity_card_contrary">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept=".jpeg,.png,.jpg"
            :before-upload="beforeUpload"
            @change="
              (info) => {
                handleChange(info, 'identity_card_contrary')
              }
            "
          >
            <img class="upload-img" type="" v-if="formState.identity_card_contrary" :src="$store.state.user.image_domain + formState.identity_card_contrary" alt="avatar" />
            <div v-else class="upload-img">
              <PlusOutlined />
              <div class="ant-upload-text">上传国徽面照片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="身份证号码" name="identity_card_number">
          <a-input placeholder="请输入身份证号码" v-model:value="formState.identity_card_number"></a-input>
        </a-form-item>
        <a-form-item label="身份证有效期开始时间" name="identity_card_start_time">
          <a-date-picker
            style="width: 200px"
            @change="
              (val, str) => {
                formState.identity_card_start_time = str
              }
            "
            v-model:value="identity_card_start_time"
            placeholder="身份证有效期开始时间"
          ></a-date-picker>
        </a-form-item>
        <a-form-item label="身份证有效期结束时间">
          <a-form-item-rest>
            <a-date-picker
              style="width: 200px"
              @change="
                (val, str) => {
                  formState.identity_card_expiration_time = str
                }
              "
              v-model:value="identity_card_expiration_time"
              :disabled="formState.identity_perpetual == 1"
              placeholder="身份证有效期结束时间"
            ></a-date-picker>
          </a-form-item-rest>
          <a-form-item-rest>
            <a-checkbox class="ml-1x" :checked="formState.identity_perpetual == 1 ? true : false" @change="identityPerpetualChange">长期</a-checkbox>
          </a-form-item-rest>
        </a-form-item>
        <a-form-item label="住所" name="identity_card_address">
          <a-input placeholder="住所" v-model:value="formState.identity_card_address"></a-input>
        </a-form-item>
        <a-form-item label="民族" name="nationality">
          <a-select v-model:value="formState.nationality" placeholder="民族">
            <a-select-option :value="item" v-for="(item, index) in state.nationalityList" :Key="index"> {{ item }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="推荐人手机号码">
          <a-input placeholder="推荐人手机号码" v-model:value="formState.referrer_tel"></a-input>
        </a-form-item>
        <a-form-item label="就职公司名称">
          <a-input placeholder="请输入就职公司名称" v-model:value="formState.company_name"></a-input>
        </a-form-item>
        <a-form-item label="就职公司统一社会信用代码">
          <a-input placeholder="请输入统一社会信用代码" v-model:value="formState.finance_social_credit_code"></a-input>
        </a-form-item>
        <a-form-item label="公司营业执照照片">
          <Upload
            upload_path="public/uploads/渠道备案"
            :accept="['png', 'jpg', 'jpeg', 'pdf']"
            uploadType="picture-card"
            :limitation="99"
            v-model:value="formState.company_business_license"
            uploadText="上传公司营业执照照片"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="就职公司地址">
          <a-input placeholder="请输入就职公司地址" v-model:value="formState.company_address"></a-input>
        </a-form-item>
        <a-form-item label="职位名称">
          <a-input placeholder="请输入职位名称" v-model:value="formState.job_title"></a-input>
        </a-form-item>
        <a-form-item label="证明材料">
          <Upload upload_path="public/uploads/渠道备案" :accept="['png', 'jpg', 'jpeg', 'pdf']" uploadType="text" :limitation="99" v-model:value="formState.job_certificate" uploadText="上传证明材料">
          </Upload>
        </a-form-item>
        <!-- 是否为注销代办人 -->
        <a-form-item :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }" label="注销代办人">
          <a-radio-group v-model:value="formState.iswaiqin" button-style="solid">
            <a-radio-button :value="1">是</a-radio-button>
            <a-radio-button :value="2">否</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="注销代办区域" v-show="formState.iswaiqin == 1"
          ><div class="information">
            <a-row v-for="(quyu, idx) in state.waiqin_quyu_list" :key="idx">
              <a-col :span="20">
                <AddressCascader v-model:value="state.waiqin_quyu_list[idx]"> </AddressCascader>
              </a-col>
              <a-col :span="4">
                <span class="muti-area"><DeleteOutlined v-if="state.waiqin_quyu_list.length > 1" @click="mutiAreaRemove(idx)" /></span>
                <span class="muti-area"><PlusOutlined v-if="idx == state.waiqin_quyu_list.length - 1" @click="mutiAreaAdd" /></span>
              </a-col>
            </a-row>
          </div>
        </a-form-item>
      </a-form>
    </a-spin>
    <image-cropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :imageUrl="state.imageUrl"
      upload_path="public/uploads/渠道备案"
      :imageName="state.imageName"
      @change="cropperChange"
      v-if="state.showCropper"
    ></image-cropper>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { Upload } from '@/components'
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { AssociateAuthorization } from '@/components'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import ImageCropper from '@/components/ImageCropper/index.vue'
import { useStore } from 'vuex'
import AddressCascader from '@/components/base/AddressCascader.vue'
import { mobileRequiredValidator } from '@/utils/util'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD'
const $store = useStore()

const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
let validateEnd = (rule, value) => {
  if (!value && formState.identity_perpetual != 1) {
    return Promise.reject(new Error('请选择身份证结束日期'))
  } else {
    return Promise.resolve()
  }
}
const rules = {
  customer_tel: [{ required: true, validator: mobileRequiredValidator }]
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const state = reactive<any>({
  modalTitle: '新增备案',
  loading: false,
  currentUpload: '',
  imageName: '',
  imageUrl: '',
  showCropper: false,
  nationalityList: [
    '汉族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '朝鲜族',
    '满族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛南族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '德昂族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '鄂伦春族',
    '赫哲族',
    '门巴族',
    '珞巴族',
    '基诺族',
    '其他',
    '外国血统'
  ],
  waiqin_quyu_list: [''] //外勤所辖区域
})

interface formState {
  customer_name: string
  customer_tel: string
  exigency_tel: string
  mail_address: string
  wechat_id: string
  wechat_name: string
  wechat_picture: string
  identity_card_front: string
  identity_card_contrary: string
  identity_card_number: string
  identity_card_address: string
  identity_card_expiration_time: string
  identity_card_start_time: string
  identity_perpetual: number
  nationality: string | null
  referrer_tel: string
  company_name: string
  finance_social_credit_code: string
  company_business_license: string
  company_address: string
  job_title: string
  job_certificate: string
  iswaiqin: number //是否为外勤或代办人
  waiqin_quyu: string
}
let formState = reactive<formState>({
  customer_name: '',
  customer_tel: '',
  exigency_tel: '',
  mail_address: '',
  wechat_id: '',
  wechat_name: '',
  wechat_picture: '',
  identity_card_front: '',
  identity_card_contrary: '',
  identity_card_start_time: '',
  identity_card_expiration_time: '',
  identity_perpetual: 2,
  identity_card_number: '',
  identity_card_address: '',
  nationality: null,
  referrer_tel: '',
  company_name: '',
  finance_social_credit_code: '',
  company_business_license: '',
  company_address: '',
  job_title: '',
  job_certificate: '',
  iswaiqin: 2,
  waiqin_quyu: ''
})

onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑备案'
    queryDetail(props.detailData.id)
  }
})
const identity_card_start_time = computed(() => {
  return formState.identity_card_start_time ? dayjs(formState.identity_card_start_time, format) : null
})
const identity_card_expiration_time = computed(() => {
  return formState.identity_card_expiration_time ? dayjs(formState.identity_card_expiration_time, format) : null
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/filings/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    Object.assign(formState, res.data)
    //处理所辖多省市区
    if (formState.waiqin_quyu.length > 0) {
      state.waiqin_quyu_list = formState.waiqin_quyu.split('#')
    }
  })
}
const identityPerpetualChange = (val) => {
  formState.identity_perpetual = val.target.checked ? 1 : 2
  ruleForm.value.validate('identity_card_expiration_time')
}
const validate = () => {
  ruleForm.value.validate('exigency_tel')
}
// 图片上传
const handleChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}

// 手动上传验证
const beforeUpload = (file) => {
  return false
}

// 裁剪成功
const cropperChange = (res, isRecognize) => {
  if (isRecognize) {
    if (!res.data.身份证识别实体信息) {
      message.error('识别失败，请重新上传')
      return
    }
    let data = res.data.身份证识别实体信息
    if (state.currentUpload == 'identity_card_contrary') {
      if (data.身份证国徽面实体信息.有效期限.length < 1) {
        message.error('请上传身份证国徽面照片')
        formState.identity_card_contrary = ''
        return
      }
      let list = data.身份证国徽面实体信息.有效期限.split('-').map((item, index) => {
        return item.replaceAll('.', '-')
      })
      let obj = {
        identity_card_start_time: list[0],
        identity_card_expiration_time: list[1],
        identity_perpetual: list[1] == '长期' ? 1 : 2
      }
      Object.assign(formState, obj)
    } else if (state.currentUpload == 'identity_card_front') {
      if (data.身份证人像面实体信息.姓名.length < 1) {
        message.error('请上传身份证人像面照片')
        formState.identity_card_front = ''
        return
      }
      let nationality: any = null
      state.nationalityList.forEach((item) => {
        if (item.indexOf(data.身份证人像面实体信息.民族) > -1) {
          nationality = item
        }
      })
      let obj = {
        customer_name: data.身份证人像面实体信息.姓名,
        identity_card_address: data.身份证人像面实体信息.住址,
        identity_card_number: data.身份证人像面实体信息.身份证号,
        nationality: nationality
      }
      Object.assign(formState, obj)
    }
    formState[state.currentUpload] = res.data.url
  } else {
    formState[state.currentUpload] = res.url
  }
  state.showCropper = false
  ruleForm.value.validate(state.currentUpload)
}
//多省市区选择
const mutiAreaAdd = () => {
  state.waiqin_quyu_list.push('')
}
const mutiAreaRemove = (index) => {
  state.waiqin_quyu_list.splice(index, 1)
}

const onFormSubmit = () => {
  //合并多个所辖省市区
  let waiqin_quyu = ''
  state.waiqin_quyu_list.forEach((element) => {
    if (element) {
      waiqin_quyu += '#' + element
    }
  })
  formState.waiqin_quyu = waiqin_quyu.length > 0 ? waiqin_quyu.substring(1) : ''
  console.log('11' + formState.waiqin_quyu)
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/filings/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/filings',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped>
.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}

.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.muti-area {
  cursor: pointer;
}
</style>
