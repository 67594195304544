<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="分类" name="class_id" required>
          <a-select placeholder="请选择分类" v-model:value="formState.class_id">
            <a-select-option v-for="(item, index) in classList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="购买库存方案名称" name="name" required>
          <a-input placeholder="请输入购买库存方案名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="购买库存方案描述" name="describe">
          <a-textarea placeholder="请输入购买库存方案描述" v-model:value="formState.describe"></a-textarea>
        </a-form-item>
        <a-form-item label="购买库存方案时间" name="start_time" required>
          <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
        </a-form-item>
        <a-form-item label="单价" name="price" required>
          <a-input placeholder="请输入单价" v-model:value="formState.price"></a-input>
        </a-form-item>
        <a-form-item label="数量" name="amount" required>
          <a-input placeholder="请输入数量" v-model:value="formState.amount"></a-input>
        </a-form-item>
        <a-form-item label="续费价格" name="renewal_price" required>
          <a-input placeholder="请输入续费价格" v-model:value="formState.renewal_price"></a-input>
        </a-form-item>
        <a-form-item label="总价" name="total_price" required>
          <a-input placeholder="请输入总价" v-model:value="formState.total_price"></a-input>
        </a-form-item>
        <a-form-item label="是否启用">
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.status"></a-switch>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD HH:mm:ss'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  },
  isCopy: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const rules = {}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增活动',
  loading: false
})
const startTime = dayjs().format(format)
const endTime = dayjs().add(1, 'years').format(format)
interface formState {
  name: string
  describe: string
  class_id: number | null
  total_price: number | null
  renewal_price: number | null
  amount: number | null
  price: number | null
  status: number
  end_time: string | null
  start_time: string | null
}
let formState = reactive<formState>({
  name: '',
  describe: '',
  class_id: null,
  total_price: null,
  renewal_price: null,
  amount: null,
  status: 1,
  price: null,
  end_time: endTime,
  start_time: startTime
})
let classList = ref()
onMounted(() => {
  queryCreate()
})
const rangTime = computed(() => {
  let array: any = []
  if (formState.start_time && formState.end_time) {
    const startAndEnd_time: string[] = []
    startAndEnd_time[0] = formState.start_time
    startAndEnd_time[1] = formState.end_time
    array = startAndEnd_time.map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
const rangTimeChange = (val, str) => {
  if (val) {
    formState.start_time = str[0]
    formState.end_time = str[1]
  } else {
    formState.start_time = null
    formState.end_time = null
  }
}
const queryCreate = () => {
  state.loading = true
  let requeryParam = {
    url: '/admin/v1/activityCenter/create',
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    classList.value = res.data.class
    if (props.detailData?.id) {
      state.modalTitle = '编辑活动'
      queryDetail(props.detailData.id)
    } else {
      state.loading = false
    }
  })
}
const queryDetail = (id) => {
  let requeryParam = {
    url: `/admin/v1/activityCenter/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    Object.assign(formState, res.data)
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0 && !props.isCopy) {
        let requeryParam = {
          url: '/admin/v1/activityCenter/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/activityCenter',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
