<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createMenu',
})
</script>
<template>
    <a-modal :title="state.modalTitle" :width="800" :open="true" :confirmLoading="state.loading" @ok="onFormSubmit" @cancel="() => {
        emit('cancel')
    }
        ">
        <a-spin :spinning="state.loading">
            <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
                <a-form-item label="菜单类型">
                    <a-radio-group v-model:value="formState.type">
                        <a-radio :value="1">目录</a-radio>
                        <a-radio :value="2">菜单</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="是否隐藏子菜单" v-if="formState.type == 1">
                    <a-switch v-model:checked="formState.hideChildrenInMenu"></a-switch>
                </a-form-item>
                <a-form-item label="上级菜单" v-if="formState.type == 2" name="parentId">
                    <a-select v-model:value="formState.parentId">
                        <a-select-option :value="1">客户管理</a-select-option>
                        <a-select-option :value="2">平台管理</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="是否缓存">
                    <a-switch v-model:checked="formState.keepAlive"></a-switch>
                </a-form-item>
                <a-form-item label="菜单名称" name="name">
                    <a-input placeholder="请输入菜单名称" v-model:value="formState.name"></a-input>
                </a-form-item>
                <a-form-item label="排序" name="sort">
                    <a-input placeholder="请输入排序" v-model:value="formState.sort"></a-input>
                </a-form-item>
                <a-form-item label="路由路径" name="path">
                    <a-input placeholder="请输入权限标识" v-model:value="formState.path"></a-input>
                </a-form-item>
                <a-form-item label="重定向地址" v-if="formState.type == 1" name="redirect">
                    <a-input placeholder="请输入重定向地址" v-model:value="formState.redirect"></a-input>
                </a-form-item>
                <a-form-item label="组件路径" name="componentPath" v-if="formState.type == 2">
                    <a-input placeholder="请输入组件路径" v-model:value="formState.componentPath"></a-input>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>

<script lang="ts" setup name="createMenu">
import { reactive, ref, onMounted } from 'vue';
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const props = defineProps({
    detailData: {
        type: Object,
        default: () => null,
    },
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
    name: [{ required: true, message: '请输入菜单名称', trigger: 'change' }],
    sort: [{ required: true, message: '请输入排序', trigger: 'change' }],
    path: [{ required: true, message: '请输入组件路径', trigger: 'change' }],
    parentId: [{ required: true, message: '请选择上级菜单', trigger: 'change' }],
    redirect: [{ required: true, message: '请输入重定向地址', trigger: 'change' }],
    componentPath: [{ required: true, message: '请输入组件路径', trigger: 'change' }],
}
const formLayout = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 15 },
        sm: { span: 15 },
    },
}
interface state {
    modalTitle: string
    loading: boolean
}
const state = reactive<state>({
    modalTitle: '新增菜单',
    loading: false,
})
interface formState {
    name: string
    sort: number
    type: number
    parentId: number | undefined
    path?: string
    componentPath?: string
    redirect?: string
    hideChildrenInMenu?: boolean
    keepAlive: boolean
}
let form = ref<formState>({
    name: '',
    sort: 1,
    parentId: undefined,
    path: '',
    componentPath: '',
    redirect: '',
    type: 1,
    hideChildrenInMenu: false,
    keepAlive: false
})
let formState = form.value

onMounted(() => {
    if (props.detailData?.id) {
        state.modalTitle = '编辑菜单'
        queryDetail(props.detailData.id)
    }
})

const queryDetail = (id) => {
    state.loading = true
    let requeryParam = {
        url: `/admin/v1/template/${id}/edit`,
        method: 'get',
    }
    baseService(requeryParam).then((res) => {
        state.loading = false
        form.value = res.data
        formState = form.value
    })
}
const onFormSubmit = () => {
    ruleForm.value.validate().then(() => {
        state.loading = true
        if (props.detailData?.id > 0) {
            let requeryParam = {
                url: '/admin/v1/template/' + props.detailData.id,
                method: 'put',
                data: formState,
            }
            baseService(requeryParam).then((res: any) => {
                state.loading = false
                message.success(res.msg)
                emit('cancel')
            })
        } else {
            let requeryParam = {
                url: '/admin/v1/template',
                method: 'post',
                data: formState,
            }
            baseService(requeryParam).then((res: any) => {
                state.loading = false
                message.success(res.msg)
                emit('cancel')
            })
        }
    }).catch(() => {
    })
}
</script>

<style lang="less" scoped>
</style>
