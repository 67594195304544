<template>
    <div>
        <div style="display: flex">
            <a-form-item-rest>
                <a-input @change="(res) => {
                    inputChange(res, 'glj1')
                }
                    " v-model:value="state.glj1" placeholder="市机关"></a-input>
            </a-form-item-rest>
            <a-form-item-rest>
                <a-input @change="(res) => {
                    inputChange(res, 'glj2')
                }
                    " v-model:value="state.glj2" placeholder="区机关"></a-input>
            </a-form-item-rest>
            <a-form-item-rest>
                <a-input @change="(res) => {
                    inputChange(res, 'jd')
                }
                    " v-model:value="state.jd" placeholder="街道"></a-input>
            </a-form-item-rest>
            <a-form-item-rest>
                <a-input @change="(res) => {
                    inputChange(res, 'gls')
                }
                    " v-model:value="state.gls" placeholder="所"></a-input>
            </a-form-item-rest>
        </div>
    </div>
</template>

<script lang="ts" setup>

import { reactive, watch } from 'vue';
const emit = defineEmits(['update:value'])
const props = defineProps({
    value: {
        type: Object,
        default: null,
    },
})
const state = reactive<any>({
    glj1: '',
    glj2: '',
    jd: '',
    gls: '',
})
watch(() => props.value, (val) => {
    if (val) {
        state.glj1 = val.glj1 || ''
        state.glj2 = val.glj2 || ''
        state.jd = val.jd || ''
        state.gls = val.gls || ''
    }
}, {
    deep: true,
    immediate: true,
})
const inputChange = (res, type) => {
    state[type] = res.target.value
    const obj = {
        glj1: state.glj1,
        glj2: state.glj2,
        jd: state.jd,
        gls: state.gls,
    }
    emit('update:value', obj)
}
</script>
