<template>
  <a-modal
    title="签名状态"
    :width="1000"
    :open="true"
    @cancel="
      () => {
        emit('cancel')
      }
    "
    :footer="null"
  >
    <a-form>
      <a-row :gutter="48">
        <a-col :md="12" :sm="24">
          <a-form-item label="公司名称">
            <a-input v-model:value="data.companyName" placeholder="请输入公司名称"> </a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button class="mr-1x" type="primary" @click="queryDetail">查询</a-button>
            <a-button v-if="data.companyName?.length > 0" type="link" @click="queryHistory">查看历史记录</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table :loading="data.ownershipTableLoading" style="margin-bottom: 30px" :columns="columns" :data-source="shareOwnershipTableData" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'status'">
          <a-tag v-if="record.signDate" color="cyan"> 已签字√ </a-tag>
          <a-tag v-else color="red"> 未签字×</a-tag>
        </template>
        <template v-else-if="column.dataIndex === 'signDate'">
          <span>{{ record.signDate ? record.signDate : '-' }}</span>
        </template>
      </template>
    </a-table>
    <a-modal
      title="历史记录"
      :width="1000"
      :open="data.historyOpen"
      @cancel="
        () => {
          data.historyOpen = false
        }
      "
      :footer="null"
    >
      <a-table :loading="data.loading" :columns="historyColumns" :data-source="historyList" :pagination="false">
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'reason'">
            <div v-for="(item, index) in reasonChange(text)" :key="index">
              {{ item }}
            </div>
          </template>
          <template v-else> {{ text ? text : '-' }}</template>
        </template>
      </a-table>
    </a-modal>
  </a-modal>
</template>

<script lang="ts" setup>
import { reactive, onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
interface tableData {
  isEdit: boolean
}
let timer = ref()
const emit = defineEmits(['cancel'])
const columns: Array<Object> = [
  {
    title: '姓名',
    dataIndex: 'signer'
  },
  {
    title: '身份证号码/社会信用代码',
    dataIndex: 'signerCertId'
  },
  {
    title: '签字状态',
    dataIndex: 'status'
  },
  {
    title: '签字时间',
    dataIndex: 'signDate'
  }
]
const historyColumns = [
  {
    title: '公司名称',
    dataIndex: 'full_name',
    width: 100
  },
  {
    title: '签字状态',
    dataIndex: 'reason',
    width: 100
  },
  {
    title: '操作人',
    dataIndex: 'operator_name',
    width: 20
  },

  {
    title: '更新时间',
    dataIndex: 'created_at',
    width: 50
  }
]
interface data {
  ownershipTableLoading: boolean
  loading: boolean
  historyOpen: boolean
  companyName: string
}
const data = reactive<data>({
  ownershipTableLoading: false,
  historyOpen: false,
  loading: false,
  companyName: ''
})
const shareOwnership = ref<tableData[]>([])
const historyList = ref<any[]>([])
let shareOwnershipTableData = shareOwnership.value

onMounted(() => {
  data.companyName = props.tableColumnsData.full_name
  if (data.companyName) {
    queryDetail()
  }
})
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const queryDetail = () => {
  data.ownershipTableLoading = true
  let requestParam = {
    url: '/admin/v1/cancellationSignStatus', //注销的签字状态
    method: 'post',
    data: {
      full_name: data.companyName
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      let list: tableData[] = res.data.map((item: any) => ({
        ...item,
        isEdit: false
      }))
      shareOwnership.value = [...list]
      shareOwnershipTableData = shareOwnership.value
      data.ownershipTableLoading = false
    })
    .catch((res) => {
      data.ownershipTableLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const queryHistory = () => {
  data.historyOpen = true
  data.loading = true
  historyList.value = []
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/cancelLogList',
    method: 'post',
    data: { page: 1, limit: 'all', type: [3], name: data.companyName }
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      historyList.value = res.data.list.map((item) => ({ ...item }))
      if (res.data.list.length === 0) {
        message.warn('当前公司没有查询到历史记录')
      }
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const reasonChange = (val) => {
  let list = val.replaceAll('获取签字状态: ', '').split('，')
  return list
}
</script>

<style lang="less" scoped></style>
