<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="优惠券码">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.code" placeholder="请输入优惠券码"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'code'">
            <sapn style="word-break: break-all;">{{ record.code }}</sapn>
        </template>

         <template v-if="column.dataIndex === 'name'">
            <div class="item" :style="record.is_usable?'':'filter: grayscale(100%);'">
  <div class="box">
    <div class="content">
	    <div class="page-group">
	      <i class="fold-page"></i>
        <span class="page">{{record.coupon_type==1?'直减现金券':record.coupon_type==2?'满减现金券':'折扣券'}}</span>
      </div>
      <i class="dot-left"></i>
      <i class="dot-right"></i>
    </div>
    <div class="footer">
      <div style="font-size: 16px;">{{record.name}}</div>
      <div v-if="record.efficacious_type == 1">
      <div>使用开始日期：{{record.start_time}}</div>
      <div>使用截至日期：{{record.end_time}}</div>
      </div>
      <div v-else-if="record.efficacious_type == 2">使用期限：领取之后{{ record.number_days }}日到期</div>
      <div v-else-if="record.efficacious_type == 3">使用期限：长期</div>
      <div v-if="!record.is_usable">不可用原因：{{ record.unusable_cause }}</div>
    </div>

    <div class="ribbon">{{ record.status==0?'待使用': record.status==1?'已使用': record.status==3?'已过期':'未知'}}</div>
  </div>
</div>
        </template>
        <template v-if="column.dataIndex === '优惠方式'">

        </template>
        <template v-if="column.dataIndex === 'publish_reason'">
          <div v-if="record.publish_reason">
            {{ record.publish_reason }}
          </div>
          <div v-else>-</div>
        </template>
         <template v-if="column.dataIndex === '发放人信息'">
 <div>{{ record.submit_operator_admin_info.name }} {{ record.created_at }}发放</div>
 <div v-if="record.coupon_type==1"><a-tag class="ant-tag-green">直减现金券，直减<span style="color: red;">¥{{ record.coupon_amout }}</span></a-tag></div>
          <div v-else-if="record.coupon_type==2"><a-tag class="ant-tag-green">满减优惠券，满<span style="color: red">¥{{ record.full_amount }}</span>，减<span style="color: red">¥{{record.coupon_amout}}</span></a-tag></div>
          <div v-else-if="record.coupon_type==3"><a-tag class="ant-tag-green">折扣券，<span style="color: red;">打{{ record.coupon_amout }}折</span></a-tag></div>
 <div v-if="record.publish_reason">理由：{{record.publish_reason}}</div>

        </template>
<template v-if="column.dataIndex === '优惠券使用情况'">
    <a-tag class="ant-tag-green" v-if="record.status==0">待使用</a-tag>

    <div v-if="record.status==1">
        <div>使用状态：<a-tag class="ant-tag-green" v-if="record.status==1">已使用</a-tag></div>
        <div>使用主体：{{record.full_name}}</div>
        <div>使用时间：{{record.use_time}}</div>
    </div>
    <a-tag class="ant-tag-green" v-if="record.status==3">已过期</a-tag>
    <a-tag class="ant-tag-green" v-if="record.status==4">已作废</a-tag>


</template>
<template v-if="column.dataIndex === '可使用平台'">
    <a-tag class="ant-tag-green" v-for="ptname in record.platform_infos"> {{ ptname.platform_abbreviation }}</a-tag>
        </template>

      </template>
    </a-table>
    <createCouPons
      v-if="state.showCreate"
      :detailData="state.detailData"
      @cancel="
        () => {
          loadData()
          state.showCreate = false
        }
      "
    ></createCouPons>
    <Issue
      v-if="state.showIssue"
      :detailData="state.detailData"
      @cancel="
        () => {
          loadData()
          state.showIssue = false
        }
      "
    ></Issue>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import createCouPons from './components/createCoupons.vue'
import Issue from './components/Issue.vue'

const columns = [
  {
    title: '优惠券券码',
    dataIndex: 'code',
    width:150
  },
  {
    title: '优惠券名字',
    dataIndex: 'name',
    width:200
  },

  {
    title: '发放人信息',
    dataIndex: '发放人信息',
    width:200
  },
  {
    title: '优惠券使用情况',
    dataIndex: '优惠券使用情况',
    width:200
  },

  {
    title: '可使用平台',
    dataIndex: '可使用平台',
    width:100
  }
]

interface data {
  showCreate: boolean
  showIssue: boolean
  detailData: object
  loading: boolean
}
const state = reactive<data>({
  showCreate: false,
  showIssue: false,
  detailData: {},
  loading: false
})
interface queryParam {
  code?: string
  template_type?: string
}

let param = ref<queryParam>({ code: '' })
let queryParam = param.value
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
const refreshTabel = () => {
  pagination.current = 1
  param.value = {}
  queryParam = param.value
  loadData()
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/getMyCoupon',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => ({ ...item, isShowOptions: false }))
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const onChange = (val) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
// 添加客户
const handleAdd = () => {
  state.detailData = {}
  state.showCreate = true
}
const handleIssue = (record) => {
  state.showIssue = true
  state.detailData = { ...record }
}
// 编辑客户
const handleEdit = (record) => {
  state.showCreate = true
  state.detailData = { ...record }
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/coupon/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped>
.item {
  width: 235px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-left: 2px;
  position: relative;
}
.box {
  background: #fff;
  border-radius: 5px;
  background: linear-gradient(45deg, orange, red);
}
.item .dot-left,.item .dot-right {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f5f5f5;
  position: absolute;
  z-index: 999;
}
.item .dot-left {
  bottom: -6px;
  left: -6px;
}
.item .dot-right {
  bottom: -6px;
  right: -6px;
}
.item .page-group {
  position: absolute;
  top: 10px;
  left: -2px;
  width: 100%;
  max-width: 120px;
}
.item .page-group .fold-page {
  display: block;
  width: 2px;
  height: 5px;
  background: #db0113;
  transform: skewY(-40deg);
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 0;
}
.item .page-group .page {
  position: absolute;
  z-index: 1;
  display: block;
  padding: 0 10px;
  height: 20px;
  background: linear-gradient(137deg, #ff5b5b 0%, #db0113 100%);
  border-radius: 0 20px 20px 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
}
.item .content {
  width: 100%;
  height: 40px;
  border-bottom: 1px dotted #f5f5f5;
  position: relative;
  z-index: 2;
}
.item .footer {
  color: #fff;
  font-size: 12px;
  padding: 5px 15px 10px 15px;
}
.footer-label {
  line-height: 24px;
}
.item .ribbon {
  width: 80px;
  height: 20px;
  background: #dd0d1f;
  position: absolute;
  right: -20px;
  top: 10px;
  transform: rotateZ(45deg);
  text-align: center;
  color: #fff;
  font-size: 12px;
}
</style>
