<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="600"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout">
        <a-form-item label="分类名称" name="name" required>
          <a-input placeholder="请输入分类名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="分类描述" name="describe">
          <a-textarea placeholder="请输入分类描述" v-model:value="formState.describe"></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const props = defineProps({
  id: {
    type: Number
  }
})
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增活动分类',
  loading: false
})

interface formState {
  name: string
  describe: string
}
let form = ref<formState>({
  name: '',
  describe: ''
})
let formState = form.value

onMounted(() => {
  if (props.id) {
    state.modalTitle = '编辑活动分类'
    queryDetail(props.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/activityCenterClass/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    form.value = res.data
    formState = form.value
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.id) {
        let requeryParam = {
          url: '/admin/v1/activityCenterClass/' + props.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/activityCenterClass',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('ok')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
