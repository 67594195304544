<template>
  <a-cascader
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    v-model:value="state.cascaderValue"
    @change="handleChange"
    placeholder="请选择受理机关"
    :field-names="{ label: 'name', value: 'name', children: 'children' }"
    :options="state.options"
    :load-data="loadData"
  ></a-cascader>
</template>

<script lang="ts" setup>
import { reactive, watch, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '300px'
  },
  type: {
    type: Number,
    default: 1
  }
})
const emit = defineEmits(['update:value', 'change'])
interface options {
  children: object[]
}
interface state {
  options: options[]
  cascaderValue: number[]
}
const state = reactive<state>({
  options: [],
  cascaderValue: []
})
watch(
  () => props.value,
  (val) => {
    // 如果进来的值和返回的值一样 直接ruturn 防止重复监听
    let list = [val.glj1, val.glj2, val.gls]
    if (state.cascaderValue == list) {
      return
    }
    if (val) {
      state.cascaderValue = list
      setTimeout(async () => {
        if (state.cascaderValue.length > 1) {
          let list: any = []
          let checkIndex = 0
          state.options.forEach((item: any, index: number) => {
            if (item.id == state.cascaderValue[0]) {
              checkIndex = index
              item.loading = false
              list.push(item)
            }
          })
          await loadData(list)
          let childrenCheckIndex = 0
          state.options[checkIndex].children.forEach((item: any, index: number) => {
            if (item.id == state.cascaderValue[1]) {
              childrenCheckIndex = index
              item.loading = false
              list.push(item)
            }
          })
          await loadData(list)
        }
      }, 300)
    }
  },
  { immediate: true, deep: true }
)

onMounted(() => {
  getByLevel()
})
// 处理级联选择器的change事件
const handleChange = (idList, value) => {
  //   let obj = {
  //     glj: idList[0],
  //     glj1: idList[1],
  //     glj2: idList[2]
  //   }
  let obj = {
    glj1: idList[0], //市
    glj2: idList[1], //区
    gls: idList[2] //所
  }
  emit('update:value', obj)
}
// 根据级别获取数据
const getByLevel = () => {
  let requestParam = {
    url: '/admin/v1/regOrgan',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.options = res.data.map((item) => {
        item.isLeaf = false
        return item
      })
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取失败')
      }
    })
}
// 加载数据
const loadData = async (selectedOptions) => {
  const targetOption = selectedOptions[selectedOptions.length - 1]
  let type = 'province'
  targetOption.loading = true
  let url = '/admin/v1/regOrgan?curOrgan=' + targetOption.id

  let requestParam = {
    url: url,
    method: 'get'
  }
  await baseService(requestParam)
    .then((res) => {
      targetOption.children = res.data.map((item: any) => {
        if (selectedOptions?.length === 2) {
          item.isLeaf = true
        } else {
          item.isLeaf = false
        }
        return item
      })
      targetOption.loading = false
      state.options = [...state.options]
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('加载失败')
      }
    })
}
</script>
