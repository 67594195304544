<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="优惠券码">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.code" placeholder="请输入优惠券码"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd">创建优惠券</a-button>
    </div>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
         <template v-if="column.dataIndex === 'name'">
            <a-tag class="ant-tag-green">{{text}}</a-tag>
        </template>
        <template v-if="column.dataIndex === '有效期类型'">
          <div v-if="record.efficacious_type==1">固定时间：{{ record.start_time }}-{{ record.end_time }}</div>
          <div v-else-if="record.efficacious_type==2">领取之后{{ record.number_days }}日到期</div>
          <div v-else-if="record.efficacious_type==3">长期</div>
        </template>
        <template v-if="column.dataIndex === '优惠方式'">
          <div v-if="record.coupon_type==1"><a-tag class="ant-tag-green">直减现金券</a-tag>直减<span style="color: red;">¥{{ record.coupon_amout }}</span></div>
          <div v-else-if="record.coupon_type==2"><a-tag class="ant-tag-green">满减优惠券</a-tag>满<span style="color: red">¥{{ record.full_amount }}</span>，减<span style="color: red">¥{{record.coupon_amout}}</span></div>
          <div v-else-if="record.coupon_type==3"><a-tag class="ant-tag-green">折扣券</a-tag><span style="color: red;">打{{ record.coupon_amout }}折</span></div>
        </template>
        <template v-if="column.dataIndex === 'publish_reason'">
          <div v-if="record.publish_reason">
            {{ record.publish_reason }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-if="column.dataIndex === 'operate'">
          <a @click="handleIssue(record)">发行</a>
          <a-divider type="vertical" />


          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除此模板?" @confirm="handleDelete(record)">
            <a style="color: red">删除</a>
          </a-popconfirm>
          <br/>
           <a @click="handleEdit(record)">发行使用情况（9/10）</a>
          <a-divider type="vertical" />
        </template>
      </template>
    </a-table>
    <createCouPons
      v-if="state.showCreate"
      :detailData="state.detailData"
      @cancel="
        () => {
          loadData()
          state.showCreate = false
        }
      "
    ></createCouPons>
    <Issue
      v-if="state.showIssue"
      :detailData="state.detailData"
      @cancel="
        () => {
          loadData()
          state.showIssue = false
        }
      "
    ></Issue>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import createCouPons from './components/createCoupons.vue'
import Issue from './components/Issue.vue'

const columns = [
  {
    title: '优惠券名字',
    dataIndex: 'name'
  },
  {
    title: '有效期类型',
    dataIndex: '有效期类型'
  },
  {
    title: '优惠方式',
    dataIndex: '优惠方式'
  },
  {
    title: '添加时间',
    dataIndex: 'created_at'
  },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]

interface data {
  showCreate: boolean
  showIssue: boolean
  detailData: object
  loading: boolean
}
const state = reactive<data>({
  showCreate: false,
  showIssue: false,
  detailData: {},
  loading: false
})
interface queryParam {
  code?: string
  template_type?: string
}

let param = ref<queryParam>({ code: '' })
let queryParam = param.value
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
const refreshTabel = () => {
  pagination.current = 1
  param.value = {}
  queryParam = param.value
  loadData()
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/couponList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => ({ ...item, isShowOptions: false }))
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const onChange = (val) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
// 添加客户
const handleAdd = () => {
  state.detailData = {}
  state.showCreate = true
}
const handleIssue = (record) => {
  state.showIssue = true
  state.detailData = { ...record }
}
// 编辑客户
const handleEdit = (record) => {
  state.showCreate = true
  state.detailData = { ...record }
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/coupon/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped></style>
