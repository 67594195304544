import { BasicLayout, RouteView } from '@/layouts'
import { Router } from './types'

export const example: Router = {
    path: '/',
    name: 'index',
    component: BasicLayout,
    redirect: '/customer',
    children: [
        {
            path: '/customer',
            name: 'customer',
            component: RouteView,
            redirect: '/customer',
            meta: { title: '客户管理' },
            children: [
                {
                    path: '/customer',
                    name: 'customer',
                    component: () => import('@/views/customer/CustomerList.vue'),
                    meta: { title: '客户管理', keepAlive: false },
                },
                {
                    path: '/customerxf',
                    name: 'customerxf',
                    component: () => import('@/views/customer/CustomerListxf.vue'),
                    meta: { title: '客户续费管理', keepAlive: false },
                },
                {
                    path: '/CustomerBusinessScope',
                    name: 'CustomerBusinessScope',
                    component: () => import('@/views/customer/CustomerBusinessScope.vue'),
                    meta: { title: '客户经营范围', keepAlive: false },
                },
                {
                    path: '/customer/pigeonhole',
                    name: 'pigeonhole',
                    component: () => import('@/views/customer/pigeonhole.vue'),
                    meta: { title: '营业执照归档', keepAlive: false },
                },
                {
                    path: '/customer/AddressRenew',
                    name: 'AddressRenew',
                    component: () => import('@/views/customer/AddressRenew.vue'),
                    meta: { title: '万能支付', keepAlive: false },
                },
                {
                    path: '/customer/ObtainExpirationDate',
                    name: 'ObtainExpirationDate',
                    component: () => import('@/views/customer/ObtainExpirationDate.vue'),
                    meta: { title: '批量查到期时间', keepAlive: false },
                },
                {
                    path: '/customer/CustomerListSimple',
                    name: 'CustomerListSimple',
                    component: () => import('@/views/customer/CustomerListSimple.vue'),
                    meta: { title: '更新核准日期', keepAlive: false },
                },
                {
                    path: '/logistic/otherLogistic',
                    name: 'otherLogistic',
                    component: () => import('@/views/logistic/OtherLogisticList.vue'),
                    meta: { title: '其他顺丰邮寄', keepAlive: false },
                },
            ],
        },
        // 核验
        {
            path: '/logout',
            name: 'logout',
            component: RouteView,
            redirect: '/logout',
            meta: { title: '注销' },
            children: [
                {
                    path: '/logout',
                    name: 'logout',
                    component: () => import('@/views/logout/lst.vue'),
                    meta: { title: '注销控制台', keepAlive: false },
                },
                {
                    path: '/logoutAll',
                    name: 'logoutAll',
                    component: () => import('@/views/logout/lstAll.vue'),
                    meta: { title: '所有注销客户', keepAlive: false },
                }
            ],
        },
        // 核验
        {
            path: '/verification',
            name: 'verification',
            component: RouteView,
            redirect: '/verification',
            meta: { title: '资料审核' },
            children: [
                {
                    path: '/verification',
                    name: 'verification',
                    component: () => import('@/views/verification/OtherData.vue'),
                    meta: { title: '资料审核', keepAlive: false },
                },
                {
                    path: '/reimburse',
                    name: 'verificationReimburse',
                    component: () => import('@/views/verification/Reimburse.vue'),
                    meta: { title: '报销审核', keepAlive: false },
                },
                {
                    path: '/recordManagement/verification',
                    name: 'recordVerification',
                    component: () => import('@/views/recordManagement/Verification.vue'),
                    meta: { title: '备案审核', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/verification/PlatformVerification',
                    name: 'PlatformVerification',
                    component: () => import('@/views/verification/PlatformVerification.vue'),
                    meta: { title: '平台充值审核', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/customerVerification',
                    name: 'customerVerification',
                    component: () => import('@/views/verification/customerVerification.vue'),
                    meta: { title: '客户删除审核', keepAlive: false },
                },
                {
                    path: '/publicTransfer',
                    name: 'publicTransfer',
                    component: () => import('@/views/financialManagement/publicTransfer.vue'),
                    meta: { title: '对公账户转账', keepAlive: false },
                },
                {
                    path: '/refund',
                    name: 'refund',
                    component: () => import('@/views/verification/refund.vue'),
                    meta: { title: '退款审核', keepAlive: false },
                },
                {
                    path: '/yichangAudit',
                    name: 'yichangAudit',
                    component: () => import('@/views/verification/yichangAuditList.vue'),
                    meta: { title: '资料下载审核', keepAlive: false },
                },
                {
                    path: '/customerAttrAdjust',
                    name: 'customerAttrAdjust',
                    component: () => import('@/views/verification/customerAttrAdjust.vue'),
                    meta: { title: '属性调整审核', keepAlive: false },
                }
            ],
        },


        // 特别行动
        // {
        //     path: '/action',
        //     name: 'action',
        //     component: RouteView,
        //     redirect: '/action/taxVerification',
        //     meta: { title: '特别行动' },
        //     children: [
        //         {
        //             path: '/action/taxVerification',
        //             name: 'taxVerification',
        //             component: () => import('@/views/action/TaxVerification.vue'),
        //             meta: { title: '特别行动', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //         {
        //             path: '/action/infoVerification',
        //             name: 'infoVerification',
        //             component: () => import('@/views/verification/InfoVerification.vue'),
        //             meta: { title: '天心区信息核查', keepAlive: false },
        //         },
        //     ],
        // },

        {
            path: '/workOrder',
            name: 'workOrder',
            component: () => import('@/views/workOrder/WorkOrderList.vue'),
            meta: { title: '工单管理', keepAlive: false },
        },

        // // 银行管理
        // {
        //     path: '/bank',
        //     name: 'bank',
        //     component: RouteView,
        //     redirect: '/bank',
        //     meta: { title: '银行管理', icon: 'none' },
        //     children: [
        //         {
        //             path: '/bank/BankAppointment',
        //             name: 'BankAppointment',
        //             component: () => import('@/views/bank/BankAppointment'),
        //             meta: { title: '银行预约', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //         {
        //             path: '/bank/BankCheckIn',
        //             name: 'BankCheckIn',
        //             component: () => import('@/views/bank/BankCheckIn'),
        //             meta: { title: '银行入住', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //     ],
        // },

        // // platformManagement
        // {
        //     path: '/companyManagement',
        //     name: 'companyManagement',
        //     component: RouteView,
        //     redirect: '/platformManagement',
        //     meta: { title: '运营公司管理', icon: 'none' },
        //     children: [
        //         {
        //             path: '/companyManagement/CompanyManagement',
        //             name: 'CompanyManagement',
        //             component: () => import('@/views/companyManagement/CompanyManagement'),
        //             meta: { title: '运营公司管理', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //         {
        //             path: '/companyManagement/PersonnelManagement',
        //             name: 'PersonnelManagement',
        //             component: () => import('@/views/companyManagement/PersonnelManagement'),
        //             meta: { title: '人员管理', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //     ],
        // },

        {
            path: '/platformManagement',
            name: 'platformManagementMgr',
            component: RouteView,
            redirect: '/platformManagement/platformManagement',
            meta: { title: '平台管理', keepAlive: false },
            children: [
                {
                    path: '/platformManagement/platformManagement',
                    name: 'platformManagement',
                    component: () => import('@/views/platformManagement/PlatformManagement.vue'),
                    meta: { title: '平台管理', keepAlive: false, hiddenHeaderContent: true },
                },
                {
                    path: '/platformManagement/restrictedAccess',
                    name: 'restrictedAccess',
                    component: () => import('@/views/platformManagement/RestrictedAccess.vue'),
                    meta: { title: '限制准入', keepAlive: false, hiddenHeaderContent: true },
                },
                {
                    path: '/platformManagement/templateManagement',
                    name: 'templateManagement',
                    component: () => import('@/views/platformManagement/TemplateManagement.vue'),
                    meta: { title: '平台模板库管理', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/platformManagement/addressNumberManagement',
                    name: 'addressNumberManagement',
                    component: () => import('@/views/system/AddressNumberManagement.vue'),
                    meta: { title: '地址编号管理', keepAlive: false, hiddenHeaderContent: true, },
                },
                // {
                //     path: '/platformManagement/Department',
                //     name: 'Department',
                //     component: () => import('@/views/platformManagement/Department'),
                //     meta: { title: '部门管理', keepAlive: false, hiddenHeaderContent: true },
                // },
                // {
                //     path: '/platformManagement/AskForLeave',
                //     name: 'AskForLeave',
                //     component: () => import('@/views/platformManagement/AskForLeave'),
                //     meta: { title: '请假申请', keepAlive: false, hiddenHeaderContent: true },
                // },
            ],
        },

        // // 系统公告
        // {
        //     path: '/systemNotice',
        //     name: 'systemNotice',
        //     component: RouteView,
        //     redirect: '/systemNotice/CompanyAnnouncements',
        //     meta: { title: '系统公告', icon: 'none', permission: ['result'] },
        //     children: [
        //         {
        //             path: '/systemNotice/CompanyAnnouncements',
        //             name: 'CompanyAnnouncements',
        //             component: () => import('@/views/systemNotice/CompanyAnnouncements'),
        //             meta: { title: '公司公告', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //         {
        //             path: '/systemNotice/NewsCenter',
        //             name: 'NewsCenter',
        //             component: () => import('@/views/systemNotice/NewsCenter'),
        //             meta: { title: '新闻中心', keepAlive: false, hiddenHeaderContent: true },
        //         },
        //     ],
        // },
        {
            path: '/userManagement',
            component: RouteView,
            redirect: '/userManagement/UserManagement',
            name: 'userManagement',
            meta: { title: '用户', keepAlive: false, },
            children: [
                {
                    path: '/userManagement/userManagement',
                    name: 'userManagement',
                    component: () => import('@/views/userManagement/UserManagement.vue'),
                    meta: { title: '用户管理', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/userManagement/authorityManagement',
                    name: 'authorityManagement',
                    component: () => import('@/views/userManagement/AuthorityManagement.vue'),
                    meta: { title: '用户权限分组', keepAlive: false, hiddenHeaderContent: true, },
                },
            ],
        },
        {
            path: '/financial',
            component: RouteView,
            redirect: '/financial/recharge',
            name: 'financial',
            meta: { title: '财务管理', keepAlive: false, },
            children: [
                {
                    path: '/financial/recharge',
                    name: 'recharge',
                    component: () => import('@/views/financialManagement/Recharge.vue'),
                    meta: { title: '充值明细', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/financial/consumption',
                    name: 'consumption',
                    component: () => import('@/views/financialManagement/consumption.vue'),
                    meta: { title: '消费明细', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/coupons/MyCouponsList',
                    name: 'MyCouponsList',
                    component: () => import('@/views/coupons/MyCouponsList.vue'),
                    meta: { title: '我的优惠券', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/financial/UsersMoneyLog',
                    name: 'UsersMoneyLog',
                    component: () => import('@/views/financialManagement/UsersMoneyLog.vue'),
                    meta: { title: '余额变动日志', keepAlive: false, hiddenHeaderContent: true, },
                }
                // {
                //     path: '/financial/WasteBook',
                //     name: 'WasteBook',
                //     component: () => import('@/views/financialManagement/WasteBook.vue'),
                //     meta: { title: '流水明细', keepAlive: false, hiddenHeaderContent: true, },
                // },
                // {
                //     path: '/financial/stistics',
                //     name: 'stistics',
                //     component: () => import('@/views/financialManagement/Stistics.vue'),
                //     meta: { title: '报表统计', keepAlive: false, hiddenHeaderContent: true, },
                // },
            ],
        },
        {
            path: '/statistics',
            component: RouteView,
            redirect: '/statistics/operate',
            name: 'statistics',
            meta: { title: '报表统计', keepAlive: false },
            children: [
                {
                    path: '/statistics/operate',
                    name: 'operate',
                    component: () => import('@/views/statistics/operate.vue'),
                    meta: { title: '运营情况', keepAlive: false, hiddenHeaderContent: true, },
                }
            ],
        },
        {
            path: '/recordManagement',
            component: RouteView,
            redirect: '/recordManagement/filingInformation',
            name: 'recordManagement',
            meta: { title: '备案管理', keepAlive: false, },
            children: [
                {
                    path: '/recordManagement/filingInformation',
                    name: 'filingInformation',
                    component: () => import('@/views/recordManagement/FilingInformation.vue'),
                    meta: { title: '渠道客戶备案', keepAlive: false, hiddenHeaderContent: true, },
                }
            ],
        },
        {
            path: '/activity',
            name: 'activityManagement',
            component: RouteView,
            meta: { title: '活动管理', keepAlive: false },
            children: [
                {
                    path: '/activity/activity',
                    name: 'activity',
                    component: () => import('@/views/activity/Activity.vue'),
                    meta: { title: '活动中心', keepAlive: false },
                },
                {
                    path: '/activity/inventory',
                    name: 'inventory',
                    component: () => import('@/views/activity/Inventory.vue'),
                    meta: { title: '购买库存地址', keepAlive: false },
                },
            ]
        },
        {
            path: '/system',
            component: RouteView,
            redirect: '/system/notificationManagement',
            name: 'system',
            meta: { title: '系统管理', keepAlive: false, },
            children: [
                // {
                //     path: '/system/CustomerLog',
                //     name: 'CustomerLog',
                //     component: () => import('@/views/system/CustomerLog'),
                //     meta: { title: '客户日志', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] },
                // },
                // {
                //     path: '/system/CustomerStatus',
                //     name: 'CustomerStatus',
                //     component: () => import('@/views/system/CustomerStatus'),
                //     meta: { title: '客户状态', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] },
                // },
                {
                    path: '/system/notificationManagement',
                    name: 'notificationManagement',
                    component: () => import('@/views/system/NotificationManagement.vue'),
                    meta: { title: '通知管理', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/system/menuManagement',
                    name: 'menuManagement',
                    component: () => import('@/views/system/MenuManagement.vue'),
                    meta: { title: '菜单管理', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/system/reimburse',
                    name: 'reimburse',
                    component: () => import('@/views/system/Reimburse.vue'),
                    meta: { title: '报销管理', keepAlive: false, hiddenHeaderContent: true },
                },
                {
                    path: '/system/Setting',
                    name: 'Setting',
                    component: () => import('@/views/system/Setting.vue'),
                    meta: { title: '后台配置', keepAlive: false, hiddenHeaderContent: true },
                },
            ],
        },

        {
            path: '/addressBook',
            component: RouteView,
            redirect: '/addressBook/Peer',
            name: 'addressBook',
            meta: { title: '通讯录', keepAlive: false, },
            children: [
                // {
                //     path: '/addressBook/Peer',
                //     name: 'Peer',
                //     component: () => import('@/views/addressBook/Peer.vue'),
                //     meta: { title: '同行通讯录', keepAlive: false, hiddenHeaderContent: true, },
                // },
                {
                    path: '/addressBook/work',
                    name: 'Work',
                    component: () => import('@/views/addressBook/Work.vue'),
                    meta: { title: '工作通讯录', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/addressBook/AuthCode',
                    name: 'AuthCode',
                    component: () => import('@/views/addressBook/AuthCode.vue'),
                    meta: { title: '验证码', keepAlive: false, hiddenHeaderContent: true, },
                },
            ],
        },
        {
            path: '/coupons',
            component: RouteView,
            redirect: '/coupons/list',
            name: 'coupons',
            meta: { title: '优惠券', keepAlive: false, },
            children: [
                {
                    path: '/coupons/list',
                    name: 'list',
                    component: () => import('@/views/coupons/list.vue'),
                    meta: { title: '优惠券管理', keepAlive: false, hiddenHeaderContent: true, },
                }
            ],
        },

        // 工商年检
        {
            path: '/annualInspection',
            name: 'annualInspection',
            component: RouteView,
            redirect: '/annualInspection/lst',
            meta: { title: '工商年检' },
            children: [
                {
                    path: '/annualInspection/lst',
                    name: 'annualInspection',
                    component: () => import('@/views/annualInspection/lst.vue'),
                    meta: { title: '工商年检', keepAlive: false, hiddenHeaderContent: true },
                }
            ],
        },
        {
            path: '/personal',
            name: 'personal',
            component: () => import('@/views/system/Personal.vue'),
            meta: { title: '个人中心', keepAlive: false },
        },
        {
            path: '/log',
            component: RouteView,
            redirect: '/log/logManagement',
            name: 'log',
            meta: { title: '系统运行日志', keepAlive: false, },
            children: [
                {
                    path: '/log/logManagement',
                    name: 'logManagement',
                    component: () => import('@/views/log/logManagement.vue'),
                    meta: { title: '总日志查看', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/log/statusManagement',
                    name: 'statusManagement',
                    component: () => import('@/views/log/statusManagement.vue'),
                    meta: { title: '总状态查看', keepAlive: false, hiddenHeaderContent: true, },
                },
                {
                    path: '/log/signatureStatusManagement',
                    name: 'signatureStatusManagement',
                    component: () => import('@/views/log/signatureStatusManagement.vue'),
                    meta: { title: '签字状态查看', keepAlive: false, hiddenHeaderContent: true, },
                },
            ],
        },
    ]
}
