<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <a-skeleton :loading="state.classLoading" active :paragraph="{ rows: 2 }">
      <div class="class-list">
        <div class="class-item pointer" :class="queryParam.key === item.key ? 'primary' : ''" @click="selectClassLick(item.key)" v-for="(item, index) in classList" :key="index">
          {{ item.name }}
        </div>
        <div class="class-item pointer" @click="handleAdd()">
          <PlusOutlined />
        </div>
      </div>
    </a-skeleton>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="false">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'operate'">
          <a-popconfirm title="确定删除此模板?" @confirm="handleDelete(record)">
            <a style="color: red">删除</a>
          </a-popconfirm>
        </template>
        <template v-else-if="column.dataIndex === 'content'">
          <div
            v-if="!record.isEdit"
            @click="
              () => {
                record.isEdit = true
              }
            "
            class="pointer"
          >
            {{ record.content }}<EditOutlined class="ml-x" />
          </div>
          <div v-else>
            <a-input v-model:value="record.editInput"></a-input>
            <a-button
              :loading="record.buttonLoading"
              class="mt-1x mr-1x"
              @click="
                () => {
                  record.isEdit = false
                  record.editInput = record.content
                }
              "
              >取消</a-button
            >
            <a-button type="primary" :loading="record.buttonLoading" @click="submitEdit(record)">确定</a-button>
          </div>
        </template>
      </template>
    </a-table>

    <createSetting :isCopy="state.isCopy" v-if="state.showCreate" :classList="classList" @ok="onOk" @cancel="onCancel"></createSetting>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined, EditOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import createSetting from './components/createSetting.vue'
// import createClass from './components/createClass.vue'
const columns = [
  {
    title: '变量标题',
    dataIndex: 'title'
  },
  {
    title: '变量值',
    dataIndex: 'content'
  },
  {
    title: '变量描述',
    dataIndex: 'tip'
  },
  //   {
  //     title: '创建时间',
  //     dataIndex: 'created_at'
  //   },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]
interface data {
  showCreate: boolean
  showCreateClass: boolean
  editClassId: any
  loading: boolean
  classLoading: boolean
  isCopy: boolean
}
const state = reactive<data>({
  showCreate: false,
  showCreateClass: false,
  editClassId: null,
  loading: false,
  classLoading: false,
  isCopy: false
})
interface queryParam {
  key: string
}

let param = ref<queryParam>({ key: '' })
let queryParam = param.value
let dataSource = ref([])
let classList = ref<{ name: string; key: string }[]>([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
  classLoadData()
})

const selectClassLick = (id) => {
  dataSource.value = []
  loadData()
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/config/index',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      dataSource.value = res.data.map((item) => {
        return {
          ...item,
          editInput: item.content,
          isEdit: false,
          buttonLoading: false
        }
      })
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const classLoadData = () => {
  state.classLoading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/config/create',
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      classList.value = []
      state.classLoading = false
      param.value.key = Object.keys(res.data.group_list)[0]
      queryParam = param.value
      for (const key in res.data.group_list) {
        classList.value.push({
          key: key,
          name: res.data.group_list[key]
        })
      }
    })
    .catch((res) => {
      state.classLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取分类失败')
      }
    })
}
const onOk = () => {
  loadData()
  classLoadData()
  state.showCreateClass = false
  state.showCreate = false
}
const onCancel = () => {
  state.showCreateClass = false
  state.showCreate = false
}
// 添加客户
const handleAdd = () => {
  state.showCreate = true
}
const submitEdit = (record) => {
  record.buttonLoading = true
  let requestParam = {
    url: '/admin/config/' + record.id,
    method: 'PUT',
    data: {
      content: record.editInput
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      record.buttonLoading = false
      message.success(res.msg)
      loadData()
      classLoadData()
    })
    .catch((res) => {
      record.buttonLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('修改失败')
      }
    })
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/config/destroy/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
      classLoadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped>
.class-list {
  display: flex;
  flex-wrap: wrap;
  .class-item.primary {
    background: #fff;
    border-color: var(--ant-primary-color-deprecated-bg);
  }
  .class-item {
    border: 1px solid #f3f3f3;
    background: #f1f1f160;
    padding: 8px 10px;
    margin-right: 2px;
    font-size: 12px;
    display: flex;
    margin-bottom: 8px;
  }
}
</style>
