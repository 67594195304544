<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="模板类型">
          <a-radio-group v-model:value="formState.template_file_type" :options="templateFileTypeOptions"> </a-radio-group>
        </a-form-item>
        <a-form-item label="统一社会信用代码" name="name" required>
          <a-input placeholder="请输入统一社会信用代码" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="联络员身份证号码">
          <a-input placeholder="请输入联络员身份证号码" v-model:value="formState.name"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const templateFileTypeOptions = [
  { label: '选择公司', value: 1 },
  { label: '上传文件', value: 2 }
]
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增模板',
  loading: false
})

interface formState {
  name: string
  is_show_client: number
  template_file_type: number
  template_type: number
  template_url: object[]
}
let form = ref<formState>({
  name: '',
  is_show_client: 1,
  template_file_type: 1,
  template_type: 1,
  template_url: []
})
let formState = form.value

onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑模板'
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/template/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    form.value = res.data
    formState = form.value
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/template/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/template',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
