<template>
  <a-modal title="标签管理" :width="1000" :open="true" @ok="onFormSubmit" @cancel="cancel">
    <a-form ref="ruleForm" :model="state.formData" v-bind="formLayout">
      <a-form-item
        v-for="(item, index) in state.formData.labelList"
        :key="index"
        :label="`标签${index + 1}`"
        :name="['labelList', index, 'tagname']"
        :rules="{ required: true, message: '请输入标签或者删除此标签', trigger: 'change' }"
      >
        <div class="label-form-item">
          <a-form-item-rest>
            <a-input v-model:value="item.tagname" />
          </a-form-item-rest>
          <DeleteOutlined v-if="state.formData.labelList.length !== 1" class="delete-icon" @click="deleteLabelClick(index)" />
        </div>
        <div class="select-color">
          <span>选择标签颜色:</span>
          <a-form-item-rest>
            <a-radio-group style="margin: 0 10px" size="small" v-model:value="item.color">
              <a-radio-button value="blue"> 正常 </a-radio-button>
              <a-radio-button value="green"> 提示 </a-radio-button>
              <a-radio-button value="orange"> 警告 </a-radio-button>
              <a-radio-button value="red"> 危险 </a-radio-button>
            </a-radio-group>
          </a-form-item-rest>
          <ColorPicker class="label-color-picker" v-model:pureColor="item.color" defaultColor="green" />
          <span class="ml-3x">创建人：{{ item.author }} <br />{{ item.created_at ? dayjs(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm') : '' }} </span>
        </div>
      </a-form-item>
      <a-form-item label=" " :colon="false">
        <a-button type="primary" @click="addLabel">添加标签</a-button>
      </a-form-item>
      <a-form-item label="标签预览">
        <span v-for="(item, index) in state.formData.labelList" :key="index">
          <a-tag v-if="item.tagname" :color="item.color">{{ item.tagname }}</a-tag>
        </span>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { reactive, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
const ruleForm = ref()
const emit = defineEmits(['cancel'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: () => false
  },
  tableColumnsData: {
    type: Object,
    default: () => {}
  },
  value: {
    type: Array,
    default: () => [
      { value: '正规企业', color: 'green' },
      { value: '外省', color: 'orange' },
      { value: '投诉', color: 'red' },
      { value: '卖发票', color: 'red' },
      { value: '洗钱', color: 'red' }
    ]
  }
})

const state = reactive<any>({
  formData: {
    labelList: []
  }
})
onMounted(() => {
  queryDetail()
})

const deleteLabelClick = (index) => {
  state.formData.labelList.splice(index, 1)
}
const queryDetail = () => {
  let requeryParam = {
    url: '/admin/v1/tagsmanage',
    method: 'post',
    data: {
      customer_id: props.tableColumnsData.id,
      action: 'get'
    }
  }
  baseService(requeryParam).then((res) => {
    if (res.data.length > 0) {
      state.formData.labelList = res.data
    } else {
      state.formData.labelList.push({
        tagname: '',
        color: 'green',
        es_id: 1
      })
    }
  })
}
const addLabel = () => {
  state.formData.labelList.push({
    tagname: '',
    color: 'green',
    es_id: 1
  })
}
// 提交
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      let requeryParam = {
        url: '/admin/v1/tagsmanage',
        method: 'post',
        data: {
          data: state.formData.labelList,
          customer_id: props.tableColumnsData.id,
          action: 'save'
        }
      }
      baseService(requeryParam).then((res) => {
        message.success('保存成功')
        cancel()
      })
    })
    .catch(() => {})
}
const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
.label-form-item {
  display: flex;
  align-items: center;

  .delete-icon {
    color: red;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.select-color {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .label-color-picker {
    border: 1px solid #dcdfe6;
    padding: 4px;
    border-radius: 4px;
  }
}
</style>
