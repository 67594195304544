<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
  <a-config-provider
    :locale="zh_CN"
    :theme="{
      token: {
        colorPrimary: systemConfig.state.color,
        borderRadius: 2
      }
    }"
  >
    <!-- algorithm: theme.darkAlgorithm,夜间主题 -->
    <router-view />
  </a-config-provider>
  <!-- 锁屏 -->
  <LockScreen />
</template>

<script lang="ts" setup name="App">
import { onErrorCaptured, onMounted } from 'vue'
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
import { setDeviceType } from '@/utils/device'
import LockScreen from '@/components/LockScreen/index.vue'
import emitter from '@/utils/eventBus'
import { useRouter } from 'vue-router'
import ls from '@/utils/Storage'
import { systemConfig } from '@/store/reactiveState'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import store from '@/store'
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
// import { theme } from 'ant-design-vue';
window.onresize = setDeviceType
setDeviceType()

const whiteList = ['/user/login', '/Action_customer'] // 不进行拦截的白名单
const router = useRouter()
emitter.once('axios_goto_login', () => {
  router.push({ name: 'login' })
})
onMounted(() => {
  let paramName: any = window.location.pathname
  if (paramName == '/' && ls.get('ACCESS_TOKEN')) {
    store.dispatch('GetInfo')
    return
  } else if (paramName == '/') {
    return
  }
  if (whiteList.indexOf(paramName) == -1) {
    store.commit('SET_IMAGE_DOMAIN', ls.get('IMAGE_DOMAIN'))
    // 在免登录白名单
    store.dispatch('GetInfo')
  }
})
//全局错误处理
onErrorCaptured((err, instance, info) => {
  // if (window.env !== 'localhost') {
  //     // debugger
  //     console.log(err, instance, info)
  //     Modal.error({
  //         title: 'System Error',
  //         content: h('pre', err.stack),
  //         class: 'errorCapturedModal',
  //         width: '60%'
  //     })
  // }
})
</script>

<style></style>
