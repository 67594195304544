<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="优惠券名称" name="name" required>
          <a-input placeholder="优惠券名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="优惠券类型" name="coupon_type">
          <a-radio-group v-model:value="formState.coupon_type">
            <a-radio :value="2">满减券</a-radio>
            <a-radio :value="3">折扣券</a-radio>
            <a-radio :value="1">现金券（一般不用）</a-radio>
          </a-radio-group>
        </a-form-item>

        <div v-if="formState.coupon_type != 3">
          <a-form-item v-if="formState.coupon_type == 2" label="门槛金额" extra="满多少元可以使用" name="full_amount" required>
            <a-input placeholder="请输入门槛金额" v-model:value="formState.full_amount" addonAfter="元"></a-input>
          </a-form-item>
          <a-form-item label="优惠卷金额" name="coupon_amout" required>
            <a-input placeholder="请输入优惠卷金额" v-model:value="formState.coupon_amout" addonAfter="元"></a-input>
          </a-form-item>
        </div>
        <div v-else>
          <a-form-item label="折扣率" name="coupon_amout" extra="优惠券折扣不能小于1折，且不可大于9.9折" required>
            <a-input-number v-model:value="formState.coupon_amout" :min="0.1" :max="9.9" :step="0.1" :precision="1" addonAfter="折" />
          </a-form-item>
          <a-form-item label="最大折扣金额" name="discount_sum">
            <a-input placeholder="请输入最大折扣金额" v-model:value="formState.discount_sum" addonAfter="元"></a-input>
          </a-form-item>
        </div>

        <a-form-item label="有效期类型" name="efficacious_type">
          <a-radio-group v-model:value="formState.efficacious_type">
            <a-radio :value="1">固定时间</a-radio>
            <a-radio :value="2">领取之后几天内</a-radio>
            <a-radio :value="3">长期</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="formState.efficacious_type == 1" label="优惠券有效期" name="start_time" required>
          <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
        </a-form-item>
        <a-form-item v-else-if="formState.efficacious_type == 2" label="领取之后" name="number_days" required>
          <a-input placeholder="请输入天数" v-model:value="formState.number_days" addonAfter="天可用"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { Upload } from '@/components'
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD HH:mm:ss'
const startTime = dayjs().format(format)
const endTime = dayjs().add(1, 'years').format(format)
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  name: [{ required: true, message: '请输入名称', trigger: 'change' }],
  platform_ids: [{ required: true, message: '请输入使用平台', trigger: 'change' }],
  coupon_amout: [{ required: true, message: '请输入金额', trigger: 'change' }],
  start_time: [{ required: true, message: '请输入生效时间', trigger: 'change' }],
  end_time: [{ required: true, message: '请输入失效时间', trigger: 'change' }],
  quantity: [{ required: true, message: '请输入数量', trigger: 'change' }],
  user_nickname: [{ required: true, message: '请输入绑定用户名', trigger: 'change' }],
  publish_reason: [{ required: true, message: '请输入发行原因', trigger: 'change' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const rangTime = computed(() => {
  let array: any = []
  if (formState.start_time && formState.end_time) {
    const startAndEnd_time: string[] = []
    startAndEnd_time[0] = formState.start_time
    startAndEnd_time[1] = formState.end_time
    array = startAndEnd_time.map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
const rangTimeChange = (val, str) => {
  if (val) {
    formState.start_time = str[0]
    formState.end_time = str[1]
  } else {
    formState.start_time = null
    formState.end_time = null
  }
}
interface state {
  modalTitle: string
  loading: boolean
  platformOptions: any[]
}
const state = reactive<state>({
  modalTitle: '新增优惠券',
  loading: false,
  platformOptions: []
})

let form = ref<any>({
  coupon_type: 2,
  efficacious_type: 1,
  start_time: startTime,
  end_time: endTime
})
let formState = form.value

onMounted(() => {
  queryPlatform()
  if (props.detailData?.id) {
    state.modalTitle = '编辑优惠券'
    queryDetail(props.detailData.id)
  }
})
const queryPlatform = () => {
  let requeryParam = {
    url: '/admin/v1/platformList',
    method: 'post',
    data: {
      limit: 'all'
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.platformOptions = res.data.list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/coupon/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam)
    .then((res) => {
      state.loading = false
      let data = res.data
      data.start_time = dayjs(data.start_time)
      data.end_time = dayjs(data.end_time)

      form.value = data
      formState = form.value
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
      state.loading = false
    })
}
const filterOption = (input: string, option: any) => {
  let bool = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  return bool
}
const selectChange = (val, e: any[]) => {}

const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      let data = { ...formState }
      data.start_time = dayjs(data.start_time).format('YYYY-MM-DD HH:mm:ss')
      data.end_time = dayjs(data.end_time).format('YYYY-MM-DD HH:mm:ss')
      if (props.detailData?.id) {
        let requeryParam = {
          url: '/admin/v1/coupon/' + props.detailData.id,
          method: 'put',
          data: data
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let data = { ...formState }
        data.start_time = dayjs(data.start_time).format('YYYY-MM-DD HH:mm:ss')
        data.end_time = dayjs(data.end_time).format('YYYY-MM-DD HH:mm:ss')
        let requeryParam = {
          url: '/admin/v1/coupon',
          method: 'post',
          data: data
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
