<template>
  <div>
    <a-modal
      title="电子证明"
      :bodyStyle="{ padding: '0 20px' }"
      :width="700"
      :footer="false"
      wrapClassName="addresssRenewModal"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
      :open="true"
      @cancel="closeModal"
      ><a-watermark content="电子收据专用" :gap="[40, 40]">
        <div class="d-flex">
          <a-qrcode style="flex-shrink: 0" :value="`https://console.cskaiyela.com/m/pages/verification/antiCounterfeit?token=${props.counterfeitInfo.token}&type=1`" :size="106" :bordered="false" />
          <div class="p-1x" v-html="props.counterfeitInfo.info.remark2"></div>
        </div>
      </a-watermark>
    </a-modal>

    <a-modal title="电子证明" :bodyStyle="{ padding: '0 20px' }" :width="700" :mask="false" :footer="false" :maskClosable="false" :keyboard="false" :open="true" @cancel="closeModal">
      <a-watermark content="电子收据专用" :gap="[40, 40]">
        <div class="d-flex">
          <a-qrcode style="flex-shrink: 0" :value="`https://console.cskaiyela.com/m/pages/verification/antiCounterfeit?token=${props.counterfeitInfo.token}&type=1`" :size="106" :bordered="false" />
          <div class="p-1x" v-html="props.counterfeitInfo.info.remark"></div>
        </div>
      </a-watermark>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, ref } from 'vue'
const emit = defineEmits(['cancel'])

const props = defineProps({
  counterfeitInfo: {
    type: Object,
    default: () => {}
  }
})

const closeModal = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 15px;
}
.d-flex {
  align-items: center;
}
</style>
<style lang="less">
.addresssRenewModal {
  .ant-modal {
    top: 320px;
  }
}
</style>
