interface storageOptType {
    namespace?: string
    isEncrypt?: boolean
}

const options = Object.assign({
    isEncrypt: false
}, {
    namespace: 'PRO_'
})

let hasSetStorage = false

export const storage = {
    getKey: (key: string) => {
        return options.namespace + key
    },
    setOptions: (opt: storageOptType) => {
        if (hasSetStorage) {
            console.error('Has set storage:', options)
            return
        }
        Object.assign(options, opt)
        hasSetStorage = true
    },
    set: (key: string, value, expire?: number | null) => {
        const stringData = JSON.stringify({
            value,
            expire: expire ? new Date().getTime() + expire * 1000 : null
        })
        window.localStorage.setItem(storage.getKey(key), stringData)
    },
    setObj: (key: string, newVal, expire?) => {
        const oldVal = storage.get(key)
        if (!oldVal) {
            storage.set(key, newVal, expire)
        } else {
            Object.assign(oldVal, newVal)
            storage.set(key, oldVal, expire)
        }
    },
    /**
     * 读取缓存
     * @param {string} key 缓存键
     * @param {*=} def 默认值
     */
    get: (key: string) => {
        let item = window.localStorage.getItem(storage.getKey(key))
        if (item) {
            try {
                const data = JSON.parse(item)
                const { value, expire } = data
                // 在有效期内直接返回
                if (expire === null || expire >= Date.now()) {
                    return value
                }
                storage.remove(key)
            } catch (e) {
                console.error(e)
            }

        }
        return null
    },
    remove: (key: string) => {
        window.localStorage.removeItem(storage.getKey(key))
    },
    clear: (): void => {
        window.localStorage.storage.clear()
    }
}

export default storage
