<template>
  <a-modal
    :keyboard="false"
    :maskClosable="false"
    :title="state.modalTitle"
    :width="state.modalWidth"
    :open="true"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <div style="padding: 30px 50px; font-size: 20px" v-if="!state.tableData[0].stockholder">
      <div>请选择股东类型</div>
      <div class="category">
        <div @click="initialTypeChange(2)" style="background: #e8f1ff; color: #3f8cff" class="category-item">自然人</div>
        <div @click="initialTypeChange(1)" style="background: #e0f9f2; color: #00d097" class="category-item">法人</div>
      </div>
    </div>
    <a-form ref="ruleForm" :model="state.tableData[0]" :rules="rules" v-if="state.tableData[0].stockholder == 1" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
      <a-form-item name="is_bensheng" label="是否为本省企业">
        <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="state.tableData[0].is_bensheng" checked-children="是本省企业" un-checked-children="不是本省企业"></a-switch>
      </a-form-item>
      <a-form-item label="企业名称" name="name">
        <a-input placeholder="请输入企业名称" v-model:value="state.tableData[0].name"></a-input>
      </a-form-item>
      <a-form-item label="企业统一社会信用代码" name="corporate_credit_code">
        <a-input placeholder="请输入统一社会信用代码" v-model:value="state.tableData[0].corporate_credit_code"></a-input>
      </a-form-item>
      <a-form-item label="法人代表姓名" name="frdbname" v-if="state.tableData[0].is_bensheng == 2">
        <a-input placeholder="请输入法人代表姓名" v-model:value="state.tableData[0].frdbname"></a-input>
      </a-form-item>
      <a-form-item name="address" label="住所">
        <a-input placeholder="地址" v-model:value="state.tableData[0].address"></a-input>
      </a-form-item>
      <a-form-item label="出资金额" name="investment_money">
        <a-form-item-rest>
          <a-input type="number" addon-after="万元" v-model:value="state.tableData[0].investment_money" style="width: 200px; display: inline-block" placeholder="请填写出资金额"></a-input>
        </a-form-item-rest>
        <span> 认缴截止日期 </span>
        <a-form-item-rest>
          <a-date-picker
            :disabledDate="disabledDate"
            v-model:value="state.tableData[0].subscribe_endtime"
            :format="format"
            style="width: 200px; margin-left: 10px; display: inline-block"
            placeholder="认缴期限"
          />
        </a-form-item-rest>
      </a-form-item>
      <a-form-item name="area_ids" label="省市区">
        <AddressCascader v-model:value="state.tableData[0].area_ids"> </AddressCascader>
      </a-form-item>

      <a-form-item label="联系号码" name="phone_number">
        <a-input placeholder="请输入联系号码" v-model:value="state.tableData[0].phone_number"></a-input>
      </a-form-item>
      <a-form-item label="营业执照" name="business_license_url">
        <Upload
          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
          :upload_path="`public/uploads/${props.uploadPathObj?.platformName}/${props.uploadPathObj?.platformCompanyName}/客户资料/${props.uploadPathObj?.mainTypeName}/${props.uploadPathObj?.date}/${props.uploadPathObj?.companyName}/`"
          v-model:value="state.tableData[0].business_license_url"
          uploadType="picture-card"
          uploadText="上传营业执照"
          :multiple="false"
          :limitation="1"
        ></Upload>
      </a-form-item>
    </a-form>
    <a-form ref="personForm" :model="state.tableData[0]" :rules="personRules" v-if="state.tableData[0].stockholder == 2" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
      <div class="information">
        <div class="information-right">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept=".jpeg,.png,.jpg"
            :before-upload="beforeUpload"
            @change="
              (info) => {
                handleChange(info, 'id_card_front')
              }
            "
          >
            <img class="upload-img" type="" v-if="state.tableData[0].id_card_front" :src="$store.state.user.image_domain + state.tableData[0].id_card_front" alt="avatar" />
            <div v-else class="upload-img">
              <PlusOutlined />
              <div class="ant-upload-text">上传人员身份证照片(人像面)</div>
            </div>
          </a-upload>
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept=".jpeg,.png,.jpg"
            :before-upload="beforeUpload"
            @change="
              (info) => {
                handleChange(info, 'id_card_back')
              }
            "
          >
            <img class="upload-img" v-if="state.tableData[0].id_card_back" :src="$store.state.user.image_domain + state.tableData[0].id_card_back" alt="avatar" />
            <div v-else class="upload-img">
              <PlusOutlined />
              <div class="ant-upload-text">上传人员身份证照片(国徽面)</div>
            </div>
          </a-upload>
        </div>
      </div>
      <a-table :columns="columns" :data-source="state.tableData" :pagination="false">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'id_card_number'">
            <span> 身份证号码 </span>
          </template>
          <template v-else-if="column.dataIndex === 'address'">
            <span> 住址 </span>
          </template>
        </template>
        <template #bodyCell="{ column, record, index, text }">
          <template v-if="column.dataIndex === 'fullname'">
            <a-form-item class="m-0" name="fullname" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="姓名" v-model:value="record.fullname"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'gender'">
            <a-form-item class="m-0" name="gender" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-select style="width: 80px" v-model:value="record.gender" placeholder="性别">
                <a-select-option :value="1"> 男</a-select-option>
                <a-select-option :value="2"> 女</a-select-option>
              </a-select>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'nationality'">
            <a-form-item class="m-0" name="nationality" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-select v-model:value="record.nationality" placeholder="民族">
                <a-select-option :value="item" v-for="(item, index) in state.nationalityList" :Key="index"> {{ item }}</a-select-option>
              </a-select>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'id_card_number'">
            <a-form-item class="m-0" name="id_card_number" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="身份证" v-model:value="record.id_card_number"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'address'">
            <a-form-item class="m-0" name="address" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-input placeholder="住所" v-model:value="record.address"></a-input>
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'id_card_validity_start'">
            <a-form-item class="m-0" name="id_card_validity_start" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-date-picker placeholder="身份证开始日期" :format="format" v-model:value="record.id_card_validity_start" />
            </a-form-item>
          </template>
          <template v-else-if="column.dataIndex === 'id_card_validity_end'">
            <a-form-item class="m-0" name="id_card_validity_end" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-form-item-rest>
                <a-date-picker
                  placeholder="身份证结束日期"
                  :format="format"
                  v-model:value="record.id_card_validity_end"
                  style="width: 140px"
                  class="mr-1x"
                  :disabled="state.tableData[0].identity_perpetual == 1"
                />
              </a-form-item-rest>
              <a-form-item-rest>
                <a-checkbox :checked="state.tableData[0].identity_perpetual == 1 ? true : false" @change="identityPerpetualChange">长期</a-checkbox>
              </a-form-item-rest>
            </a-form-item>
          </template>
        </template>
      </a-table>
      <a-form-item label="职位">
        <a-checkbox-group v-model:value="state.tableData[0].position_ids">
          <a-checkbox v-for="(item, index) in positionFor()" :key="index" :value="item.id" :disabled="item.name == '监事' ? dDisabled : item.name == '联络员' ? false : aeDisabled">{{
            item.name
          }}</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="是否占股" name="is_shareholder" v-if="props.isFiliale != 1">
        <a-form-item-rest>
          <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="state.tableData[0].is_shareholder"></a-switch>
        </a-form-item-rest>
        <a-form-item-rest>
          <a-input
            type="number"
            addon-after="万元"
            v-if="state.tableData[0].is_shareholder == 1"
            v-model:value="state.tableData[0].investment_amount"
            style="width: 200px; margin-left: 10px; display: inline-block"
            placeholder="出资金额"
          ></a-input>
        </a-form-item-rest>
        <span v-if="state.tableData[0].is_shareholder == 1"> 认缴截止日期: </span>
        <a-form-item-rest>
          <a-date-picker
            :disabledDate="disabledDate"
            v-model:value="state.tableData[0].subscribe_endtime"
            :format="format"
            v-if="state.tableData[0].is_shareholder == 1"
            style="width: 200px; margin-left: 10px; display: inline-block"
            placeholder="认缴期限"
          />
        </a-form-item-rest>
      </a-form-item>
      <a-form-item label="本人实名真实手机号码" name="phone_number">
        <a-input
          @change="
            (v) => {
              phoneChange(v, 'phone_number')
            }
          "
          style="width: 200px"
          placeholder="请输入手机号码"
          :value="state.tableData[0].phone_number"
        ></a-input>
        <a-button v-if="props.shareOwnershipTableData.length > 0 && props.shareOwnershipTableData[0].phone_number && !isEdit" class="ml-1x" type="primary" @click="onCopyPhoneClick"
          >复制上一个手机号码</a-button
        >
        <a-switch
          style="margin-left: 5px"
          v-model:checked="state.tableData[0].isuseteltogsj"
          :checkedValue="2"
          :unCheckedValue="1"
          checked-children="替换工商预留手机号码，填入【电子化职位】处"
          un-checked-children="不替换工商预留手机号码，不填入【电子化职位】处"
        />
      </a-form-item>
      <a-form-item name="area_ids" label="身份证住所省市区" v-if="props.isFiliale != 1"> <AddressCascader v-model:value="state.tableData[0].area_ids"> </AddressCascader> </a-form-item>
    </a-form>
    <ImageCropper
      @cancel="
        () => {
          state.showCropper = false
        }
      "
      :uploadPathObj="props.uploadPathObj"
      :imageUrl="state.imageUrl"
      :imageName="state.imageName"
      :customerName="props.customerName"
      @change="cropperChange"
      v-if="state.showCropper"
    >
    </ImageCropper>
  </a-modal>
</template>

<script lang="ts" setup>
import ImageCropper from '@/components/ImageCropper/index.vue'
import { Upload } from '@/components'
import AddressCascader from '@/components/base/AddressCascader.vue'
import dayjs from 'dayjs'
import { PlusOutlined } from '@ant-design/icons-vue'
import cloneDeep from 'lodash.clonedeep'
import { reactive, ref, computed, onMounted, PropType } from 'vue'
import { message } from 'ant-design-vue'
import { mobileRequiredValidator, mobileValidator } from '@/utils/util'
import { useStore } from 'vuex'
let dayTime = dayjs()
const $store = useStore()
const format = 'YYYY-MM-DD'
const props = defineProps({
  // 人员数据
  employmentData: {
    type: Object as PropType<tableData>
  },
  // 选中职位
  position: {
    type: Array
  },
  // 是否编辑
  isEdit: {
    type: Boolean
  },
  // 总人员信息
  shareOwnershipTableData: {
    type: Array as PropType<any>
  },
  company_area_name: {
    type: String,
    default: ''
  },
  business_type: {
    type: Number,
    default: 1
  },
  isFiliale: { type: Number },
  uploadPathObj: {
    type: Object
  },
  customerName: {
    type: String
  }
})

const positionData = {
  headOffice: [
    {
      id: 1,
      name: '法定代表人'
    },
    {
      id: 2,
      name: '执行董事'
    },
    {
      id: 3,
      name: '经理'
    },
    {
      id: 4,
      name: '监事'
    },
    {
      id: 5,
      name: '财务负责人'
    },
    {
      id: 6,
      name: '联络员'
    }
  ],
  filiale: [
    {
      id: 1,
      name: '负责人'
    },
    {
      id: 5,
      name: '财务负责人'
    },
    {
      id: 6,
      name: '联络员'
    }
  ]
}

var date = new Date()
date.setTime(date.getTime() - 24 * 60 * 60 * 1000)
let year = date.getFullYear()

let month: number | string = date.getMonth() + 1
if (month < 10) month = '0' + month
let day: number | string = date.getDate()
if (day < 10) day = '0' + day
let subscribe_endtime = `${year + 5}-${month}-${day}`

// if (props.company_area_name.indexOf('开福区') == -1 && props.company_area_name.indexOf('雨花区') == -1) {
//   subscribe_endtime = '2051-12-31'
// }

const positionFor = () => {
  if (props.isFiliale == 1) {
    return positionData.filiale
  } else {
    return positionData.headOffice
  }
}
const disabledDate = (current) => {
  if (props.company_area_name.indexOf('开福区') == -1 && props.company_area_name.indexOf('雨花区') == -1) {
    return false
  }

  let day = dayjs(subscribe_endtime, format)
  if (current < dayjs().startOf('day')) {
    return true
  } else if (current > day) {
    return true
  } else {
    return false
  }
}
interface tableData {
  // 省市区
  area_ids: string
  // 法人股东或者自然人股东
  stockholder: number | null
  // 法人名称
  name: string | null
  is_bensheng: number | null
  frdbname: string | null
  // 法人信用代码
  corporate_credit_code: string | null
  // 法人出资金额
  investment_money: number | null
  // 法人出资截止日期
  subscribe_endtime: any
  // 法人营业执照
  business_license_url: any
  // 职位
  position_ids: number[]
  // 名称
  fullname: string | null
  // 性别
  gender: string | null
  // 民族
  nationality: string | null
  // 身份证号码
  id_card_number: string | null
  // 地址
  address: string | null
  // 是否为长期
  identity_perpetual: number | null
  // 身份证日期开始时间
  id_card_validity_start: any
  // 身份证日期截止时间
  id_card_validity_end: any
  // 身份证正面照片
  id_card_front: string | null
  // 身份证反面照片
  id_card_back: string | null
  // 手机号
  phone_number: string | null
  // 编辑或者新增
  type: string | null
  // 出资金额
  investment_amount: number | null
  // 是否占股
  is_shareholder: number
  isuseteltogsj: number
  id?: number
  edit_code?: string
}
const emit = defineEmits(['change', 'cancel'])
const ruleForm = ref()
const personForm = ref()
let validateEnd = (rule, value) => {
  if (!value && state.tableData[0].identity_perpetual != 1) {
    return Promise.reject(new Error('请选择身份证结束日期'))
  } else if (value && state.tableData[0].identity_perpetual != 1) {
    const start = dayjs(value, 'YYYY-MM-DD')
    const diff = start.diff(dayTime, 'day')
    if (diff < 5) {
      return Promise.reject(new Error('身份证结束日期不能少于5天'))
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.resolve()
  }
}
const columns = [
  {
    // 默认货币
    title: '姓名',
    dataIndex: 'fullname',
    width: 80
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 50
  },

  {
    // 默认货币
    title: '身份证号码',
    dataIndex: 'id_card_number',
    width: 150
  },
  {
    // 默认货币
    title: '住所',
    dataIndex: 'address',
    width: 150
  },
  {
    title: '民族',
    dataIndex: 'nationality',
    width: 90
  },
  {
    title: '身份证有效期开始日期',
    dataIndex: 'id_card_validity_start',
    width: 130
  },
  {
    title: '身份证有效期结束日期',
    dataIndex: 'id_card_validity_end',
    width: 120
  }
]
const idNumberValidator = (rule, value) => {
  let reg_tel = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (value && value.length > 0) {
    if (!reg_tel.test(value)) {
      return Promise.reject('身份证号码不正确')
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.reject('请输入身份证号码')
  }
}
const investmentMoneyValidator = (rule, value) => {
  if (!value) {
    return Promise.reject('请输入出资金额')
  } else if (value <= 0) {
    return Promise.reject('出资金额必须大于0')
  } else if (!state.tableData[0].subscribe_endtime) {
    return Promise.reject('请选择出资日期')
  } else {
    return Promise.resolve()
  }
}
const nameValidator = (rule, value) => {
  if (!value) {
    return Promise.reject('请输入姓名')
  } else if (value.length < 7) {
    return Promise.reject('姓名必须大于6个字')
  } else {
    return Promise.resolve()
  }
}
const shareholderValidate = (rule, value) => {
  if (value == 1) {
    if (!state.tableData[0].investment_amount) {
      return Promise.reject('请输入出资金额')
    } else if (state.tableData[0].investment_amount <= 0) {
      return Promise.reject('出资金额必须大于0')
    } else if (!state.tableData[0].subscribe_endtime) {
      return Promise.reject('请选择出资日期')
    } else {
      return Promise.resolve()
    }
  } else {
    return Promise.resolve()
  }
}

let rules: any = {
  name: [{ required: true, validator: nameValidator, trigger: 'change' }],
  corporate_credit_code: [{ required: true, message: '请填写法人信用代码', trigger: 'change' }],
  frdbname: [{ required: true, message: '请填写法人代表姓名', trigger: 'change' }],
  investment_money: [{ required: true, validator: investmentMoneyValidator, trigger: 'change' }],
  business_license_url: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
  area_ids: [{ message: '请填写省市区', trigger: 'change' }],
  address: [{ required: true, message: '请填写地址', trigger: 'change' }],
  phone_number: [{ required: true, validator: mobileRequiredValidator, trigger: 'change' }]
}

let personRules: any = {
  fullname: [{ required: true, message: '请填写姓名', trigger: 'change' }],
  gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
  nationality: [{ required: true, message: '请填写名族', trigger: 'change' }],
  id_card_number: [{ validator: idNumberValidator, trigger: 'change' }],
  address: [{ required: true, message: '请填写地址', trigger: 'change' }],
  area_ids: [{ required: true, message: '请填写省市区', trigger: 'change' }],
  id_card_validity_start: [{ required: true, message: '请选择身份证开始日期', trigger: 'change', type: 'object' }],
  id_card_validity_end: [{ required: true, validator: validateEnd, trigger: 'change' }],
  is_shareholder: [{ validator: shareholderValidate, trigger: 'change' }],
  phone_number: [{ required: true, validator: mobileRequiredValidator, trigger: 'change' }]
}

interface state {
  modalTitle: string
  // 图片裁剪
  showCropper: boolean
  // 选中职位
  positionList: number[]
  // 当前上传身份证的字段
  currentUpload: string
  modalWidth: number

  // 表格数据
  tableData: tableData[]
  // 进行裁剪的图片地址
  imageUrl: string
  imageName: string

  nationalityList: string[]
}
const state = reactive<state>({
  modalTitle: '新增人员',
  // 图片裁剪
  showCropper: false,
  // 选中职位
  positionList: [],
  // 当前上传身份证的字段
  currentUpload: '',
  modalWidth: 1000,

  // 表格数据
  tableData: [
    {
      area_ids: '',
      // 法人股东或者自然人股东
      stockholder: null,
      // 法人名称
      name: null,
      is_bensheng: 1,
      frdbname: null,
      // 法人信用代码
      corporate_credit_code: null,
      // 法人出资金额
      investment_money: null,
      // 法人出资截止日期
      subscribe_endtime: dayjs(subscribe_endtime, format),
      // 法人营业执照
      business_license_url: [],
      // 职位
      position_ids: [],
      // 名称
      fullname: null,
      // 性别
      gender: null,
      // 民族
      nationality: null,
      // 身份证号码
      id_card_number: null,
      // 地址
      address: null,
      // 是否为长期
      identity_perpetual: 2,
      // 身份证日期开始时间
      id_card_validity_start: null,
      // 身份证日期截止时间
      id_card_validity_end: null,
      // 身份证正面照片
      id_card_front: null,
      // 身份证反面照片
      id_card_back: null,
      // 手机号
      phone_number: null,
      // 编辑或者新增
      type: 'add',
      // 出资金额
      investment_amount: null,
      // 是否占股
      is_shareholder: 1,
      isuseteltogsj: 1
    }
  ],
  // 进行裁剪的图片地址
  imageUrl: '',
  imageName: '',
  nationalityList: [
    '汉族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '朝鲜族',
    '满族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛南族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '德昂族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '鄂伦春族',
    '赫哲族',
    '门巴族',
    '珞巴族',
    '基诺族',
    '其他',
    '外国血统'
  ]
})

const aeDisabled = computed(() => {
  if (state.tableData[0].position_ids.indexOf(4) > -1) {
    return true
  } else {
    return false
  }
})
const dDisabled = computed(() => {
  let list = [1, 2, 3, 5]
  for (let index = 0; index < list.length; index++) {
    if (state.tableData[0].position_ids.indexOf(list[index]) > -1) {
      return true
    }
  }
  return false
})

onMounted(() => {
  if (props.business_type !== 1) {
    personRules = {
      phone_number: [{ validator: mobileValidator, trigger: 'change' }]
    }
    rules = {
      phone_number: [{ validator: mobileValidator, trigger: 'change' }]
    }
  }
  state.positionList = cloneDeep(props.position)
  // 编辑
  if (props.isFiliale == 1) {
    if (state.positionList.length == 0) {
      state.tableData[0].position_ids = [7, 5, 6]
    }
    state.modalWidth = 1400
    state.tableData[0].is_shareholder = 2
    state.tableData[0].stockholder = 2
  }
  if (props.employmentData?.type == 'edit') {
    // 编辑直接通过核验

    let employmentData = cloneDeep(props.employmentData)
    if (employmentData.subscribe_endtime) employmentData.subscribe_endtime = dayjs(employmentData.subscribe_endtime, format)
    if (employmentData.id_card_validity_end) employmentData.id_card_validity_end = dayjs(employmentData.id_card_validity_end, format)
    if (employmentData.id_card_validity_start) employmentData.id_card_validity_start = dayjs(employmentData.id_card_validity_start, format)

    state.modalTitle = '编辑人员'
    // 如果存在身份证则为 自然人 否则为法人
    if (employmentData.fullname || employmentData.stockholder == 2) {
      employmentData.stockholder = 2
      state.modalWidth = 1400
      state.tableData = [employmentData]
      if (employmentData.position_ids?.length > 0) {
        employmentData.position_ids.forEach((val) => {
          state.positionList = state.positionList.filter((item) => item !== val)
        })
      } else {
        employmentData.position_ids = []
      }
    } else if (employmentData.name || employmentData.stockholder == 1) {
      employmentData.stockholder = 1
      state.tableData = [employmentData]
    }
  }
})
const phoneChange = (v, type) => {
  let str = v.target.value.replaceAll(/\D/g, '')
  state.tableData[0][type] = str
}
const onCopyPhoneClick = () => {
  state.tableData[0].phone_number = props.shareOwnershipTableData[0].phone_number
}
const identityPerpetualChange = (val) => {
  state.tableData[0].identity_perpetual = val.target.checked ? 1 : 2
  personForm.value.validateFields('id_card_validity_end')
}
// 初始进入股东类型
const initialTypeChange = (val) => {
  if (val == 2) {
    if (props.isFiliale != 1) {
      if (state.positionList.length == 0) {
        state.tableData[0].position_ids = [1, 2, 3, 5, 6]
      } else {
        state.tableData[0].position_ids = [4]
      }
    }
    state.modalWidth = 1400
  } else {
    state.tableData[0].is_shareholder = 1
  }
  state.tableData[0].stockholder = val
}
// 提交
const onFormSubmit = () => {
  // 自然人股东
  if (state.tableData[0].stockholder == 2) {
    if (props.business_type == 1) {
      // 股东或者职位 必须选择一个
      //   if (state.tableData[0].is_shareholder == 2 && state.tableData[0].position_ids.length == 0) {
      //     message.warning('请选择人员职位或者填写股份信息')
      //     return
      //   }
      // 判断人员是否重复
      if (state.positionList && state.positionList.length > 0) {
        let isRepeat = false
        for (let i = 0; i < state.positionList.length; i++) {
          if (state.tableData[0].position_ids.indexOf(state.positionList[i]) > -1) {
            isRepeat = true
          }
        }
        if (isRepeat) {
          message.warning('有人员重复职位，请检查!')
          return
        }
      }
      // 身份证信息验证
      if (!state.tableData[0].id_card_front || !state.tableData[0].id_card_back) {
        message.warning('请上传身份证正反面照片')
        return
      }
    }
    personForm.value
      .validate()
      .then((valid) => {
        if (valid) {
          if (props.isEdit) {
            if (state.tableData[0].id) {
              state.tableData[0].edit_code = 'edit'
            } else {
              state.tableData[0].edit_code = 'add'
            }
          }
          // 日期转换
          let data = cloneDeep(state.tableData[0])
          data.subscribe_endtime = dayjs(data.subscribe_endtime).format(format)
          data.id_card_validity_end = dayjs(data.id_card_validity_end).format(format)
          data.id_card_validity_start = dayjs(data.id_card_validity_start).format(format)
          // positionList 当使用编辑是 选中的职位表在进入时清空了
          emit('change', data, state.positionList)
        }
      })
      .catch(() => {
        message.warning('请检查必填项')
      })
  } else if (state.tableData[0].stockholder == 1) {
    ruleForm.value
      .validate()
      .then((valid) => {
        if (valid) {
          if (props.isEdit) {
            if (state.tableData[0].id) {
              state.tableData[0].edit_code = 'edit'
            } else {
              state.tableData[0].edit_code = 'add'
            }
          }
          let data = cloneDeep(state.tableData[0])

          if (data.subscribe_endtime) data.subscribe_endtime = dayjs(data.subscribe_endtime).format(format)
          if (data.id_card_validity_end) data.id_card_validity_end = dayjs(data.id_card_validity_end).format(format)
          if (data.id_card_validity_start) data.id_card_validity_start = dayjs(data.id_card_validity_start).format(format)
          // positionList 当使用编辑是 选中的职位表在进入时清空了
          emit('change', data)
        }
      })
      .catch(() => {
        message.warning('请检查必填项')
      })
  } else {
    message.warning('请选择股东类型')
  }
}
// 图片上传
const handleChange = (info, type) => {
  state.currentUpload = type
  const reader: any = new FileReader()
  state.imageName = info.file.name
  reader.readAsDataURL(info.file)
  reader.onload = () => {
    state.imageUrl = reader.result
    state.showCropper = true
  }
}

// 裁剪成功
const cropperChange = (res, isRecognize) => {
  if (isRecognize) {
    if (!res.data.身份证识别实体信息) {
      message.error('识别失败，请重新上传')
      return
    }
    let data = res.data.身份证识别实体信息
    if (state.currentUpload == 'id_card_back') {
      if (data.身份证国徽面实体信息.有效期限.length < 1) {
        message.error('请上传身份证国徽面照片')
        state.tableData[0].id_card_back = ''
        return
      }
      let list = data.身份证国徽面实体信息.有效期限.split('-').map((item, index) => {
        return item.replaceAll('.', '-')
      })
      let obj = {
        id_card_validity_start: dayjs(list[0], format),
        id_card_validity_end: dayjs(list[1], format),
        identity_perpetual: list[1] == '长期' ? 1 : 2
      }
      Object.assign(state.tableData[0], obj)
      personForm.value.validateFields('id_card_validity_end')
    } else if (state.currentUpload == 'id_card_front') {
      if (data.身份证人像面实体信息.姓名.length < 1) {
        message.error('请上传身份证人像面照片')
        state.tableData[0].id_card_front = ''
        return
      }
      if (res.data.province_id && res.data.city_id && res.data.area_id) {
        state.tableData[0].area_ids = `${res.data.province_id},${res.data.city_id},${res.data.area_id}`
      }
      let nationality: any = null
      state.nationalityList.forEach((item) => {
        if (item.indexOf(data.身份证人像面实体信息.民族) > -1) {
          nationality = item
        }
      })
      let obj = {
        fullname: data.身份证人像面实体信息.姓名,
        address: data.身份证人像面实体信息.住址,
        id_card_number: data.身份证人像面实体信息.身份证号,
        gender: data.身份证人像面实体信息.性别 == '男' ? 1 : 2,
        nationality: nationality
      }
      Object.assign(state.tableData[0], obj)
    }
    state.tableData[0][state.currentUpload] = res.data.url
  } else {
    state.tableData[0][state.currentUpload] = res.url
  }
  state.showCropper = false
}
// 手动上传验证
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请上传正确图片！')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('图片必须小于2MB!')
  }
  return false
}
</script>

<style lang="less" scoped>
.information {
  display: flex;
  justify-content: center;
}

.information-right {
  display: flex;
  justify-content: center;
}

.information-card {
  margin-bottom: 20px;
  border-radius: 10px;

  :deep(.ant-card-head) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #40a9ff;
    color: #fff;
  }
}

.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}

.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td) {
  padding-left: 28px;
}

.category {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;

  .category-item {
    font-size: 24px;
    cursor: pointer;
    padding: 50px;
    border-radius: 15px;
    width: 100%;
    margin-right: 20px;
    text-align: center;
  }
}

.m-0 {
  :deep(.ant-form-explain) {
    position: absolute;
  }
}
</style>
