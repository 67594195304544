<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="购买人账号" name="name">
          <div class="d-flex">
            <a-input placeholder="请输入购买人账号" v-model:value="formState.name" @keyup.enter="queryAccountClick" @blur="queryAccountClick"></a-input>
            <a-button class="ml-1x" type="primary" :loading="state.queryAccountLoading" @click="queryAccountClick">查询账号</a-button>
          </div>
          <a-tag v-if="formState.users_id" color="blue" class="mt-2x">已匹配到：账号姓名:{{ state.accountInfo.nickname }}√</a-tag>
        </a-form-item>
        <a-form-item label="选择购买平台" name="platform_id" required>
          <a-select placeholder="请选择购买平台" v-model:value="formState.platform_id">
            <a-select-option v-for="item in state.platformList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="库存使用范围" name="main_types" required>
          <a-checkbox-group v-model:value="formState.main_types">
            <a-checkbox :value="1">公司类注册</a-checkbox>
            <a-checkbox :value="2">个体类注册</a-checkbox>
            <a-checkbox :value="3">个独注册</a-checkbox>
            <a-checkbox :value="4">分公司注册</a-checkbox>
            <a-checkbox :value="5">合伙企业注册</a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="库存生效开始时间" name="start_time" required>
          <a-date-picker v-model:value="formState.start_time" show-time disabled />
        </a-form-item>
        <a-form-item label="库存生效结束时间" name="end_time" required>
          <a-date-picker v-model:value="formState.end_time" show-time />
        </a-form-item>
        <a-form-item label="优惠活动" name="activity_center_class_id">
          <a-select placeholder="请选择优惠活动" @change="selectClassChange" v-model:value="formState.activity_center_class_id">
            <a-select-option v-for="item in state.activityClassList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-table
          :loading="state.activityLoading"
          style="margin: 0 5vw 20px"
          :pagination="false"
          :customRow="
            (record, index) => {
              return {
                onClick: (val) => {
                  onTableClick(index)
                }
              }
            }
          "
          :columns="columns"
          :data-source="dataSource"
        >
          <template #bodyCell="{ column, record, index, text }">
            <template v-if="column.dataIndex === 'select'">
              <a-radio :checked="record.isSelect" @click="onTableClick(index)"></a-radio>
            </template>
            <template v-else-if="column.dataIndex === 'name'">
              <div>
                {{ text }}
              </div>
              <div style="color: #999">说明：{{ record.describe }}</div>
            </template>
            <template v-else-if="['total_price', 'price', 'renewal_price'].includes(column.dataIndex)">
              <span>￥{{ text }}</span>
              <span v-if="column.dataIndex == 'renewal_price'">/年</span>
            </template>
          </template>
        </a-table>
        <a-form-item label="收款方式" name="payment_method" required>
          <a-select placeholder="请选择收款方式" v-model:value="formState.payment_method">
            <a-select-option :value="1">对公基本户</a-select-option>
            <a-select-option :value="2">微信</a-select-option>
            <a-select-option :value="3">支付宝</a-select-option>
            <a-select-option :value="4">银行卡</a-select-option>
            <a-select-option :value="5">支付现金</a-select-option>
            <a-select-option :value="6">其他</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="收款人" name="payee" required>
          <a-input placeholder="请输入收款人" v-model:value="formState.payee"></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea placeholder="请输入备注" v-model:value="formState.order_remark"></a-textarea>
        </a-form-item>
        <a-form-item
          :wrapperCol="{
            xs: { span: 8, offset: 7 },
            sm: { span: 8, offset: 7 }
          }"
        >
          <a-button type="primary" @click="handleSubmit" :loading="state.login" :disabled="state.login">购买</a-button>
        </a-form-item>
      </a-form>
      <a-modal
        title="请输入密码"
        :width="600"
        :open="state.openPassword"
        :confirmLoading="state.loading"
        @ok="onPasswordSubmit"
        @cancel="
          () => {
            state.openPassword = false
          }
        "
      >
        <a-form ref="passwordForm" :model="formState" v-bind="formLayout">
          <a-form-item label="充值方案">
            {{ state.selectTable.name }}
          </a-form-item>
          <a-form-item label="总价">
            <span style="color: red">￥ {{ state.selectTable.total_price }}</span>
          </a-form-item>
          <a-form-item label="数量">
            <span style="color: red"> {{ state.selectTable.amount }}个</span>
          </a-form-item>
          <a-form-item label="单价">
            <span style="color: red">￥ {{ state.selectTable.price }}</span>
          </a-form-item>
          <a-form-item label="续费">
            <span style="color: red">￥ {{ state.selectTable.renewal_price }}/年</span>
          </a-form-item>

          <a-form-item label="密码" name="password" required>
            <a-input-password placeholder="请输入密码" v-model:value="formState.password"></a-input-password>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-spin>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, computed } from 'vue'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD HH:mm:ss'
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  }
}
const dataSource = ref()
const ruleForm = ref()
const passwordForm = ref()
const columns = [
  {
    title: '选择',
    dataIndex: 'select'
  },
  {
    title: '充值方案',
    dataIndex: 'name'
  },
  {
    title: '单价',
    dataIndex: 'price',
    width: 150
  },
  {
    title: '数量',
    dataIndex: 'amount',
    width: 100
  },
  {
    title: '总价',
    dataIndex: 'total_price',
    width: 150
  },
  {
    title: '续费',
    dataIndex: 'renewal_price',
    width: 150
  }
]
const nameValidator = (rule, value) => {
  if (value && value.length > 0) {
    if (formState.users_id) {
      return Promise.resolve()
    } else {
      return Promise.reject('请输入正确的购买人账号')
    }
  } else {
    return Promise.reject('请输入购买人账号')
  }
}
const rules = {
  name: [{ required: true, validator: nameValidator, trigger: 'click' }]
}
const state = reactive<any>({
  loading: false,
  queryAccountLoading: false,
  activityLoading: false,
  openPassword: false,
  accountInfo: {},
  platformList: [],
  activityClassList: [],
  selectTable: {}
})
onMounted(() => {
  queryCreate()
})
const formState = reactive<any>({
  main_types: [1, 2, 3, 4, 5],
  start_time: dayjs(dayjs().format(format), format),
  end_time: dayjs(dayjs().add(1, 'years').format(format), format)
})

const onTableClick = (index) => {
  dataSource.value = dataSource.value.map((item, mapIndex) => {
    if (index == mapIndex) {
      state.selectTable = item
    }
    return { ...item, isSelect: index == mapIndex ? true : false }
  })
}
const selectClassChange = () => {
  dataSource.value = []
  state.activityLoading = true
  let requestParam = {
    url: '/admin/v1/activityCenterList',
    method: 'post',
    data: { page: 1, limit: 'all', class_id: formState.activity_center_class_id, status: 1, time: dayjs().format(format) }
  }
  baseService(requestParam)
    .then((res) => {
      state.activityLoading = false
      if (res.data.list?.length > 0) {
        dataSource.value = res.data.list.map((item, index) => {
          return { ...item, isSelect: index === 0 ? true : false }
        })
        state.selectTable = res.data.list[0]
      } else {
        state.selectTable = {}
      }
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const queryCreate = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/buyAddress/create',
    method: 'get'
  }
  baseService(requestParam).then((res) => {
    state.platformList = res.data.platform
    formState.platform_id = res.data.platform[res.data.platform.length - 1].id
    state.activityClassList = res.data.activity_center_class
    if (res.data.activity_center_class?.length > 0) {
      formState.activity_center_class_id = res.data.activity_center_class[0].id
      selectClassChange()
    }
    state.loading = false
  })
}
const queryAccountClick = () => {
  formState.users_id = undefined
  if (!formState.name || formState.name.length === 0) {
    message.warn('请输入账号查询')
    return
  }
  state.queryAccountLoading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/user/list',
    method: 'post',
    data: { page: 1, limit: 10, name: formState.name }
  }
  baseService(requestParam).then((res) => {
    state.queryAccountLoading = false
    if (res.data?.list?.length > 0) {
      state.accountInfo = res.data.list[0]
      formState.users_id = state.accountInfo.id
      ruleForm.value.validate('name')
    }
  })
}
const handleSubmit = () => {
  if (Object.keys(state.selectTable).length < 1) {
    message.warn('请选择优惠活动')
    return
  }
  ruleForm.value
    .validate()
    .then((res) => {
      state.openPassword = true
    })
    .catch((res) => {
      console.log(res)
      message.warn('请检查必填项')
    })
}
const onPasswordSubmit = () => {
  passwordForm.value
    .validate()
    .then((res) => {
      let requestParam = {
        url: '/admin/userCheckPassword',
        method: 'post',
        data: {
          password: formState.password
        }
      }
      baseService(requestParam)
        .then((res) => {
          let obj = { ...formState }
          obj.password = undefined
          obj.name = undefined
          obj.start_time = dayjs(obj.start_time).format(format)
          obj.end_time = dayjs(obj.end_time).format(format)
          obj.activity_center_id = state.selectTable.id
          let requestParam = {
            url: '/admin/v1/buyAddress',
            method: 'post',
            data: obj
          }
          baseService(requestParam)
            .then((res: any) => {
              formState.name = ''
              formState.users_id = undefined
              message.success(res.msg)
              state.openPassword = false
            })
            .catch((res) => {
              if (res.msg) {
                message.error(res.msg)
              } else {
                message.error('失败')
              }
            })
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('失败')
          }
        })
    })
    .catch((res) => {
      console.log(res)
    })
}
</script>

<style lang="less" scoped>
.class-list {
  display: flex;
  flex-wrap: wrap;
  .class-item {
    border: 1px solid #d9d9d9;
    padding: 8px 10px;
    margin-right: 2px;
    font-size: 12px;
    display: flex;
  }
}
</style>
