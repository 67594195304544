<template>
  <a-modal title="选择优惠券" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <!-- <a-popconfirm title="是否确定？" @confirm="onFormSubmit"> -->
      <a-button type="primary" @click="onFormSubmit">确定</a-button>
      <!-- </a-popconfirm> -->
    </template>
    <a-table
      :row-selection="{ selectedRowKeys: state.selectedRowKeys, type: 'radio', onChange: onSelectChange }"
      :pagination="pagination"
      @change="onChange"
      :columns="couponListColumns"
      :loading="state.loading"
      rowKey="id"
      :data-source="state.couponList"
    >
      <template #bodyCell="{ column, record, index, text }">
        <div>
          <template v-if="column.dataIndex === 'name'">
            <div class="item" :style="record.is_usable ? '' : 'filter: grayscale(100%);'">
              <div class="box">
                <div class="content">
                  <div class="page-group">
                    <i class="fold-page"></i>
                    <span class="page">{{ record.coupon_type == 1 ? '直减现金券' : record.coupon_type == 2 ? '满减现金券' : '折扣券' }}</span>
                  </div>
                  <i class="dot-left"></i>
                  <i class="dot-right"></i>
                </div>
                <div class="footer">
                  <div style="font-size: 16px">{{ record.name }}</div>
                  <div v-if="record.efficacious_type == 1">
                    <div>使用开始日期：{{ record.start_time }}</div>
                    <div>使用截至日期：{{ record.end_time }}</div>
                  </div>
                  <div v-else-if="record.efficacious_type == 2">使用期限：领取之后{{ record.number_days }}日到期</div>
                  <div v-else-if="record.efficacious_type == 3">使用期限：长期</div>
                  <div v-if="!record.is_usable">不可用原因：{{ record.unusable_cause }}</div>
                </div>
                <div class="ribbon" v-if="record.is_usable">生效中</div>
                <div class="ribbon" v-else>不可用</div>
              </div>
            </div>
          </template>

          <template v-if="column.dataIndex === '优惠方式'">
            <div v-if="record.coupon_type == 1">
              <a-tag class="ant-tag-green"
                >直减现金券：<span style="color: red">{{ record.coupon_amout }}元 </span></a-tag
              >
            </div>
            <div v-else-if="record.coupon_type == 2">
              <a-tag class="ant-tag-green"
                >满减现金券：<span style="color: red">满{{ record.full_amount }}元 减{{ record.coupon_amout }}</span></a-tag
              >
            </div>
            <div v-else-if="record.coupon_type == 3">
              <a-tag class="ant-tag-green"
                >折扣券：<span style="color: red">{{ record.coupon_amout }}折 最高优惠{{ record.discount_sum }}元</span></a-tag
              >
            </div>
          </template>
          <template v-if="column.dataIndex === 'operator_admin_name'">
            <div v-if="record.operator_admin_name">
              {{ record.operator_admin_name }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'created_at'">
            <div v-if="record.created_at">
              {{ record.created_at }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'publish_reason'">
            <div v-if="record.publish_reason">
              {{ record.publish_reason }}
            </div>
            <div v-else>-</div>
          </template>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { reactive, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
const $store = useStore()
const ruleForm = ref()
const emit = defineEmits(['cancel', 'ok'])
const props = defineProps({
  //{public_payment_type,customer_full_names,id}
  tableColumnsData: {
    type: Object,
    default: () => {}
  },
  couponId: {
    type: String,
    default: ''
  }
})
const couponListColumns: any = [
  {
    title: '优惠券名字',
    dataIndex: 'name'
  },

  {
    title: '优惠方式',
    dataIndex: '优惠方式'
  },
  {
    title: '添加人',
    dataIndex: 'operator_admin_name'
  },
  {
    title: '添加时间',
    dataIndex: 'created_at'
  },
  {
    title: '添加原因',
    dataIndex: 'publish_reason'
  }
]
const pagination = reactive<any>({
  pageSize: 10,
  current: 1,
  total: undefined,
  showTotal: (total) => `总数：${total}`
})

const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  getCouponList()
}
const state = reactive<any>({
  selectedRowKeys: [props.couponId],
  couponList: [],
  selectedRecord: {},
  queryKey: '',
  loading: false
})
const formData = reactive<any>({
  refund_remark: null,
  refund_money: null,
  refund_type: 1,
  renew_pact: 1,
  refund_attachment: null,
  type: null
})
onMounted(() => {
  getCouponList()
  getComputeOreder()
})

const getComputeOreder = () => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/getComputeOreder`,
    method: 'post',
    data: {
      public_payment_type: props.tableColumnsData?.public_payment_type,
      customer_full_names: props.tableColumnsData?.customer_full_names,
      customer_id: props.tableColumnsData?.id,
      fee_standard: null,
      coupon_use_id: null
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.loading = false
      state.couponList = res.data.coupon_use_list
      pagination.total = res.data.coupon_use_list.length
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询订单失败')
      state.loading = false
    })
}

const getCouponList = () => {
  //   state.loading = true
  //   let requestParam = {
  //     url: '/admin/v1/getMyCoupon',
  //     method: 'post',
  //     data: { page: pagination.current, limit: pagination.pageSize }
  //   }
  //   baseService(requestParam)
  //     .then((res: any) => {
  //       state.loading = false
  //       pagination.total = res.data.total_records
  //       state.couponList = res.data.list
  //     })
  //     .catch((res) => {
  //       state.loading = false
  //       if (res.msg) {
  //         message.error(res.msg)
  //       } else {
  //         message.error('操作失败')
  //       }
  //     })
}

const onSelectChange = (selectedRowKeys: any[], val) => {
  //   console.log('selectedRowKeys changed: ', selectedRowKeys)
  //   console.log('selectedRecord: ', val[0])
  state.selectedRowKeys = selectedRowKeys
  state.selectedRecord = val[0]
}

// 提交
const onFormSubmit = () => {
  emit('ok', state.selectedRecord)
}

const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-modal-title) {
  margin-bottom: 25px !important;
}
.label-form-item {
  display: flex;
  align-items: center;

  .delete-icon {
    color: red;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
  }
}

.select-color {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .label-color-picker {
    border: 1px solid #dcdfe6;
    padding: 4px;
    border-radius: 4px;
  }
}
.item {
  width: 235px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-left: 2px;
  position: relative;
}
.box {
  background: #fff;
  border-radius: 5px;
  background: linear-gradient(45deg, orange, red);
}
.item .dot-left,
.item .dot-right {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f5f5f5;
  position: absolute;
  z-index: 999;
}
.item .dot-left {
  bottom: -6px;
  left: -6px;
}
.item .dot-right {
  bottom: -6px;
  right: -6px;
}
.item .page-group {
  position: absolute;
  top: 10px;
  left: -2px;
  width: 100%;
  max-width: 120px;
}
.item .page-group .fold-page {
  display: block;
  width: 2px;
  height: 5px;
  background: #db0113;
  transform: skewY(-40deg);
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 0;
}
.item .page-group .page {
  position: absolute;
  z-index: 1;
  display: block;
  padding: 0 10px;
  height: 20px;
  background: linear-gradient(137deg, #ff5b5b 0%, #db0113 100%);
  border-radius: 0 20px 20px 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
}
.item .content {
  width: 100%;
  height: 40px;
  border-bottom: 1px dotted #f5f5f5;
  position: relative;
  z-index: 2;
}
.item .footer {
  color: #fff;
  font-size: 12px;
  padding: 5px 15px 10px 15px;
}
.footer-label {
  line-height: 24px;
}
.item .ribbon {
  width: 80px;
  height: 20px;
  background: #dd0d1f;
  position: absolute;
  right: -20px;
  top: 10px;
  transform: rotateZ(45deg);
  text-align: center;
  color: #fff;
  font-size: 12px;
}
</style>
