<template>
  <a-modal
    title="顺丰邮寄"
    :width="1300"
    :open="true"
    :confirmLoading="state.confirmLoading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="formLayout">
        <a-form-item label="邮寄公司名称"> {{ props.tableColumnsData.entityNameToRegister?.full_name }}</a-form-item>

        <a-form-item label="有多家公司一起邮寄" v-if="state.customer_list?.length > 0">
          <a-checkbox-group v-model:value="formState.customer_ids">
            <a-checkbox v-for="(item, index) in state.customer_list" :key="index" :value="item.id">
              {{ item.full_name }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-item>

        <a-form-item label="付款方式">
          <a-radio-group v-model:value="formState.pay_type" :options="plainOptions" />
        </a-form-item>
        <a-form-item label="特别备注"> <a-input style="width: 400px" v-model:value="formState.remark" type="textarea"></a-input> </a-form-item>
        <a-form-item label="付款方式">
          <a-radio-group v-model:value="formState.is_timing">
            <a-radio :value="1"> 立马邮寄 </a-radio>
            <a-radio :value="2"> 定时邮寄 </a-radio> </a-radio-group
          ><a-button :loading="formState.emailLoading" type="primary" @click="emailChange" style="float: right">自动获取邮寄信息</a-button>
        </a-form-item>
        <a-form-item label="上门取货时间" v-if="formState.is_timing == 2" required>
          <a-date-picker v-model:value="formState.timing_time" :format="format" show-time />
        </a-form-item>
        <div class="d-flex">
          <div class="flex-1">
            <a-form-item label="寄件人姓名" name="send_name" required>
              <a-input placeholder="请输入寄件人姓名" v-model:value="formState.send_name"></a-input>
            </a-form-item>
            <a-form-item label="寄件人电话" name="send_tel" required>
              <a-input placeholder="请输入寄件人姓名" v-model:value="formState.send_tel"></a-input>
            </a-form-item>
            <a-form-item label="寄件人省市区" name="send_paua">
              <AddressCascader width="100%" v-model:value="formState.send_paua" @change="senderPauaChange"> </AddressCascader>
            </a-form-item>
            <a-form-item label="寄件人详细地址" name="send_address" required>
              <a-input placeholder="请选择寄件人详细地址" v-model:value="formState.send_address"></a-input>
            </a-form-item>
          </div>
          <div class="flex-1">
            <a-form-item label="收件人姓名" name="receive_name" required>
              <a-input v-model:value="formState.receive_name" placeholder="请输入收件人姓名"></a-input>
            </a-form-item>
            <a-form-item label="收件人电话" name="receive_tel" required>
              <a-input v-model:value="formState.receive_tel" placeholder="请输入收件人电话"></a-input>
            </a-form-item>
            <a-form-item label="收件人省市区" name="receive_paua">
              <AddressCascader width="100%" v-model:value="formState.receive_paua" @change="recipientChange"> </AddressCascader>
            </a-form-item>
            <a-form-item label="收件人详细地址" name="receive_address" required>
              <a-input placeholder="请选择收件人详细地址" v-model:value="formState.receive_address"></a-input>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup>
import { AddressCascader } from '@/components'
import cloneDeep from 'lodash.clonedeep'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
import { ref, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel', 'ok'])
const format = 'YYYY-MM-DD HH:mm:ss'
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
}
onMounted(() => {
  queryCustomerSf()
})
const plainOptions = [
  { label: '寄付(顺丰速运小程序->查快递->待支付付款)', value: 1 },
  { label: '到付', value: 2 }
]
interface state {
  customer_list: any[]
  loading: boolean
  confirmLoading: boolean
}
const state = reactive<state>({
  customer_list: [],
  loading: false,
  confirmLoading: false
})
const formRef = ref()
interface from {
  pay_type: number
  is_timing: number
  remark: string
  timing_time: any
  customer_ids: any[]
  receive_address: string
  receive_paua: string
  receive_tel: string
  receive_name: string
  send_address: string
  send_paua: string
  send_tel: string
  send_name: string
  emailLoading: boolean
  renew_first_contact_mobile: string
}
const formState = reactive<from>({
  pay_type: 1,
  is_timing: 1,
  remark: '',
  customer_ids: [],
  timing_time: null,
  receive_address: '',
  receive_paua: '',
  receive_tel: '',
  receive_name: '',
  send_address: '',
  send_paua: '',
  send_tel: '',
  send_name: '',
  emailLoading: false,
  renew_first_contact_mobile: ''
})
const rules = reactive({
  send_paua: [{ required: true, message: '请选择寄件人地址' }],
  receive_paua: [{ required: true, message: '请选择收件人地址' }]
})
// 获取邮寄信息
const emailChange = () => {
  if (!formState.renew_first_contact_mobile) {
    message.warn('请填写续费联系人手机号码再获取邮寄信息')
    return
  }
  formState.emailLoading = true

  let requeryParam = {
    url: '/admin/v1/mailingAddressList',
    method: 'post',
    data: {
      page: 1,
      limit: 'all',
      customer_tel: formState.renew_first_contact_mobile
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      if (res.data.list?.length > 0) {
        formState.receive_name = res.data.list[0].name
        formState.receive_tel = res.data.list[0].tel
        formState.receive_address = res.data.list[0].address
        formState.receive_paua = `${res.data.list[0].recipient_province_id},${res.data.list[0].recipient_city_id},${res.data.list[0].recipient_area_id}`
      }
      message.success(res.msg)
      formState.emailLoading = false
    })
    .catch((res) => {
      if (res) {
        formState.emailLoading = false
        if (res.msg) {
          message.warning(res.msg)
          return
        }
      }
    })
  //   }
}
const queryCustomerSf = () => {
  state.loading = true
  let requestParam = {
    url: `/admin/v1/customerSf?customer_id=${props.tableColumnsData.id}`,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      formState.pay_type = res.data.payment_type
      state.customer_list = res.data.customer_list
      formState.remark = res.data.remark
      let send: any = res.data.send
      formState.send_name = send.name
      formState.send_tel = send.tel
      formState.send_paua = `${send.provinceid},${send.cityid},${send.areaid}`
      formState.send_address = send.address
      let receive: any = res.data.receive
      formState.receive_name = receive.name
      formState.receive_tel = receive.tel
      if (receive?.provinceid && receive.cityid && receive.areaid) {
        formState.receive_paua = `${receive.provinceid},${receive.cityid},${receive.areaid}`
      }
      formState.receive_address = receive.address
      formState.renew_first_contact_mobile = res.data.renew_first_contact_mobile

      state.loading = false
    })
    .catch((res) => {
      message.error(res?.mas ? res.msg : '自动获取失败请手动输出信息')
      state.loading = false
    })
}
const senderPauaChange = (e, val) => {
  let address = `${val[0].name}${val[1].name}${val[2].name}`
  formState.send_address = address
}
const recipientChange = (e, val) => {
  let address = `${val[0].name}${val[1].name}${val[2].name}`
  formState.receive_address = address
}
const onFormSubmit = () => {
  formRef.value.validate().then(() => {
    let data: any = cloneDeep(formState)
    let param: any = {}
    data.customer_ids.push(props.tableColumnsData.id)
    param.customer_ids = data.customer_ids
    param.send = {
      provinceid: parseInt(data.send_paua.split(',')[0]),
      cityid: parseInt(data.send_paua.split(',')[1]),
      areaid: parseInt(data.send_paua.split(',')[2]),
      address: data.send_address,
      tel: data.send_tel,
      name: data.send_name
    }
    param.receive = {
      provinceid: parseInt(data.receive_paua.split(',')[0]),
      cityid: parseInt(data.receive_paua.split(',')[1]),
      areaid: parseInt(data.receive_paua.split(',')[2]),
      address: data.receive_address,
      tel: data.receive_tel,
      name: data.receive_name
    }
    param.timing_time = dayjs(data.timing_time, format)
    param.remark = data.remark
    param.pay_type = data.pay_type
    param.is_timing = data.is_timing
    param.full_name = props.tableColumnsData.entityNameToRegister?.full_name
    state.confirmLoading = true
    let requestParam = {
      url: `/admin/v1/customerSfPush`,
      method: 'post',
      data: param
    }
    baseService(requestParam)
      .then((res: any) => {
        state.confirmLoading = false
        message.success(res?.msg ? res.msg : '发送成功', 8)
        emit('cancel')
      })
      .catch((res) => {
        state.confirmLoading = false
        message.error(res?.msg ? res.msg : '操作失败请联系管理员')
      })
  })
}
</script>

<style lang="less" scoped></style>
