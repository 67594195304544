<template>
    <a-modal title="转单" :width="600" :visible="true" :confirmLoading="loading" @ok="onFormSubmit" @cancel="() => {
        $emit('cancel')
    }
        ">
        <a-form class="flex-1" :form="form" v-bind="formLayout">
            <a-form-item label="选择转单对象">
                <a-select @change="selectChange">
                    <a-select-option value="jack"> 续费联系人 </a-select-option>
                    <a-select-option value="lucy"> 法人代表 </a-select-option>
                    <a-select-option value="Yiminghe"> 代理人 </a-select-option>
                    <a-select-option value="yuangong"> 本公司员工 </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="选择员工" v-if="selectType == 'yuangong'">
                <a-select>
                    <a-select-option value="jack"> 张三 </a-select-option>
                    <a-select-option value="lucy"> 李四 </a-select-option>
                    <a-select-option value="Yiminghe"> 王五 </a-select-option>
                    <a-select-option value="yuangong"> 赵六 </a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
export default {
    components: {},
    props: {
        loading: {
            type: Boolean,
            default: () => false,
        },
        model: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            formLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 7 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 13 },
                },
            },
            // form
            form: this.$form.createForm(this),
            selectType: '',
        }
    },
    methods: {
        selectChange(val) {
            this.selectType = val
        },
        // 提交
        onFormSubmit() {
            this.form.validateFields((errors, values) => {
                if (!errors) {
                    let requeryParam = {
                        url: 'http://26.232.144.88:10393/mock/b5008f27-4b72-4e6f-a8b5-54af32c5d9b6/customAdd',
                        method: 'post',
                        data: values,
                    }
                    request(requeryParam).then((res) => {
                        this.$message.success('保存成功')
                        this.$emit('cancel')
                    })
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.add-button {
    position: absolute;
    right: 65px;
    top: 10px;
}

.positionItem {
    display: inline-block;
    margin-bottom: 10px;
}
</style>
