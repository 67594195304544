import ls from './Storage';
import { systemConfig } from '@/store/reactiveState'
import { updateTheme } from '@/components/SettingDrawer/updateTheme'
import { updateColorWeak, updateGrayMode } from '@/components/SettingDrawer/settingConfig'
export default () => {
    const siteSettings = ls.get('SITE_SETTINGS')
    if (siteSettings) {
        if (!siteSettings['THEME_COLOR']) {
            ls.setObj('SITE_SETTINGS', { THEME_COLOR: systemConfig.state.color })
        }
        for (let s in siteSettings) {
            // 只要设置了黑暗主题,就不设置亮暗颜色了,统一默认暗色
            if (s === 'TOGGLE_THEME' && siteSettings['SET_DARK_MODE']) {
                continue;
            }
            // 有主题色就设置主题色
            if (s === 'THEME_COLOR' && siteSettings['THEME_COLOR']) {
                updateTheme(siteSettings['THEME_COLOR'])
            }
            // 黑白模式
            if (s === 'TOGGLE_GRAY' && siteSettings['TOGGLE_GRAY']) {
                updateGrayMode(true)
            }
            // 色弱模式
            if (s === 'TOGGLE_WEAK' && siteSettings['TOGGLE_WEAK']) {
                updateColorWeak(true)
            }
            systemConfig.commit(s, siteSettings[s])
        }
    } else {
        ls.setObj('SITE_SETTINGS', { THEME_COLOR: systemConfig.state.color })
        // 设置主题色
        updateTheme(systemConfig.state.color)
    }
}
