<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SettingDrawer',
})
</script>
<template>
  <div>
    <a-drawer :bodyStyle="{ padding: 0 }" width="1200" placement="right" :maskStyle="{ background: '#0000001a' }" @close="onClose" :closable="false" :open="$store.state.showInform">
      <div class="inform-drawer">
        <div class="drawer-left">
          <div class="title">通知/公告</div>
          <div class="menus">
            <div class="menus-item" :style="selectedKeys.key == item.key ? { background: '#ebecf0' } : {}" @click="selectMenus(item)" v-for="(item, index) in menusList" :key="index">
              <div>
                <BellOutlined style="font-size: 16px; margin-right: 5px" />
                {{ item.title }}
              </div>
              <a-badge color="#1677ff" :count="item.inform"> </a-badge>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <div class="title">
            {{ selectedKeys.title }}
          </div>
          <Inform></Inform>
        </div>
      </div>
      <template #handle>
        <div class="inform-drawer-index-handle" v-if="$store.state.showInform" @click="onClose">
          <CloseOutlined style="color: #fff" />
        </div>
      </template>
    </a-drawer>
  </div>
</template>

<script lang="ts" setup name="SettingDrawer">
import { BellOutlined } from '@ant-design/icons-vue'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons-vue'
import Inform from './components/inform.vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
const $store = useStore()
const menusList = ref([
  { title: '消息通知', inform: 2, key: 'inform' },
  { title: '公告', inform: 3, key: '23' },
  { title: '新闻', inform: 4, key: '12' },
  { title: '回复', inform: 5, key: '58' }
])
const selectedKeys = ref<{ title: string; inform: number; key: string }>({ title: '消息通知', inform: 2, key: 'inform' })
const selectMenus = (item) => {
  selectedKeys.value = item
}
const onClose = () => {
  $store.commit('SET_SHOW_INFORM', false)
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
