<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectTemplate',
})
</script>
<template>
  <div>
    <a-popover :getPopupContainer="(triggerNode) => triggerNode.parentNode" trigger="click" :overlayStyle="{ width: '1200px' }">
      <template #content>
        <div class="template-content">
          <div class="template-item" v-for="(item, index) in state.optionsList" :key="index">
            <!-- <a-tooltip>
                            <template slot="title"> {{ item.name }} </template>

                        </a-tooltip> -->
            <span @click="selectedClick(index)" :class="item.check ? 'tips-item' : ''"
              ><HeartOutlined v-if="item.template_file_type == 2" /> <FileWordOutlined v-if="item.template_file_type == 1" />{{ item.name }}
            </span>
          </div>
        </div>
      </template>
      <a-button type="primary">选择模板</a-button>
    </a-popover>
    <div class="selected-template">
      <div style="width: 80px">已选中模板:</div>
      <div style="flex: 1">
        <span v-for="(item, index) in state.optionsList" :key="index">
          <span class="tips-item" v-if="item.check">
            <HeartOutlined v-if="item.template_file_type == 2" /> <FileWordOutlined v-if="item.template_file_type == 1" /> {{ item.name }}
            <CloseOutlined @click="deleteTemplate(index)" />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="SelectTemplate">
import { CloseOutlined, FileWordOutlined, HeartOutlined } from '@ant-design/icons-vue'
import cloneDeep from 'lodash.clonedeep'
import { reactive, watch } from 'vue'
const emit = defineEmits(['update:value'])
const props = defineProps({
  value: {
    type: String
  },
  options: {
    type: Array,
    default: []
  }
})

watch(
  () => props.value,
  (val) => {
    if (val && val.length > 0) {
      let list = val.split(',').map((item) => parseInt(item))
      state.selectedList = list
      props.options.forEach((element: any, index) => {
        if (list.indexOf(element.id) > -1) {
          state.optionsList[index].check = true
        }
      })
    }
  },
  { deep: true }
)
watch(
  () => props.options,
  (val) => {
    if (val && val.length > 0) {
      let optionsList = cloneDeep(val)
      if (props.value && props.value.length > 0) {
        let list = props.value.split(',').map((item) => parseInt(item))
        state.selectedList = list
        optionsList.forEach((element, index) => {
          if (list.indexOf(element.id) > -1) {
            optionsList[index].check = true
          }
        })
      } else {
        optionsList = optionsList.map((item) => {
          item.check = false
          return item
        })
      }
      state.optionsList = cloneDeep(optionsList)
    }
  },
  { deep: true, immediate: true }
)
interface state {
  optionsList: any
  selectedList: any
}
const state = reactive<state>({
  optionsList: [],
  //   已经选中的模板
  selectedList: []
})

const deleteTemplate = (index) => {
  let item = state.optionsList[index]
  state.optionsList[index].check = false

  state.selectedList.forEach((selectedItem, selectedIndex) => {
    if (item.id == selectedItem) {
      state.selectedList.splice(selectedIndex, 1)
    }
  })
  emit('update:value', state.selectedList.join(','))
}
const selectedClick = (index) => {
  state.optionsList[index].check = !state.optionsList[index].check
  let item = state.optionsList[index]
  if (state.optionsList[index].check) {
    state.selectedList.push(item.id)
  } else {
    let index: number | undefined = undefined
    for (let listindex = 0; listindex < state.selectedList.length; listindex++) {
      if (state.selectedList[listindex] == item.id) {
        index = listindex
      }
    }
    state.selectedList.splice(index, 1)
  }
  state.optionsList = [...state.optionsList]
  emit('update:value', state.selectedList.join(','))
}
</script>

<style lang="less" scoped>
.template-content {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 0;

  .template-item {
    margin: 2px 0;
    box-sizing: border-box;
    width: 20%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      cursor: pointer;
    }

    span:hover {
      color: #40a9ff;
    }
  }

  .wordtemplets {
    border-left: 3px dotted #ccc;
    padding-left: 5px;
  }
}

.tips-item {
  box-sizing: border-box;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  border: 1px solid #b7eb8f;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  color: #52c41a;
  background: #f6ffed;
}
.tips-item2 {
  box-sizing: border-box;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  border: 1px solid #ffa39e;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  color: #cf1322;
  background: #fff1f0;
}

.selected-template {
  display: flex;
  align-items: flex-start;
}
</style>
