import baseService from '@/utils/http/axios'
import { clearUserInfo } from '@/utils/util'
import ls from '@/utils/Storage';
const user = {
    state: {
        token: '',
        name: '',
        welcome: '',
        avatar: '',
        roles: [],
        userInfo: {},
        filings_info: {},
        image_domain: '',
        image_domain_a: '',
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_IMAGE_DOMAIN: (state, image_domain) => {
            state.image_domain = image_domain
            state.image_domain_a = image_domain.replace('https://hncs.cskaiyela.com', '/hncskaiyela')
        },
        SET_NAME: (state, { name, welcome }) => {
            state.name = name
            state.welcome = welcome
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_USER_INFO: (state, info) => {
            state.userInfo = info
        },
        SET_FILINGS_INFO: (state, info) => {
            state.filings_info = info
        }
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                let requeryParam = {
                    url: '/admin/v1/login',
                    method: 'post',
                    data: userInfo,
                }
                baseService(requeryParam).then((res: any) => {
                    commit('SET_TOKEN', `${res.data.original.token_type} ${res.data.original.access_token}`)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit }) {
            // 请求后端获取用户信息 /api/user/info
            let requeryParam = {
                url: '/admin/v1/userInfo',
                method: 'get',
            }
            baseService(requeryParam).then((res: any) => {
                ls.set('USER_INFO', res.data.userinfo);
                ls.set('FILINGS_INFO', res.data.filings_info);
                ls.set('IMAGE_DOMAIN', res.data.config.image_domain);
                commit('SET_USER_INFO', res.data.userinfo)
                commit('SET_FILINGS_INFO', res.data.filings_info)
                commit('SET_IMAGE_DOMAIN', res.data.config.image_domain)
            }).catch(error => {
            })
        },

        // 登出
        Logout({ commit, state }) {
            return new Promise((resolve) => {
                let requeryParam = {
                    url: '/admin/v1/logout',
                    method: 'delete'
                }
                baseService(requeryParam).then((res: any) => {
                    clearUserInfo()
                    resolve(res)
                }).catch((err) => {
                    console.log('logout fail:', err)
                })
            })
        }

    }
}

export default user
