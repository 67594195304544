<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="标题">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.name" placeholder="请输入标题"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="tabs">
      <a-tabs v-model:activeKey="state.tabsKey" @change="tabsChange">
        <a-tab-pane :key="1" tab="短信"> </a-tab-pane>
        <a-tab-pane v-if="user.id==1" :key="2" tab="其他短信"> </a-tab-pane>
      </a-tabs>
    </div>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex == 'content'">
          <div v-if="text">
            {{ text }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="column.dataIndex == 'created_at'">
          <div v-if="text || record.ctime">
            {{ text || record.ctime }}
          </div>
          <div v-else>-</div>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import ls from '@/utils/Storage'
const user = ls.get('USER_INFO')

const columns = [
  {
    title: '内容',
    dataIndex: 'content'
  },
  {
    title: '电话',
    dataIndex: 'tel',
    width: 100
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    width: 100
  }
]
interface data {
  showCreate: boolean
  detailData: object
  loading: boolean
  tabsKey: number
}
const state = reactive<data>({
  showCreate: false,
  detailData: {},
  loading: false,
  tabsKey: 1
})
interface queryParam {
  name?: string
  template_type?: string
  is_other?: string
}

let param = ref<queryParam>({ name: '' })
let queryParam = param.value
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
const refreshTabel = () => {
  pagination.current = 1
  param.value = {}
  queryParam = param.value
  loadData()
}
const tabsChange = (val) => {
  state.tabsKey = val
  queryParam.is_other = val
  loadData()
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/yzmlst',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const onChange = (val) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
</script>

<style lang="less" scoped></style>
