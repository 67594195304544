<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="标题">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.name" placeholder="请输入标题"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="模板种类">
              <a-select v-model:value="queryParam.template_type" placeholder="请选择模板种类">
                <a-select-option :value="1">地址资料模板</a-select-option>
                <a-select-option :value="2">承诺书模板</a-select-option>
                <a-select-option :value="3">其他资料模板</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd">新建模板</a-button>
    </div>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'template_file_type'">
          {{ templateFileTypeDic.get(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'template_type'">
          {{ templateTypeDic.get(text) }}
        </template>
        <template v-else-if="column.dataIndex === 'operate'">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除此模板?" @confirm="handleDelete(record)">
            <a style="color: red">删除</a>
          </a-popconfirm>
        </template>
      </template>
    </a-table>

    <createInspection
      v-if="state.showCreate"
      :detailData="state.detailData"
      @cancel="
        () => {
          loadData()
          state.showCreate = false
        }
      "
    ></createInspection>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import createInspection from './components/createInspection.vue'
const columns = [
  {
    title: '名称',
    dataIndex: 'name'
  },
  {
    title: '模板类型',
    dataIndex: 'template_file_type'
  },
  {
    title: '模板种类',
    dataIndex: 'template_type'
  },
  {
    title: '创建时间',
    dataIndex: 'created_at'
  },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]
const templateFileTypeDic = new Map([
  [1, '普通模板'],
  [2, '图片模板']
])
const templateTypeDic = new Map([
  [1, '地址资料模板'],
  [2, '承诺书模板'],
  [3, '其他资料模板']
])

interface data {
  showCreate: boolean
  detailData: object
  loading: boolean
}
const state = reactive<data>({
  showCreate: false,
  detailData: {},
  loading: false
})
interface queryParam {
  name?: string
  template_type?: string
}

let param = ref<queryParam>({ name: '' })
let queryParam = param.value
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
const refreshTabel = () => {
  pagination.current = 1
  param.value = {}
  queryParam = param.value
  loadData()
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/templateList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => ({ ...item, isShowOptions: false }))
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const onChange = (val) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
// 添加客户
const handleAdd = () => {
  state.detailData = {}
  state.showCreate = true
}
// 编辑客户
const handleEdit = (record) => {
  state.showCreate = true
  state.detailData = { ...record }
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/template/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped></style>
