<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="报销总额" name="money" required>
          <a-input style="width: 200px" type="number" placeholder="请输入报销金额" v-model:value="formState.money" addon-after="元"></a-input>
        </a-form-item>
        <a-form-item label="费用发生时间" name="occurrence_time" required>
          <a-date-picker :format="format" showTime :value="pickTime" @change="pickTimeChange" placeholder="请选择费用发生时间"></a-date-picker>
        </a-form-item>

        <a-form-item label="费用类型" name="type" required>
          <a-select v-model:value="formState.type" placeholder="请输入费用类型">
            <a-select-option :value="1">餐饮</a-select-option>
            <a-select-option :value="2">打车</a-select-option>
            <a-select-option :value="3">办公</a-select-option>
            <a-select-option :value="4">差旅</a-select-option>
            <a-select-option :value="5">其他</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="费用说明" name="reason">
          <a-input v-model:value="formState.reason" placeholder="请输入费用说明"></a-input>
        </a-form-item>
        <a-form-item label="附件" name="accessory">
          <Upload
            upload_path="public/uploads/报销附件"
            uploadType="text"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            :limitation="1"
            v-model:value="formState.accessory"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="报销人" name="vesting_party" required>
          <a-input v-model:value="formState.vesting_party" placeholder="请输入报销人"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { Upload } from '@/components'
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
const $store = useStore()
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const format = 'YYYY-MM-DD HH:mm:ss'
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const pickTime = computed(() => {
  const time = formState.occurrence_time
  if (time) {
    return dayjs(time, format)
  } else {
    return null
  }
})
const pickTimeChange = (e, val) => {
  formState.occurrence_time = val
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增报销',
  loading: false
})
let formState = reactive<any>({
  vesting_party: null,
  accessory: null,
  reason: null,
  occurrence_time: null,
  money: null
})

onMounted(() => {
  formState.vesting_party = $store.state.user.userInfo.idcard_name
  if (props.detailData?.id) {
    state.modalTitle = '编辑报销'
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/claimExpenses/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    Object.assign(formState, res.data)
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id > 0) {
        let requeryParam = {
          url: '/admin/v1/claimExpenses/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/claimExpenses',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
