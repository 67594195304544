<template>
  <vue-ueditor-wrap v-model="content" :config="editorConfig" :editorDependencies="['ueditor.config.js', 'ueditor.all.js']" style="min-height: 530px" />
</template>

<script lang="ts" setup>
import ls from '@/utils/Storage'
import { ref, watch } from 'vue'
const emit = defineEmits(['update:value'])
const content = ref('')
const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  uid: {
    type: String,
    default: 'editor'
  }
})
watch(
  () => props.value,
  (val) => {
    content.value = val
  },
  { deep: true }
)
watch(
  () => content,
  (val) => {
    emit('update:value', val)
  },
  { deep: true }
)

//富文本配置
const editorConfig = {
  initialFrameWidth: null, //设置null则宽度自适应
  initialFrameHeight: 320, //区域内容高度
  catchRemoteImageEnable: false, //抓取远程图片是否开启,默认true
  autoHeightEnabled: false, //固定高度且带滚动条
  autoSyncData: false,
  autoFloatEnabled: true,
  UEDITOR_HOME_URL: '/UEditor/',
  UEDITOR_CORS_URL: '/UEditor/',
  serverUrl: '/hncskaiyela/admin/v1/upload',
  serverHeaders: {
    Authorization: ls.get('ACCESS_TOKEN')
  },
  imageActionName: 'uploadimage',
  imageFieldName: 'file',
  imageMaxSize: 2048000,
  imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
  imageCompressEnable: true,
  imageCompressBorder: 1600,
  imageInsertAlign: 'none',
  imageUrlPrefix: '',
  imagePathFormat: '/uploads/{yyyy}{mm}{dd}/{time}{rand:6}',
  videoActionName: 'uploadvideo',
  videoFieldName: 'file',
  videoPathFormat: '/uploads/{yyyy}{mm}{dd}/{time}{rand:6}',
  videoUrlPrefix: '',
  videoMaxSize: 102400000,
  videoAllowFiles: ['.flv', '.swf', '.mkv', '.avi', '.rm', '.rmvb', '.mpeg', '.mpg', '.ogg', '.ogv', '.mov', '.wmv', '.mp4', '.webm', '.mp3', '.wav', '.mid'],
  fileActionName: 'uploadfile',
  fileFieldName: 'file',
  filePathFormat: 'upload/file/{yyyy}{mm}{dd}/{time}{rand:6}',
  fileMaxSize: 102400000,
  fileAllowFiles: [
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.bmp',
    '.flv',
    '.swf',
    '.mkv',
    '.avi',
    '.rm',
    '.rmvb',
    '.mpeg',
    '.mpg',
    '.ogg',
    '.ogv',
    '.mov',
    '.wmv',
    '.mp4',
    '.webm',
    '.mp3',
    '.wav',
    '.mid',
    '.rar',
    '.zip',
    '.tar',
    '.gz',
    '.7z',
    '.bz2',
    '.cab',
    '.iso',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.pdf',
    '.txt',
    '.md',
    '.xml',
    '.crx'
  ]
  /*    toolbars: [ //根据需求筛选需要的功能，这里是根据需求筛选后的
           [
               "fullscreen",   // 全屏
               "source",       // 源代码
               "|",
               "undo",         // 撤销
               "redo",         // 重做
               "|",
               "bold",         // 加粗
               "italic",       // 斜体
               "underline",    // 下划线
               "fontborder",   // 字符边框
               "strikethrough",// 删除线
               "superscript",  // 上标
               "subscript",    // 下标
               "removeformat", // 清除格式
               "formatmatch",  // 格式刷
               "autotypeset",  // 自动排版
               "blockquote",   // 引用
               "pasteplain",   // 纯文本粘贴模式
               "|",
               "forecolor",    // 字体颜色
               "backcolor",    // 背景色
               "insertorderedlist",   // 有序列表
               "insertunorderedlist", // 无序列表
               "selectall",    // 全选
               "cleardoc",     // 清空文档
               "|",
               "rowspacingtop",// 段前距
               "rowspacingbottom",    // 段后距
               "lineheight",          // 行间距
               "|",
               "customstyle",         // 自定义标题
               "paragraph",           // 段落格式
               "fontfamily",          // 字体
               "fontsize",            // 字号
               "|",
               "directionalityltr",   // 从左向右输入
               "directionalityrtl",   // 从右向左输入
               "indent",              // 首行缩进
               "|",
               "justifyleft",         // 居左对齐
               "justifycenter",       // 居中对齐
               "justifyright",
               "justifyjustify",      // 两端对齐
               "|",
               "touppercase",         // 字母大写
               "tolowercase",         // 字母小写
               "|",
               "link",                // 超链接
               "unlink",              // 取消链接
               "anchor",              // 锚点
               "|",
               "imagenone",           // 图片默认
               "imageleft",           // 图片左浮动
               "imageright",          // 图片右浮动
               "imagecenter",         // 图片居中
               "|",
               "simpleupload",        // 单图上传
               "insertimage",         // 多图上传
               "emotion",             // 表情
               "scrawl",              // 涂鸦
               "insertvideo",         // 视频
               "attachment",          // 附件
               "insertframe",         // 插入Iframe
               "insertcode",          // 插入代码
               "pagebreak",           // 分页
               "template",            // 模板
               "background",          // 背景
               "formula",             // 公式
               "|",
               "horizontal",          // 分隔线
               "date",                // 日期
               "time",                // 时间
               "spechars",            // 特殊字符
               "wordimage",           // Word图片转存
               "|",
               "inserttable",         // 插入表格
               "deletetable",         // 删除表格
               "insertparagraphbeforetable",     // 表格前插入行
               "insertrow",           // 前插入行
               "deleterow",           // 删除行
               "insertcol",           // 前插入列
               "deletecol",           // 删除列
               "mergecells",          // 合并多个单元格
               "mergeright",          // 右合并单元格
               "mergedown",           // 下合并单元格
               "splittocells",        // 完全拆分单元格
               "splittorows",         // 拆分成行
               "splittocols",         // 拆分成列
               "|",
               "print",               // 打印
               "preview",             // 预览
               "searchreplace",       // 查询替换
               "help",                // 帮助
           ]
       ], */
}
</script>
