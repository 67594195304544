<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="模板名称" name="name">
          <a-input placeholder="请输入模板名称" v-model:value="formState.name"></a-input>
        </a-form-item>
        <a-form-item label="关键字触发" name="keyword">
          <a-input placeholder="请输入关键字" v-model:value="formState.keyword"></a-input>
        </a-form-item>
        <a-form-item label="不出场情况">
          <a-checkbox-group v-model:value="formState.noshowtype">
            <div>
              <a-checkbox :value="1">公司设立时</a-checkbox>
              <a-checkbox :value="3">个体户设立时</a-checkbox>
              <a-checkbox :value="5">个独设立时</a-checkbox>
              <a-checkbox :value="7">分公司设立时</a-checkbox>
              <a-checkbox :value="9">合伙企业设立时</a-checkbox>
            </div>
            <div>
              <a-checkbox :value="2">公司变更时</a-checkbox>
              <a-checkbox :value="4">个体户变更时</a-checkbox>
              <a-checkbox :value="6">个独变更时</a-checkbox>
              <a-checkbox :value="8">分公司变更时</a-checkbox>
              <a-checkbox :value="10">合伙企业变更时</a-checkbox>
            </div>
          </a-checkbox-group>
        </a-form-item>

        <a-form-item label="客户签字页是否展现">
          <a-radio-group v-model:value="formState.is_show_client" :options="isShowClientOptions"> </a-radio-group>
        </a-form-item>
        <a-form-item label="模板类型">
          <a-radio-group :disabled="props.detailData?.id > 0" v-model:value="formState.template_file_type" :options="templateFileTypeOptions"> </a-radio-group>
        </a-form-item>
        <a-form-item label="模板种类">
          <a-radio-group v-model:value="formState.template_type" :options="templateTypeOptions"> </a-radio-group>
        </a-form-item>
        <a-form-item label="上传附件" name="template_url">
          <Upload
            upload_path="public/uploads/模板资料"
            uploadType="text"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            :limitation="1"
            v-model:value="formState.template_url"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { Upload } from '@/components'
import { reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { NumberUtils } from '@aesoper/normal-utils'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const isShowClientOptions = [
  { label: '展现', value: 1 },
  { label: '不展现', value: 2 }
]
const templateFileTypeOptions = [
  { label: '普通模板', value: 1 },
  { label: '纯图片', value: 2 }
]
const templateTypeOptions = [
  { label: '地址资料模板', value: 1 },
  { label: '承诺书模板', value: 2 },
  { label: '其他资料模板', value: 3 }
]
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
  name: [{ required: true, message: '请输入模板名称', trigger: 'change' }],
  template_url: [{ required: true, message: '请上传附件', trigger: 'change' }]
}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增模板',
  loading: false
})

interface formState {
  name: string
  is_show_client: number
  template_file_type: number
  template_type: NumberUtils
  template_url: object[]
  keyword: string
  noshowtype: number[]
}
let form = ref<formState>({
  name: '',
  is_show_client: 1,
  template_file_type: 1,
  template_type: 1,
  template_url: [],
  keyword: '',
  noshowtype: []
})
let formState = form.value

onMounted(() => {
  if (props.detailData?.id) {
    state.modalTitle = '编辑模板'
    queryDetail(props.detailData.id)
  }
})

const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/template/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    form.value = res.data
    formState = form.value
  })
}
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      if (props.detailData?.id) {
        let requeryParam = {
          url: '/admin/v1/template/' + props.detailData.id,
          method: 'put',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/template',
          method: 'post',
          data: formState
        }
        baseService(requeryParam)
          .then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
          })
          .catch((res) => {
            state.loading = false
            if (res.msg) {
              message.error(res.msg)
            } else {
              message.error('操作失败')
            }
          })
      }
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
