<template>
  <a-modal :title="`${tabsTitle[state.tabsKey]}(${props.tableColumnsData.full_name})`" :width="1300" :open="true" :footer="null" @cancel="cancel">
    <div class="relative">
      <div>
        <a-button class="add-btn" type="primary" v-if="state.tabsKey == 1" @click="addClick">添加{{ tabsTitle[state.tabsKey] }}</a-button>
      </div>
      <a-tabs v-model:activeKey="state.tabsKey">
        <a-tab-pane :key="1" tab="状态">
          <LoadingOutlined v-show="state.loading && state.statusDataList?.length == 0" />
          <div v-if="state.statusDataList?.length > 0">
            <a-timeline class="timelineStatus">
              <a-timeline-item color="green" v-for="(item, index) in state.statusDataList" :key="index">
                <p>{{ item.after_status_name }} {{ item.created_at }}</p>
                <p v-if="item.is_show == 1" v-html="item.reason"></p>
                <p>操作人：{{ item.operator_name }}</p>
              </a-timeline-item>
            </a-timeline>
          </div>
          <a-empty v-else style="height: 300px" description="暂无状态" class="flex-center flex-dc" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="日志">
          <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
            <template #bodyCell="{ column, record, index, text }">
              <template v-if="column.dataIndex === 'reason'">
                <div v-if="record.remark" v-html="record.remark"></div>
                <div v-if="record.reason" v-html="record.reason"></div>
              </template>
              <template v-else-if="column.dataIndex === 'operator_name'">
                {{ text ? text : '-' }}
              </template>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <a-modal title="添加状态" :bodyStyle="{ padding: '20px', paddingBottom: '0' }" :width="500" :destroyOnClose="true" :open="state.createStatusOpen" @ok="createStatusOk" @cancel="statusCancel">
      <a-form :model="createStatusForm" ref="createStatusFormRef" v-bind="formLayout">
        <a-form-item label="选择状态" name="status" required>
          <a-select v-model:value="createStatusForm.status" placeholder="请选择状态">
            <a-select-option v-for="(item, index) in state.statusList" :key="index" :value="Object.keys(item)[0]">{{ Object.values(item)[0] }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="说明" name="reason">
          <a-input placeholder="请输入说明" v-model:value="createStatusForm.reason"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-modal>
</template>

<script lang="ts" setup>
import { PlusOutlined, UploadOutlined, EyeOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import Upload from '@/components/base/Upload.vue'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const $store = useStore()
const emit = defineEmits(['cancel'])
let dataSource = ref([])

const tabsTitle = {
  1: '状态',
  2: '日志'
}
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 14 }
  }
}
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: false
  },
  logTabsKey: {
    type: Number,
    default: 1
  }
})

const createStatusForm = reactive<any>({
  status: undefined,
  reason: ''
})
const createStatusFormRef = ref()

let param = ref<any>({ name: '' })
let queryParam = param.value
const columns = [
  {
    title: '日志',
    dataIndex: 'reason'
  },
  {
    title: '日志类型',
    dataIndex: 'type_name',
    width: 100
  },
  {
    title: '操作人',
    dataIndex: 'operator_name',
    width: 100
  },

  {
    title: '创建时间',
    dataIndex: 'created_at',
    width: 150
  }
]
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  queryParam.status = filters.status
  logData()
}
const logData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: `/admin/v1/getSimpleCancellatioinLog`,
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, { pid: props.tableColumnsData.id, type: 2 })
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (item.urls?.length > 0) {
          let list1 = item.urls.filter((item) => ['jpg', 'png', 'jpeg'].includes(item.name.split('.')[1]))
          let list2 = item.urls.filter((item) => !['jpg', 'png', 'jpeg'].includes(item.name.split('.')[1]))
          item.urls = [...list1, ...list2]
        }
        return { ...item }
      })
    })
    .catch((res) => {})
}

const state = reactive<any>({
  //tabs key
  tabsKey: 1,
  loading: false,
  statusDataList: [],
  createStatusOpen: false,
  statusList: [],
  isRefresh: false
})
onMounted(() => {
  state.tabsKey = props.logTabsKey
  statusData()
  getCancellatioinStatus()
  logData()
})

const addClick = () => {
  if (state.tabsKey == 1) {
    state.createStatusOpen = true
  }
}
const statusCancel = () => {
  state.createStatusOpen = false
  createStatusForm.status = undefined
  createStatusForm.reason = ''
}

const createStatusOk = () => {
  createStatusFormRef.value
    .validate()
    .then(() => {
      let requestParam = {
        url: '/admin/v1/addCancellationLog',
        method: 'post',
        data: Object.assign({ pid: props.tableColumnsData.id }, createStatusForm)
      }
      baseService(requestParam)
        .then((res) => {
          state.isRefresh = true
          message.success('添加成功')
          state.createStatusOpen = false
          createStatusForm.status = undefined
          statusData()
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('添加失败')
          }
        })
    })
    .catch(() => {})
}
const statusData = () => {
  state.loading = true
  let requestParam = {
    url: '/admin/v1/getSimpleCancellatioinLog',
    method: 'post',
    data: {
      pid: props.tableColumnsData.id,
      type: 1,
      limit: 'all',
      page: 1
    }
  }
  baseService(requestParam)
    .then((res) => {
      state.statusDataList = res.data.list
    })
    .catch((res) => {})
}

const getCancellatioinStatus = () => {
  let requestParam = {
    url: '/admin/v1/getCancellatioinStatus?pid=' + props.tableColumnsData.id,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      state.statusList = res.data.status_list
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取状态失败')
      }
    })
}

const cancel = () => {
  emit('cancel', state.isRefresh)
}
</script>

<style lang="less" scoped>
.add-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.timelineStatus {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
:deep(.ant-table-cell) {
  padding: 12px !important;
}
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.timeline {
  .ant-timeline-item:first-child {
    p:first-child {
      color: #ff5000;
    }
  }
}
</style>
