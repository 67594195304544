<template>
  <a-modal
    :title="`推送(${props.tuisongData.full_name})`"
    :width="1200"
    :open="true"
    @ok="onFormSubmit"
    :confirmLoading="state.loading"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="loading">
      <a-form ref="formRef" v-bind="formLayout" :model="formState" :rules="rules">
        <a-form-item label="推送方式" name="type">
          <a-radio-group v-model:value="formState.type" button-style="solid">
            <a-radio-button :value="1">微信</a-radio-button>
            <a-radio-button :value="2">短信</a-radio-button>
            <a-radio-button :value="3">邮箱</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="微信" v-if="formState.type === 1" name="type">
          <div class="d-flex" style="gap: 5px; flex-wrap: wrap">
            <template v-for="(tag, index) in formState.tels" :key="tag">
              <a-tag closable color="green" @close="handleClose(tag)">
                {{ tag.name }}
              </a-tag>
            </template>
            <!-- <a-select
              mode="multiple"
              placeholder="请输入公司名称"
              style="width: 100%"
              optionLabelProp="label"
              v-model:value="formState.tels"
              show-search
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
            >
              <a-select-option v-for="(optionsItem, optionsIndex) in state.options" :key="optionsIndex" :value="optionsItem.id" :label="optionsItem.full_name" :title="optionsItem">
                <a-tooltip>
                  <template #title> {{ optionsItem.full_name }}</template>
                  {{ optionsItem.full_name }}
                </a-tooltip>
              </a-select-option>
            </a-select> -->
          </div>
        </a-form-item>
        <a-form-item v-else :label="formState.type == 2 ? '短信' : '邮箱'" name="type">
          <a-textarea disabled v-model:value="formState.content"></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
const $store = useStore()
const emit = defineEmits(['cancel', 'ok'])
const rules = {}
interface formState {
  type: number | null
  content: string
  tels: any[]
}

const formState = reactive<formState>({
  type: 1,
  content: '',
  tels: []
})
const props = defineProps({
  tuisongData: {
    type: Object,
    default: () => {}
  },
  loading: {
    type: Boolean,
    default: () => false
  },
  model: {
    type: Object,
    default: () => null
  }
})
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
onMounted(() => {
  queryDetail()
  formState.content = `【开业啦】${props.tuisongData.full_name}有1个${
    props.tuisongData.type == 1 ? '投诉' : props.tuisongData.type == 2 ? '举报' : '工单'
  }，请及时联系您的客户经理朱尚，如未联系，您公司可能被判断为失联，列入地址异常名录。`
})

const handleSearch = (e) => {
  if (e == '') {
    state.options = []
    return
  }
  let requestParam = {
    url: '/admin/v1/customerSearchAll?keyword=' + e,
    method: 'get'
  }
  baseService(requestParam)
    .then((res) => {
      state.options = res.data.list
    })
    .catch((res) => {
      state.options = []
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('查询失败')
      }
    })
}

const handleClose = (removedTag: string) => {
  const tags = formState.tels.filter((tag) => tag !== removedTag)
  formState.tels = tags
}

const queryDetail = () => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/getWorkOrdercInfo?id=` + props.tuisongData.id,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    formState.tels = res.data.tels
  })
}
const formRef = ref()
const state = reactive<any>({
  loading: false,
  inputVisible: false
})
const onFormSubmit = () => {
  let data: any = {
    id: props.tuisongData.id,
    warn_type: formState.type,
    tel_or_mail: []
  }
  formState.tels.forEach((res: any) => {
    data.tel_or_mail.push(res.tel)
  })
  state.loading = true
  let requeryParam = {
    url: '/admin/v1/pushMessage',
    method: 'post',
    data: data
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.loading = false
      message.success(res.msg)
      emit('ok')
    })
    .catch((res) => {
      state.loading = false
      if (res.data) {
        message.warning('参数错误,请检查')
      } else {
        message.warning(res.msg)
      }
    })
}
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 65px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin-bottom: 10px;
}
</style>
