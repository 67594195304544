<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    :width="1000"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="变量名" name="name" required>
          <a-input v-model:value="formState.name" placeholder="请输入变量名"></a-input>
        </a-form-item>
        <a-form-item label="分组" name="group" required>
          <a-select v-model:value="formState.group" placeholder="请选择分组">
            <a-select-option v-for="(item, index) in props.classList" :key="index" :value="item.key">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="类型" name="type" required>
          <a-select v-model:value="formState.type" placeholder="请选择类型">
            <a-select-option v-for="(item, index) in list" :key="index" :value="item">{{ item }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="变量标题" name="title">
          <a-input v-model:value="formState.title" placeholder="请输入变量标题"></a-input>
        </a-form-item>
        <a-form-item label="变量描述" name="tip">
          <a-input v-model:value="formState.tip" placeholder="请输入变量描述"></a-input>
        </a-form-item>
        <a-form-item label="变量值" name="content" required>
          <a-input v-model:value="formState.content" placeholder="请输入变量值"></a-input>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted, computed } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import type { PropType } from 'vue'
import { useStore } from 'vuex'
const $store = useStore()

const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  },
  classList: {
    type: Array as PropType<any>
  }
})
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const list = ['string', 'text', 'int', 'bool', 'file']
const rules = {}
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
interface state {
  modalTitle: string
  loading: boolean
}
const state = reactive<state>({
  modalTitle: '新增配置',
  loading: false
})
let formState = reactive<any>({
  name: null,
  type: null,
  title: null,
  tip: null,
  content: null
})

onMounted(() => {})

const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      let requeryParam = {
        url: '/admin/config/store',
        method: 'post',
        data: formState
      }
      baseService(requeryParam)
        .then((res: any) => {
          state.loading = false
          message.success(res.msg)
          emit('ok')
        })
        .catch((res) => {
          state.loading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch(() => {})
}
</script>

<style lang="less" scoped></style>
