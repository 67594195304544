<template>
  <a-modal title="地址续费" :confirmLoading="state.loading" centered :bodyStyle="{ padding: '20px' }" :width="1000" :open="true" @cancel="cancel">
    <template #footer>
      <a-button @click="cancel">取消</a-button>
      <a-popconfirm title="是否确定？" @confirm="onFormSubmit">
        <a-button :loading="state.loading" type="primary">确定</a-button>
      </a-popconfirm>
    </template>
    <a-form ref="ruleForm" :rules="rules" :model="formState" v-bind="formLayout" scrollToFirstError="true">
      <a-form-item label="主体名称">
        {{ props.tableColumnsData.full_name }}
        <a-tag class="ant-tag-green" v-if="props.tableColumnsData.exception_flag == 1">正常状态</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 2">待申报异常，未交材料</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 3">已申报异常，已交材料</a-tag>
        <a-tag class="ant-tag-red" v-else-if="props.tableColumnsData.exception_flag == 4">已确认进入异常名录</a-tag>
      </a-form-item>

      <a-form-item label="历史名称" v-if="props.tableColumnsData.history_names?.length > 0">
        <div v-for="(item, index) in props.tableColumnsData.history_names" :key="index">
          {{ item }}
        </div>
      </a-form-item>

      <a-form-item label="注册地址">
        {{ props.tableColumnsData.full_address_number }}
      </a-form-item>

      <a-form-item label="代理商" name="belonger" required>
        {{ formState.belonger }}
      </a-form-item>
      <a-form-item label="备注" v-if="props.tableColumnsData.remark">
        {{ props.tableColumnsData.remark }}
      </a-form-item>

      <a-form-item label="当前托管时间"> {{ props.tableColumnsData.enter_starttime.substr(0, 10) }} ~ <span v-html="props.tableColumnsData.enter_endtime"></span> </a-form-item>
      <a-form-item label="支付渠道">
        <a-radio-group v-model:value="state.type" button-style="solid">
          <a-radio-button :value="1">账户余额</a-radio-button>
          <a-radio-button :value="2">对公账户</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="续费类型">
        <a-radio-group v-model:value="formState.fee_standard" button-style="solid" @change="getComputeOreder">
          <a-radio-button :value="1">按年续费</a-radio-button>
          <a-radio-button :value="2">一次性续费</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="按年续费价格" v-if="formState.fee_standard == 1">
        <span class="red"> ￥{{ props.tableColumnsData.next_year_price }} / 年 </span>
      </a-form-item>

      <PlatformPayment v-if="state.platformVisible" @cancel="handleCancel" @ok="handleOk" :tableColumnsData="props.tableColumnsData"></PlatformPayment>

      <a-form-item label="续费时长" v-if="formState.fee_standard == 1" name="month_quantity" required>
        <a-select v-model:value="formState.month_quantity" placeholder="请选择续费时长" @change="getComputeOreder">
          <a-select-option v-for="(item, index) in timesOption" :key="item.value"> {{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="一次性付费续费金额" v-if="formState.fee_standard == 2">
        <span class="red" v-if="formState.price > 0"> ￥{{ formState.price }} </span>
        <span v-else>-</span>
        <loading-outlined v-if="state.priceLoading" />
      </a-form-item>

      <div v-if="state.type == 2">
        <a-form-item label="付款人全称" name="public_payer_name" required>
          <a-input v-model:value="formState.public_payer_name" placeholder="请输入付款人全称"></a-input>
        </a-form-item>
        <a-form-item label="付款时间" name="public_pay_time" required>
          <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="formState.public_pay_time" placeholder="请选择付款时间"></a-date-picker>
        </a-form-item>
        <a-form-item label="付款金额" name="public_money" required>
          <a-input v-model:value="formState.public_money" placeholder="请输入付款金额"></a-input>
        </a-form-item>
        <a-form-item label="付款备注" name="public_remark" required>
          <a-input v-model:value="formState.public_remark" placeholder="请输入付款备注"></a-input>
        </a-form-item>
        <a-form-item label="收款人全称" name="public_payee_name" required>
          <a-input v-model:value="formState.public_payee_name" placeholder="请输入收款人全称"></a-input>
        </a-form-item>
      </div>

      <a-form-item label="附件" :required="state.type == 2 ? true : false">
        <Upload
          :upload_path="props.tableColumnsData.upload_path"
          :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
          @uploading="
            (val) => {
              state.finishLoading = val
            }
          "
          :customerName="props.tableColumnsData.entityNameToRegister.full_name"
          uploadType="text"
          v-model:value="formState.attachment"
          :limitation="99"
          uploadText="上传附件"
        >
        </Upload>
      </a-form-item>

      <a-form-item label="备注">
        <a-textarea v-model:value="formState.remark" placeholder="请输入备注"></a-textarea>
      </a-form-item>
      <a-form-item label="日志">
        <a @click="onViewLogClick">查看日志</a>
      </a-form-item>
      <a-form-item label="总计需要支付金额">
        <div style="font-size: 24px; color: red; line-height: 24px">
          <div v-if="state.coupon_use_id >= 1">
            <span class="red" style="text-decoration: line-through">￥{{ state.pay_amount_total }} </span>
            <span>，优惠了￥{{ state.discount_amount_total }}</span>
            <a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small" v-if="state.isselected_coupon"
              >使用了优惠券（{{ state.isselected_coupon.coupon_type == 1 ? '直减现金券' : state.isselected_coupon.coupon_type == 2 ? '满减现金券' : '折扣券' }}：{{
                state.isselected_coupon.name
              }}√）</a-button
            >
            <a style="margin-left: 20px; font-size: 14px" @click="deleteCoupon">删除优惠券</a>
          </div>
          <div v-else>
            ￥{{ state.actually_paid_total }}
            <a-button type="primary" @click="useCoupon" style="background: #ff6b26; margin-left: 10px" size="small">使用优惠券</a-button>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="优惠后需要支付金额" v-if="state.coupon_use_id >= 1">
        <div style="font-size: 24px; color: red; margin-top: -4px">￥{{ state.actually_paid_total }}</div>
      </a-form-item>

      <a-form-item label="用户当前平台余额">
        <span class="red"> ￥{{ state.moeny }} <a @click="recharge" class="m-3x">点击充值</a></span>
      </a-form-item>
    </a-form>
    <UseCoupons
      v-if="state.showCoupon"
      v-model:couponId="state.couponId"
      :tableColumnsData="{ public_payment_type: 2, customer_full_names: null, id: props.tableColumnsData.id }"
      @cancel="
        () => {
          state.showCoupon = false
        }
      "
      @ok="UseCouponsOk"
    ></UseCoupons>
    <ElectronicProof v-if="state.showModal" :counterfeitInfo="state.counterfeitInfo" @cancel="closeModal"></ElectronicProof>
    <view-log
      :logTabsKey="1"
      v-if="state.visibleLog"
      :tableColumnsData="props.tableColumnsData"
      @cancel="
        () => {
          state.visibleLog = false
        }
      "
    ></view-log>
  </a-modal>
</template>

<script lang="ts" setup>
import { message, Modal } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { reactive, onMounted, ref, computed } from 'vue'
import ViewLog from './ViewLog.vue'
import Upload from '@/components/base/Upload.vue'
import ElectronicProof from './ElectronicProof.vue'
import PlatformPayment from './PlatformPayment.vue'
import UseCoupons from '@/components/base/UseCoupons.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const format = 'YYYY-MM-DD HH:mm:ss'
const $store = useStore()
const router = useRouter()
const formLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const ruleForm = ref()
const rules = []
const emit = defineEmits(['cancel', 'ok'])

const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: () => {}
  }
})
const timesOption = [
  { label: '1年', value: 12 },
  { label: '2年', value: 24 },
  { label: '3年', value: 36 },
  { label: '4年', value: 48 },
  { label: '5年', value: 60 },
  { label: '半年', value: 6 }
]

const state = reactive<any>({
  loading: false,
  showModal: false,
  finishLoading: false,
  visibleLog: false,
  priceLoading: false,
  counterfeitInfo: {},
  showCoupon: false,

  discount_amount_total: 0, // 总优惠金额
  pay_amount_total: 0, // 总应付金额
  actually_paid_total: 0, // 总实付金额
  customer_xfdata: [],

  coupon_use_id: null, // 选中优惠券id
  isselected_coupon: {}, //选中的优惠券
  moeny: 0.0,
  type: 1,
  platformVisible: false
})

const formState = reactive<any>({
  fee_standard: 1,
  month_quantity: 12,
  price: props.tableColumnsData.price
})
onMounted(() => {
  formState.belonger = props.tableColumnsData.seller
  queryMoney()
  getComputeOreder()
})
const useCoupon = () => {
  state.showCoupon = true
}
const deleteCoupon = () => {
  state.isselected_coupon = {}
  state.coupon_use_id = null
  getComputeOreder()
}
const UseCouponsOk = (e) => {
  state.coupon_use_id = e.id
  state.isselected_coupon = e
  state.showCoupon = false
  getComputeOreder()
}
const handleCancel = () => {
  state.platformVisible = false
  queryMoney()
}
const handleOk = () => {
  state.platformVisible = false
  queryMoney()
}

const queryMoney = async () => {
  state.loading = true
  let requeryParam = {
    url: '/admin/user/getMoney?customer_id=' + props.tableColumnsData.id,
    method: 'get'
  }
  await baseService(requeryParam)
    .then((res) => {
      state.loading = false
      state.moeny = res.data.money
      state.addr_kucun = res.data.addr_kucun
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取余额失败')
      }
    })
}
// 查询日志
const onViewLogClick = () => {
  state.visibleLog = true
}

// 跳转平台充值
const recharge = () => {
  state.platformVisible = true
}

const closeModal = () => {
  emit('ok')
}

// 计算订单-地址续费
const getComputeOreder = () => {
  state.priceLoading = true
  let requeryParam = {
    url: `/admin/v1/getComputeOreder`,
    method: 'post',
    data: {
      public_payment_type: 2, //地址续费
      customer_id: props.tableColumnsData.id,
      month_quantity: formState.month_quantity,
      fee_standard: formState.fee_standard, //按年收费
      coupon_use_id: state.coupon_use_id
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.priceLoading = false
      //state.coupon_use_list = res.data.coupon_use_list
      state.discount_amount_total = res.data.oreder.discount_amount_total // 总优惠金额
      state.pay_amount_total = res.data.oreder.pay_amount_total // 总应付金额
      state.actually_paid_total = res.data.oreder.actually_paid_total // 总实付金额
      state.customer_xfdata = res.data.oreder.customer_xfdata // 总实付金额
    })
    .catch((res) => {
      message.error(res?.msg ? res.msg : '查询订单失败')
      state.priceLoading = false
      if (res?.data == 'coupon') {
        //优惠券问题，删除此优惠券
        deleteCoupon()
      }
    })
}

// 提交
const onFormSubmit = () => {
  ruleForm.value
    .validate()
    .then(() => {
      state.loading = true
      let obj: any = {}
      if (state.type == 1) {
        //账户余额
        obj = {
          public_payment_type: 2, //地址续费
          customer_id: props.tableColumnsData.id, //客户id
          fee_standard: formState.fee_standard, //续费类型
          month_quantity: formState.month_quantity, //续费月份
          belonger: null, //代理商、这里没有变更代理商
          coupon_use_id: state.coupon_use_id, //余额抵扣可以用优惠券
          pay_type: 1, //余额抵扣
          remark: formState.remark, //付款备注
          attachment: formState.attachment //附件、续费都有附件
        }
      } else {
        //对公账户
        obj = {
          public_payment_type: 2, //地址续费
          customer_id: props.tableColumnsData.id, //客户id
          fee_standard: formState.fee_standard, //收费时间
          month_quantity: formState.month_quantity,
          belonger: formState.belonger, //代理商
          coupon_use_id: null, //对公不能使用优惠券
          pay_type: 4, //对公转账
          remark: formState.remark, //付款备注
          attachment: formState.attachment, //附件、对公才传附件

          public_payer_name: formState.public_payer_name, //对公转账特有的6项
          public_payee_number: formState.public_payee_number,
          public_money: formState.public_money,
          public_remark: formState.public_remark,
          public_payee_name: formState.public_payee_name,
          public_pay_time: dayjs(formState.public_pay_time).format('YYYY-MM-DD')
        }
      }
      let requeryParam = {
        url: '/admin/v1/payOreder',
        method: 'post',
        data: obj
      }
      baseService(requeryParam)
        .then((res: any) => {
          state.loading = false
          message.success(res.msg)
          //   emit('ok')
          if (state.type == 1) {
            //余额续费显示一下凭证
            state.showModal = true
            state.counterfeitInfo = res.data
          } else {
            closeModal()
          }
        })
        .catch((res) => {
          state.loading = false
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    })
    .catch(() => {})
}
const cancel = () => {
  emit('cancel')
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 15px;
}
.d-flex {
  align-items: center;
}
</style>
<style lang="less">
.addresssRenewModal {
  .ant-modal {
    top: 300px;
  }
}
</style>
