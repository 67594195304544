<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'TemplateManagement',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="标题">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.name" placeholder="请输入标题"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="loadData">查询</a-button>
              <a-button style="margin-left: 8px" @click="refreshTabel">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd(1)">新建活动</a-button>
      <a-button type="primary" :icon="h(PlusOutlined)" @click="handleAdd(2)">新建活动分类</a-button>
    </div>
    <a-skeleton :loading="state.classLoading" active :paragraph="{ rows: 2 }">
      <div class="class-list">
        <div class="class-item pointer" :class="queryParam.class_id === 0 ? 'primary' : ''" @click="selectClassLick(0)">全部</div>
        <div class="class-item pointer" :class="queryParam.class_id === item.id ? 'primary' : ''" @click="selectClassLick(item.id)" v-for="(item, index) in classList" :key="index">
          {{ item.name }}
          <a-popover trigger="hover">
            <template #content>
              <a @click="editClasssClick(item.id)">编辑</a>
              <a @click="deleteClasssClick(item.id)" style="color: red; cursor: pointer; display: block">删除</a>
            </template>
            <MoreOutlined class="mh-x" />
          </a-popover>
        </div>
      </div>
    </a-skeleton>
    <a-table :columns="columns" :loading="state.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'operate'">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleCopy(record)">复制</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除此模板?" @confirm="handleDelete(record)">
            <a style="color: red">删除</a>
          </a-popconfirm>
        </template>
        <template v-else-if="column.dataIndex == 'class_id'">
          <a-tag color="red"> {{ className(text) }}</a-tag>
        </template>
        <template v-else-if="column.dataIndex == 'status'">
          <a-popconfirm :title="`确定${text == 1 ? '禁用' : '启用'}此活动?`" @confirm="statusChange(record, text)">
            <a-switch :loading="record.loadingSwicth" :checked="text == 1 ? true : false"></a-switch>
          </a-popconfirm>
        </template>
        <template v-else-if="column.dataIndex == 'start_time'">
          <div>{{ record.start_time }}至</div>
          <div>
            {{ record.end_time }}
          </div>
        </template>
        <template v-else-if="['renewal_price', 'price', 'total_price'].includes(column.dataIndex)"> ￥{{ text }} </template>
      </template>
    </a-table>

    <createActivity :isCopy="state.isCopy" v-if="state.showCreate" :detailData="state.detailData" @ok="onOk" @cancel="onCancel"></createActivity>
    <createClass v-if="state.showCreateClass" :id="state.editClassId" @cancel="onCancel" @ok="onOk"></createClass>
  </a-card>
</template>

<script lang="ts" setup name="TemplateManagement">
import { PlusOutlined, MoreOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { ref, reactive, onMounted, h } from 'vue'
import createActivity from './components/createActivity.vue'
import createClass from './components/createClass.vue'
const columns = [
  {
    title: '购买库存方案名称',
    dataIndex: 'name'
  },
  {
    title: '购买库存方案描述',
    dataIndex: 'describe'
  },
  {
    title: '分类',
    dataIndex: 'class_id'
  },
  {
    title: '单价',
    dataIndex: 'price'
  },
  {
    title: '数量',
    dataIndex: 'amount'
  },
  {
    title: '总价',
    dataIndex: 'total_price'
  },
  {
    title: '续费价格',
    dataIndex: 'renewal_price'
  },
  {
    title: '购买库存方案时间',
    dataIndex: 'start_time'
  },
  {
    title: '是否启用',
    dataIndex: 'status'
  },
  {
    title: '创建时间',
    dataIndex: 'created_at'
  },
  {
    title: '操作',
    dataIndex: 'operate'
  }
]
interface data {
  showCreate: boolean
  showCreateClass: boolean
  editClassId: any
  detailData: object
  loading: boolean
  classLoading: boolean
  isCopy: boolean
}
const state = reactive<data>({
  showCreate: false,
  showCreateClass: false,
  editClassId: null,
  detailData: {},
  loading: false,
  classLoading: false,
  isCopy: false
})
interface queryParam {
  name?: string
  class_id?: number
}

let param = ref<queryParam>({ name: '', class_id: 0 })
let queryParam = param.value
let dataSource = ref([])
let classList = ref<{ name: string; id: number }[]>([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '40'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
  classLoadData()
})
const refreshTabel = () => {
  pagination.current = 1
  param.value = { class_id: 0 }
  queryParam = param.value
  dataSource.value = []
  loadData()
  classLoadData()
}
const selectClassLick = (id) => {
  queryParam.class_id = id
  dataSource.value = []
  loadData()
}
const className = (id) => {
  let className = '-'
  classList.value.forEach((item) => {
    if (id == item.id) {
      className = item.name
    }
  })
  return className
}
const loadData = () => {
  state.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/activityCenterList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, { name: queryParam.name, class_id: queryParam.class_id === 0 ? undefined : queryParam.class_id })
  }
  baseService(requestParam)
    .then((res) => {
      state.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        return { ...item, loadingSwicth: false }
      })
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取列表失败')
      }
    })
}
const statusChange = (record, val) => {
  let status = val == 1 ? 2 : 1
  record.loadingSwicth = true
  let requeryParam = {
    url: '/admin/v1/activityCenter/changeStatus',
    method: 'put',
    data: {
      id: record.id,
      status
    }
  }
  baseService(requeryParam)
    .then((res: any) => {
      record.loadingSwicth = false
      message.success(res.msg)
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('修改失败请联系管理员')
      }
    })
}
const classLoadData = () => {
  state.classLoading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/activityCenterClassList',
    method: 'post',
    data: { page: 1, limit: 'all' }
  }
  baseService(requestParam)
    .then((res) => {
      state.classLoading = false
      classList.value = res.data.list
    })
    .catch((res) => {
      state.classLoading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取分类失败')
      }
    })
}
const onChange = (val) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  loadData()
}
const onOk = () => {
  loadData()
  classLoadData()
  state.showCreateClass = false
  state.showCreate = false
}
const onCancel = () => {
  state.showCreateClass = false
  state.showCreate = false
}
// 添加客户
const handleAdd = (type) => {
  state.detailData = {}
  state.isCopy = false
  if (type == 1) {
    state.showCreate = true
  } else {
    state.editClassId = null
    state.showCreateClass = true
  }
}
// 编辑客户
const handleEdit = (record) => {
  state.showCreate = true
  state.detailData = { ...record }
  state.isCopy = false
}
// 复制客户
const handleCopy = (record) => {
  state.showCreate = true
  state.detailData = { ...record }
  state.isCopy = true
}
const editClasssClick = (id) => {
  state.editClassId = id
  state.showCreateClass = true
}
const deleteClasssClick = (id) => {
  let requestParam = {
    url: '/admin/v1/activityCenterClass/' + id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
      classLoadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const handleDelete = (record) => {
  let requestParam = {
    url: '/admin/v1/activityCenter/' + record.id,
    method: 'delete'
  }
  baseService(requestParam)
    .then((res: any) => {
      message.success(res.msg)
      loadData()
      classLoadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
</script>

<style lang="less" scoped>
.class-list {
  display: flex;
  flex-wrap: wrap;
  .class-item.primary {
    background: #fff;
    border-color: var(--ant-primary-color-deprecated-bg);
  }
  .class-item {
    border: 1px solid #f3f3f3;
    background: #f1f1f160;
    padding: 8px 10px;
    margin-right: 2px;
    font-size: 12px;
    display: flex;
    margin-bottom: 8px;
  }
}
</style>
