<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Exception500',
})
</script>
<template>
    <a-result status="500" title="500">
        <template #subTitle>
            <pre>
        {{ errorMsg }}
      </pre>
        </template>
        <template #extra>
            <a-button type="primary" @click="toHome">
                返回首页
            </a-button>
        </template>
    </a-result>
</template>

<script lang="ts" setup name="Exception500">
import { useRouter } from 'vue-router'
const toHome = () => {
    window.location.href = '/'
}

const router = useRouter()
const { errorMsg } = router.currentRoute.value.params
</script>
