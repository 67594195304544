<template>
    <a-range-picker :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="pickDate"
        :format="props.format" :show-time="props.showTime" @change="rangePickerChange" />
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import { ref, watch } from 'vue';

const emit = defineEmits(['update:value'])
const props = defineProps({
    value: {
        type: String,
        default: null,
    },
    format: {
        type: String,
        default: 'YYYY-MM-DD HH:mm:ss',
    },
    showTime: {
        type: Boolean,
        default: true,
    },
})
watch(() => props.value, (val) => {
    if (val) {
        const list = val.split(',')
        pickDate.value = [dayjs(list[0], props.format), dayjs(list[1], props.format)]
    }
}, {
    deep: true,
    immediate: true,
})
const pickDate = ref()
const rangePickerChange = (e, v) => {
    if (e) {
        emit('update:value', v.join(','))
    } else {
        emit('update:value', null)
    }
}
</script>
