<template>
  <a-modal :title="`${props.tableColumnsData.full_name}-公示系统授权登录`" :width="1000" :open="true" @cancel="cancel" :footer="null">
    <a-form @finish="onRegisterFinish" v-bind="formLayout" :model="registerFormState" :rules="rules">
      <a-form-item label="核验方式">
        <a-radio-group v-model:value="registerFormState.logout_sqtype" button-style="solid" @change="changeLogoutSqtype">
          <a-radio :value="1">短信验证码登录</a-radio>
          <a-radio :value="2">授权登录</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="扫码登录" v-show="registerFormState.logout_sqtype == 2">
        <div class="d-flex flex-left" v-show="data.deadline > 0">
          <img :src="data.qrcode" />
          <a-statistic-countdown format="mm:ss" title="登录倒计时" :value="data.deadline" @finish="onStatisticFinish" />
        </div>
        <div v-show="data.deadline == 0">
          <a-button type="primary" @click="onFormSubmit">重新获取</a-button>
        </div>
      </a-form-item>
      <div v-if="registerFormState.logout_sqtype == 1">
        <a-form-item label="统一社会信用代码" name="social_credit_code">
          <a-input v-model:value="registerFormState.social_credit_code" placeholder="请输入统一社会信用代码"> </a-input>
        </a-form-item>
        <a-form-item label="联络员身份证" name="liaison_identity">
          <a-input v-model:value="registerFormState.liaison_identity" placeholder="请输入联络员身份证"> </a-input>
        </a-form-item>
        <a-form-item label="联络员电话号码" name="phone_number">
          <div class="d-flex">
            <a-input v-model:value="registerFormState.phone_number" placeholder="联络员电话号码" disabled> </a-input>
            <a-button type="primary" class="ml-1x" @click="onGetCodeClick" :disabled="data.getCodeDisabled">获取验证码</a-button>
          </div>
        </a-form-item>
        <a-form-item label="验证码">
          <a-input v-model:value="registerFormState.code" placeholder="请输入验证码"> </a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 7, span: 16 }">
          <a-button type="primary" html-type="submit">确定</a-button>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>
<script lang="ts" setup>
import { PlusOutlined, UploadOutlined, EyeOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
const emit = defineEmits(['cancel', 'ok'])
let timer = ref()
let timer2 = ref()

interface data {
  qrcode: string
  deadline: number
  getCodeDisabled: boolean
}
const data = reactive<data>({
  deadline: 0,
  qrcode: '',
  getCodeDisabled: false
})
const props = defineProps({
  tableColumnsData: {
    type: Object,
    default: false
  }
})
onMounted(() => {
  //设置默认登录方式：1收验证码，2扫码登录
  registerFormState.logout_sqtype = props.tableColumnsData.logout_sqtype
  registerFormState.social_credit_code = props.tableColumnsData.social_credit_code
  registerFormState.liaison_identity = props.tableColumnsData.liaison_identity
  //扫码授权，自动获取一下二维码
  if (registerFormState.logout_sqtype == 2) {
    onFormSubmit()
  }
})

const rules = {}
const formLayout: Object = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
const registerFormState = reactive<any>({
  id: null,
  social_credit_code: null,
  liaison_identity: null,
  logout_sqtype: 1,
  phone_number: null,
  code: null
})
//倒计时完成
const onStatisticFinish = () => {
  data.qrcode = ''
  data.deadline = 0
}

//获取验证码
const onGetCodeClick = () => {
  if (!props.tableColumnsData.social_credit_code || !props.tableColumnsData.liaison_identity) {
    message.error('请先设置统一社会信用代码和联络员身份证！')
    return
  }
  data.getCodeDisabled = true
  //分三步骤curl：1登录页，2验证身份证和代码，3发送短信验证码
  let requestParam = {
    url: `/admin/v1/cancellationSMS1`,
    method: 'POST',
    data: { id: props.tableColumnsData.id }
  }
  baseService(requestParam)
    .then((res: any) => {
      registerFormState.phone_number = res.data.mobile
      message.success(res.msg)
    })
    .catch((res) => {
      data.getCodeDisabled = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取失败')
      }
    })
}
// 提交验证码
const onRegisterFinish = () => {
  if (!registerFormState.code) {
    message.error('请输入验证码')
    return
  }
  if (!registerFormState.social_credit_code || !registerFormState.liaison_identity) {
    message.error('请输入统一社会信用代码和联络员身份证')
    return
  }
  let requestParam = {
    url: '/admin/v1/setCancellationSMS1',
    method: 'post',
    data: {
      id: props.tableColumnsData.id,
      social_credit_code: registerFormState.social_credit_code,
      liaison_identity: registerFormState.liaison_identity,
      verify_code: registerFormState.code
    }
  }
  baseService(requestParam)
    .then((res: any) => {
      //短信验证码的成功
      emit('ok', props.tableColumnsData.id, props.tableColumnsData.steptype)
      message.success(res.msg)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
const changeLogoutSqtype = () => {
  if (registerFormState.logout_sqtype == 2) {
    onFormSubmit()
  } else {
    clearInterval(timer.value)
    clearInterval(timer2.value)
  }
}
const onFormSubmit = () => {
  data.qrcode = '' //先清空一下二维码
  //获取二维码
  let requestParam = {
    url: '/admin/v1/getqrcodewebsite',
    method: 'post',
    data: { id: props.tableColumnsData.id }
  }
  baseService(requestParam)
    .then((res) => {
      timer.value = setInterval(() => {
        let param = {
          url: '/admin/v1/cancellationQrcode1Check?id=' + props.tableColumnsData.id,
          method: 'get'
        }
        baseService(param).then((res: any) => {
          if (res.data.status == 1) {
            //扫码的成功
            emit('ok', props.tableColumnsData.id, props.tableColumnsData.steptype)
            clearInterval(timer.value)
          }
        })
      }, 3000)
      data.deadline = Date.now() + 1000 * 60

      timer2.value = setInterval(() => {
        let param = {
          url: '/admin/v1/getqrcodefrommysql?id=' + props.tableColumnsData.id,
          method: 'get'
        }
        baseService(param).then((res: any) => {
          if (res.data.qrcode) {
            data.qrcode = res.data.qrcode
            clearInterval(timer2.value)
          }
        })
      }, 2000)
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('获取二维码失败')
      }
    })
}

const cancel = () => {
  clearInterval(timer.value)
  clearInterval(timer2.value)
  emit('cancel')
}
</script>
<style lang="less" scoped></style>
