<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'yichangAuditList',
})
</script>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form>
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="主体名称" name="nameOrCode">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.nameOrCode" placeholder="请输入主体名称、税号"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="属性名称" name="name">
              <a-select mode="multiple" v-model:value="queryParam.name" placeholder="请选择">
                <a-select-option value="fee_standard"> 收费标准 </a-select-option>
                <a-select-option value="enter_starttime"> 入驻开始日期 </a-select-option>
                <a-select-option value="enter_endtime"> 入驻到期日期 </a-select-option>
                <a-select-option value="founding_time"> 成立日期 </a-select-option>
                <a-select-option value="verify_time"> 核准日期 </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="审核状态" name="status">
              <a-select mode="multiple" v-model:value="queryParam.status" placeholder="请选择">
                <a-select-option :value="1">待审核</a-select-option>
                <a-select-option :value="2">已通过</a-select-option>
                <a-select-option :value="3">已拒绝</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="调整理由" name="remark">
              <a-input @keyup.enter="loadData" v-model:value="queryParam.remark" placeholder="请输入"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="录入系统时间" name="between_time">
              <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <div class="table-page-search-submitButtons" style="overflow: hidden; margin-bottom: 0">
              <a-button type="primary" @click="loadData">查询</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :loading="data.loading" :data-source="dataSource" :pagination="pagination" @change="onChange">
      <template #bodyCell="{ column, record, index, text }">
        <template v-if="column.dataIndex === 'name'">
          <p>{{ record.full_name }}</p>
          <a @click="goCustomerClick(record.full_name)" class="pointer"> 查看详情 </a>
        </template>
        <template v-else-if="column.dataIndex === 'verification'">
          <div v-if="record.status === 1">
            <a-input v-model:value="record.desc" placeholder="选填:输入通过或者不通过的理由"></a-input>
            <a-popconfirm title="确定不通过?" @confirm="verificatioChange(record, 2)">
              <a-button class="mr-1x mt-1x" type="primary" danger>不通过</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定通过?" @confirm="verificatioChange(record, 1)">
              <a-button type="primary">通过</a-button>
            </a-popconfirm>
          </div>
          <div v-else>
            <a-tag color="green" v-if="record.status === 2">审核通过</a-tag>
            <a-tag color="red" v-if="record.status === 3">审核不通过</a-tag>
            <div v-if="record.audit_remark">理由:{{ record.audit_remark }}</div>
          </div>
        </template>
        <template v-else-if="column.dataIndex === 'annex_url'">
          <template v-for="(item, index) in record.annex_url">
            <a-image
              v-if="item.url.endsWith('png') || item.url.endsWith('jpg') || item.url.endsWith('jpeg')"
              :width="60"
              :height="60"
              :key="index"
              :src="$store.state.user.image_domain + item.url"
            ></a-image>
          </template>
        </template>
        <template v-else-if="column.dataIndex === 'submitName'">
          <div v-if="record.sellerName">
            {{ record.sellerName }}
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="column.dataIndex === 'detail'">
          <p>{{ record.name_name }}:</p>
          <p>
            调整前：<a-tag color="green" v-if="record.name == 'fee_standard'"> {{ record.old_value == 1 ? '按年收费' : '一次性收费' }} </a-tag>
            <a-tag color="green" v-else> {{ record.old_value }} </a-tag>
          </p>
          <p>
            调整后：<a-tag color="green" v-if="record.name == 'fee_standard'"> {{ record.new_value == 1 ? '按年收费' : '一次性收费' }} </a-tag>
            <a-tag color="green" v-else> {{ record.new_value }} </a-tag>
          </p>
          <p>理由：{{ record.remark }}</p>
        </template>
        <template v-else-if="column.dataIndex === 'updated_at'">
          <div v-if="record.status !== 1">{{ record.operator_admin_info?.nickname }}{{ text }}审核完毕,耗时{{ calculateTimeDifference(record.created_at, record.updated_at) }}</div>
          <div v-else>-</div>
        </template>
      </template>
    </a-table>
  </a-card>
</template>
<script lang="ts" setup name="yichangAuditList">
import { PlusOutlined, DownOutlined, UpOutlined, CopyOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'
import type { PaginationProps } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { message, Modal, Table } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { unref, ref, reactive, h, onMounted, computed, onActivated, customRef } from 'vue'
import dayjs from 'dayjs'
import ls from '@/utils/Storage'
import { costByPoints } from '@logicflow/core/types/util'
import { useStore } from 'vuex'
const $store = useStore()
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
const token = ls.get('ACCESS_TOKEN').replace('bearer ', '')
const user = ls.get('USER_INFO')
const router = useRouter()
const format = 'YYYY-MM-DD HH:mm:ss'

interface data {
  visible: boolean
  loading: boolean
}
const data = reactive<data>({
  visible: false,
  loading: false
})
const filteredInfo = ref()
const createForm = ref()
const columns = [
  {
    title: '公司名称',
    dataIndex: 'name'
  },
  {
    title: '调整属性',
    dataIndex: 'detail'
  },
  {
    title: '审核',
    dataIndex: 'verification'
  },

  {
    title: '相关附件',
    dataIndex: 'annex_url'
  },
  {
    title: '申请审核人',
    dataIndex: 'submitName'
  },
  {
    title: '申请时间',
    dataIndex: 'created_at'
  },
  {
    title: '审核时间',
    dataIndex: 'updated_at'
  }
]
const formLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 }
  }
}
let queryParam = reactive<any>({
  order_status_list: []
})
let dataSource = ref([])
const pagination = reactive<PaginationProps>({
  pageSize: 10,
  pageSizeOptions: ['10', '20', '40', '50', '60', '200'],
  current: 1,
  total: undefined,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total) => `总数：${total}`
})
onMounted(() => {
  loadData()
})
// 列表数据
const loadData = () => {
  if (data.loading) {
    return
  }
  data.loading = true
  // 获取到列表数据
  let requestParam = {
    url: '/admin/v1/attrAdjustList',
    method: 'post',
    data: Object.assign({ page: pagination.current, limit: pagination.pageSize }, queryParam)
  }
  baseService(requestParam)
    .then((res) => {
      data.loading = false
      pagination.total = res.data.total_records
      dataSource.value = res.data.list.map((item) => {
        if (queryParam.nameOrCode) {
          item.fullName = item.full_name.replace(queryParam.nameOrCode, `<span style="color: red">${queryParam.nameOrCode}</span>`)
        } else {
          item.fullName = item.full_name
        }
        return { ...item, isShowOptions: false, isShowCompany: false, desc: null, deleteType: null, customer_id: item.id }
      })
    })
    .catch((res) => {
      data.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('异常审核列表查询失败,请联系管理员')
      }
    })
}

// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (queryParam.between_time) {
    array = queryParam.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    queryParam.between_time = str.join(',')
  } else {
    queryParam.between_time = null
  }
}
const goCustomerClick = (name) => {
  router.push({ path: '/customer', query: { customer_name: name } })
}

// 表格分页查询
const onChange = (val, filters) => {
  pagination.pageSize = val.pageSize
  pagination.current = val.current
  if (filters.status) {
    queryParam.order_status_list = filters.status
  }
  loadData()
}

const verificatioChange = (record, type) => {
  let requestParam = {
    url: '/admin/v1/attrAdjustAudit',
    method: 'post',
    data: {
      reason: record.desc,
      status: type,
      id: record.id
    }
  }
  baseService(requestParam)
    .then((res) => {
      message.success('操作成功')
      loadData()
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}
// 计算时间差
const calculateTimeDifference = (startDate: string, endDate: string) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)
  const duration = end.diff(start, 'minute')
  return formatDuration(duration)
}
const formatDuration = (duration: number) => {
  const days = Math.floor(duration / (24 * 60))
  const hours = Math.floor((duration % (24 * 60)) / 60)
  const minutes = duration % 60
  return `${days > 0 ? days + '天' : ''}${hours > 0 ? hours + '小时' : ''}${minutes > 0 ? minutes : 1}分钟`
}
</script>
<style lang="less" scoped>
:deep(.ant-image) {
  margin-right: 10px;
  margin-bottom: 10px;
}
.customer-options-popover {
  p {
    padding: 0 16px;
    margin: 0;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    color: #000;
  }

  p:hover {
    color: var(--ant-primary-color);
    background: var(--ant-primary-color-deprecated-bg);
  }
}
</style>
