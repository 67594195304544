<template>
  <a-cascader
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    @change="handleChange"
    v-model:value="state.cascaderValue"
    :style="{ width: props.width }"
    placeholder="请选择省市区"
    :field-names="{ label: 'name', value: 'value', children: 'children' }"
    :options="state.options"
    :load-data="loadData"
  ></a-cascader>
</template>

<script lang="ts" setup>
import { reactive, watch, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '../../utils/http/axios'
const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '300px'
  },
  type: {
    type: Number,
    default: 1
  }
})
const emit = defineEmits(['update:value', 'change'])
interface options {
  children: object[]
}
interface state {
  options: options[]
  cascaderValue: number[]
}
const state = reactive<state>({
  options: [],
  cascaderValue: []
})
watch(
  () => props.value,
  (val) => {
    // 如果进来的值和返回的值一样 直接ruturn 防止重复监听
    if (state.cascaderValue?.join(',') == val) {
      return
    }
    if (val) {
      state.cascaderValue = val.split(',').map((item) => parseInt(item))
      setTimeout(async () => {
        if (state.options.length == 0) {
          await getByLevel()
        }
        if (state.cascaderValue.length > 1) {
          let list: any = []
          let checkIndex = 0
          state.options.forEach((item: any, index: number) => {
            if (item.value == state.cascaderValue[0]) {
              checkIndex = index
              item.loading = false
              list.push(item)
            }
          })
          await loadData(list)
          let childrenCheckIndex = 0
          state.options[checkIndex].children.forEach((item: any, index: number) => {
            if (item.value == state.cascaderValue[1]) {
              childrenCheckIndex = index
              item.loading = false
              list.push(item)
            }
          })
          await loadData(list)
          if (props.type === 3) {
            state.options[checkIndex].children[childrenCheckIndex].children.forEach((item: any) => {
              if (item.value == state.cascaderValue[2]) {
                item.loading = false
                list.push(item)
              }
            })
            await loadData(list)
          }
        }
      }, 100)
    }
  },
  { immediate: true, deep: true }
)

onMounted(() => {
  getByLevel()
})
// 处理级联选择器的change事件
const handleChange = (idList, value) => {
  emit('update:value', idList.join(','))
  emit('change', idList, value)
}
// 根据级别获取数据
const getByLevel = async () => {
  let url = '/admin/area'
  if (props.type === 3) {
    url = url + '?type=1'
  }
  let requestParam = {
    url: url,
    method: 'get'
  }
  let localData = localStorage.getItem('AddressProvinceList' + props.type)
  if (localData) {
    console.log('查的本地地址')
    state.options = JSON.parse(localData)
    //把湖南放前面
    let list = JSON.parse(localData)
    let index = 0
    for (let a in list) {
      let aa = list[a]
      if (aa.name.includes('湖南')) {
        index = parseInt(a)
      }
    }
    list = [list[index], ...list.slice(0, index), ...list.slice(index + 1)]
    state.options = list
  } else {
    baseService(requestParam)
      .then((res) => {
        state.options = res.data.map((item) => {
          item.isLeaf = false
          return item
        })
        //先存入本地
        console.log('存入本地地址')
        localStorage.setItem('AddressProvinceList' + props.type, JSON.stringify(state.options))
      })
      .catch((res) => {
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('获取失败')
        }
      })
  }
}
// 加载数据
const loadData = async (selectedOptions) => {
  const targetOption = selectedOptions[selectedOptions.length - 1]
  let type = 'province'
  targetOption.loading = true
  let url = '/admin/area'
  if (selectedOptions.length == 1) {
    url = url + `?province=${selectedOptions[selectedOptions.length - 1].value}`
  } else if (selectedOptions.length == 2) {
    type = 'city'
    url = url + `?province=${selectedOptions[selectedOptions.length - 2].value}&city=${selectedOptions[selectedOptions.length - 1].value}`
  } else {
    type = 'street'
    url = url + `?province=${selectedOptions[selectedOptions.length - 3].value}&city=${selectedOptions[selectedOptions.length - 2].value}&street=${selectedOptions[selectedOptions.length - 1].value}`
  }
  if (props.type === 3) {
    url = url + '&type=1'
  }
  let requestParam = {
    url: url,
    method: 'get'
  }
  await baseService(requestParam)
    .then((res) => {
      targetOption.children = res.data.map((item: any) => {
        if (type == 'province') {
          item.isLeaf = false
        } else if (type == 'city' && props.type === 3) {
          item.isLeaf = false
        }
        return item
      })
      targetOption.loading = false
      state.options = [...state.options]
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('加载失败')
      }
    })
}
</script>
