<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
  <a-modal
    title="审核"
    :width="1000"
    :open="true"
    :footer="false"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <div class="ph-4x pv-2x">
      <a-form v-bind="formLayout">
        <a-form-item label="客户姓名">
          {{ isEmpty(props.detailData.customer_name) }}
        </a-form-item>
        <a-form-item label="客户电话">
          {{ isEmpty(props.detailData.customer_tel) }}
        </a-form-item>
        <a-form-item label="紧急联系人手机号码">
          {{ isEmpty(props.detailData.exigency_tel) }}
        </a-form-item>
        <a-form-item label="邮箱">
          {{ isEmpty(props.detailData.mail_address) }}
        </a-form-item>
        <a-form-item label="微信号">
          {{ isEmpty(props.detailData.wechat_id) }}
        </a-form-item>
        <a-form-item label="微信昵称">
          {{ isEmpty(props.detailData.wechat_name) }}
        </a-form-item>
        <a-form-item label="微信头像">
          <a-image v-if="props.detailData.wechat_picture?.length > 0" :width="100" :height="100" :src="$store.state.user.image_domain + props.detailData.wechat_picture"> </a-image>
          <div v-else>无</div>
        </a-form-item>
        <a-form-item label="身份证人像面照片">
          <a-image v-if="props.detailData.identity_card_front" :width="300" :height="200" :src="$store.state.user.image_domain + props.detailData.identity_card_front"> </a-image>
          <div v-else>无</div>
        </a-form-item>
        <a-form-item label="身份证国徽面照片">
          <a-image v-if="props.detailData.identity_card_contrary" :width="300" :height="200" :src="$store.state.user.image_domain + props.detailData.identity_card_contrary"> </a-image>
          <div v-else>无</div>
        </a-form-item>
        <a-form-item label="身份证号码">{{ isEmpty(props.detailData.identity_card_number) }} </a-form-item>
        <a-form-item label="身份证有效期开始时间">{{ isEmpty(props.detailData.identity_card_start_time) }} </a-form-item>
        <a-form-item label="身份证有效期结束时间">{{ isEmpty(props.detailData.identity_card_expiration_time) }} </a-form-item>
        <a-form-item label="住所">{{ isEmpty(props.detailData.identity_card_address) }} </a-form-item>
        <a-form-item label="民族">{{ isEmpty(props.detailData.nationality) }} </a-form-item>
        <a-form-item label="推荐人手机号码">{{ isEmpty(props.detailData.referrer_tel) }} </a-form-item>
        <a-form-item label="就职公司名称">{{ isEmpty(props.detailData.company_name) }}</a-form-item>
        <a-form-item label="就职公司统一社会信用代码">{{ isEmpty(props.detailData.finance_social_credit_code) }}</a-form-item>
        <a-form-item label="公司营业执照照片">
          <div v-if="props.detailData.company_business_license?.length > 0">
            <span class="mr-1x mb-1x" v-for="(item, index) in props.detailData.company_business_license" :key="index">
              <a-image :width="100" :height="100" :src="$store.state.user.image_domain + item"></a-image>
            </span>
          </div>
          <div v-else>无</div>
        </a-form-item>
        <a-form-item label="就职公司地址">{{ isEmpty(props.detailData.company_address) }} </a-form-item>
        <a-form-item label="职位名称">{{ isEmpty(props.detailData.job_title) }} </a-form-item>
        <a-form-item label="证明材料">
          <div v-if="props.detailData.job_certificate?.length > 0">
            <a :download="item.name" :href="$store.state.user.image_domain + item.url" target="_blank" v-for="(item, index) in props.detailData.job_certificate" :key="index">{{ item.name }}</a>
          </div>
          <div v-else>无</div>
        </a-form-item>
        <a-form-item label="操作">
          <a-input v-model:value="state.desc" placeholder="选填:输入通过或者不通过的理由"></a-input>
          <a-popconfirm title="确定不通过?" @confirm="verificatioChange(2)">
            <a-button class="mr-1x mt-1x" type="primary" danger>不通过</a-button>
          </a-popconfirm>
          <a-popconfirm title="确定通过?" @confirm="verificatioChange(1)">
            <a-button type="primary">通过</a-button>
          </a-popconfirm>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
const $store = useStore()
import baseService from '@/utils/http/axios'
const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const formLayout = {
  labelCol: {
    xs: { span: 7 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  }
}
const emit = defineEmits(['cancel'])
const state = reactive<any>({
  desc: ''
})

const isEmpty = (val) => {
  if (val && val.length > 0) {
    return val
  } else {
    return '无'
  }
}
const verificatioChange = (type) => {
  let requestParam = {
    url: '/admin/v1/filingsCheck/' + props.detailData.id,
    method: 'put',
    data: {
      reason: state.desc,
      status: type
    }
  }
  baseService(requestParam)
    .then((res) => {
      message.success('操作成功')
      emit('cancel')
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败,请联系管理员')
      }
    })
}
onMounted(() => {})
</script>

<style lang="less" scoped>
.avatar-uploader {
  :deep(.ant-upload.ant-upload-select-picture-card) {
    width: 300px !important;
    height: 200px !important;
  }
}

.upload-img {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
