<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createTemplate',
})
</script>
<template>
    <a-modal title="新建通知" :width="1000" :open="true" :confirmLoading="state.loading" @ok="onFormSubmit" @cancel="() => {
        emit('cancel')
    }
        ">
        <a-spin :spinning="state.loading">
            <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
                <a-form-item label="选择平台">
                    <a-select placeholder="请选择平台">
                        <a-select-option key="" value=""></a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="选择类型">
                    <a-select placeholder="请选择类型">
                        <a-select-option :value="1">不限类型</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="公司列表" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
                    <a-radio-group v-model:value="formState.template_file_type" :options="templateFileTypeOptions">
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="已经选择的公司" v-if="formState.template_file_type == 2">
                    <a-button type="primary" @click="addCompanyClick">添加公司</a-button>
                </a-form-item>
                <a-form-item label="短信内容">
                    <a-textarea placeholder="请输入短信内容"></a-textarea>
                </a-form-item>
                <a-form-item label="是否同步通知销售">
                    <a-radio-group>
                        <a-radio :value="1">不通知</a-radio>
                        <a-radio :value="2">通知</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="通知方式">
                    <a-radio-group>
                        <a-radio :value="1">微信</a-radio>
                        <a-radio :value="2">短信</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="发送方式">
                    <a-radio-group>
                        <a-radio :value="1">一个公司一个公司的发送</a-radio>
                        <a-radio :value="2">所有公司混在一起发送</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-spin>
        <!-- <SelectCompany :selectCompanyVisible="state.selectCompanyVisible"
            @cancel="() => state.selectCompanyVisible = false">
        </SelectCompany> -->
    </a-modal>
</template>

<script lang="ts" setup name="createTemplate">
import { reactive, ref, onMounted } from 'vue';
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import { SelectCompany } from '@/components';

const templateFileTypeOptions = [{ label: '全部通知', value: 1 }, { label: '筛选通知', value: 2 },]
const emit = defineEmits(['cancel'])
const ruleForm = ref()
const rules = {
    name: [{ required: true, message: '请输入合同名称', trigger: 'change' }],
    template_url: [{ required: true, message: '请上传附件', trigger: 'change' }],
}
const formLayout = {
    labelCol: {
        xs: { span: 7 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
}
interface state {
    loading: boolean
    selectCompanyVisible: boolean
}
const state = reactive<state>({
    loading: false,
    selectCompanyVisible: false,
})


interface formState {
    name: string
    is_show_client: number
    template_file_type: number
    template_type: number
    template_url: object[]
}
let form = ref<formState>({
    name: '',
    is_show_client: 1,
    template_file_type: 1,
    template_type: 1,
    template_url: [],
})
let formState = form.value
onMounted(() => {
})
const addCompanyClick = () => {
    state.selectCompanyVisible = true
}
const onFormSubmit = () => {
    ruleForm.value.validate().then(() => {
        state.loading = true
        let requeryParam = {
            url: '/admin/v1/template',
            method: 'post',
            data: formState,
        }
        baseService(requeryParam).then((res: any) => {
            state.loading = false
            message.success(res.msg)
            emit('cancel')
        }).catch((res) => {
            state.loading = false
            if (res.msg) {
                message.error(res.msg)
            } else {
                message.error('操作失败')
            }
        })
    }).catch(() => {
    })
}
</script>

<style lang="less" scoped>
</style>
