import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/style/global.less';
import 'virtual:svg-icons-register';
import setupDefaultSetting from '@/utils/setupDefaultSetting'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css'
import "@/utils/default-passive-events"
import ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Print from 'vue3-print-nb'


import VueUeditorWrap from 'vue-ueditor-wrap';
const app = createApp(App)
app.use(Print)
app.use(VueUeditorWrap)
app.use(Antd)
app.use(ColorPicker)
app.use(router)
app.use(store)
app.mount('#app');
// 将储存到storage中的设置覆盖
setupDefaultSetting()

// window.env = import.meta.env.MODE
